import React, { useEffect, useState } from "react";
import { TiThMenu } from "react-icons/ti";
import {
  Box,
  Breadcrumbs,
  Chip,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useMenuSideBarContext } from "../contexts/MenuSideBarContext";
import SingleSelect from "./AppSelect/SingleSelect";
import { useRoleSidebar } from "../../navbarConstants";
import { OPTIONS_NAVBAR } from "../utils/constant";
import logo from "../../assets/images/DeleveryDayLogo.png";
import { styled } from "@mui/system";

const NavMobileDiv = styled("div")(({}) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItem: "center",
  margin: "auto",
}));

export default function MenuSidebar({ children }) {
  // State and context hooks
  const innerWith = useMediaQuery("(max-width:760px)");
  const { user } = useAuth();
  const {
    selected,
    childSelected,
    handleItemsClick,
    handleChildClick,
    settingsOpen,
    sideBarOpen,
    setSideBarOpen,
  } = useMenuSideBarContext();
  const sidebarMenu = useRoleSidebar();
  const [anchor, setAnchor] = useState(null);
  // Other hooks
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const currentPath = location.pathname;

  // Event handlers
  const handleSideBarClose = () => {
    setSideBarOpen(!sideBarOpen);
    console.log(true, "sidebar");
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleMenuItemClick = (value, path) => {
    if (value === "Orders") {
      handleItemsClick("Orders", "/dashboard");
    } else {
      handleLogout();
    }
  };

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleLogout = () => {
    window.localStorage.clear("accessToken");
    window.location.reload();
  };

  const updateSelectedItems = () => {
    // Iterate through your menu items and find the one that matches the current path
    for (const menuItem of sidebarMenu) {
      if (menuItem.path === currentPath) {
        // Update the state with the selected item
        handleItemsClick(menuItem.title, menuItem.path);
        return;
      }

      // If the menu item has sub-items, check them as well
      if (menuItem.items) {
        for (const subItem of menuItem.items) {
          if (subItem.path === currentPath) {
            // Update the state with the selected sub-item
            handleChildClick(subItem.title, subItem.path);
            return;
          }
        }
      }
    }
  };

  // Styles
  const shadowStyleRight = {
    boxShadow: "5px 0 10px rgba(0, 0, 0, 0.07)",
  };

  const shadowStyleBottom = {
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
  };

  // Mapping for icons
  const iconMapping = {
    order: <ShoppingCartIcon />,
    dashboard: <HomeIcon />,
    setting: <SettingsIcon />,
  };

  // UseEffect
  useEffect(() => {
    updateSelectedItems();
  }, [currentPath, sideBarOpen]);
  useEffect(() => {
    if (innerWith) {
      setSideBarOpen(true);
    }
  }, [innerWith]);
  return (
    <Grid
      container
      style={{
        height: "100vh",
        // width: "100%",

        overflowX: "hidden",
      }}
    >
      {/* Sidebar */}
      <Grid item position={"relative"} sx={{}} zIndex={3}>
        <Box
          position={"fixed"}
          style={shadowStyleRight}
          sx={{
            height: "100%",
            display: innerWith ? "none" : "flex",
            width: sideBarOpen ? "75px" : "270px",
            background: "white",
            overflowX: "auto",
          }}
        >
          {/* Sidebar content */}
          <div style={{ width: "100%" }}>
            {/* Logo and toggle button */}
            <Grid
              position="absolute"
              sx={{
                mt: -6,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              top={15}
            >
              <Box
                onClick={(e) => handleItemsClick("Orders", "/dashboard")}
                sx={{
                  ml: 2,
                  cursor: "pointer",
                  display: sideBarOpen ? "none" : "flex",
                }}
                width={"100%"}
              >
                <img src={logo} width={150} alt="Logo" />
              </Box>
              <Box
                mr={sideBarOpen ? 0.5 : 2}
                mt={sideBarOpen ? 8 : 1}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: sideBarOpen ? "center" : "end",
                }}
              >
                <IconButton
                  onClick={() => {
                    handleSideBarClose();
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Menu items */}
            <List sx={{ mt: 10, overflowX: "hidden" }} component="nav">
              {sidebarMenu.map((list, index) => {
                const isSelected =
                  selected === list.title &&
                  !childSelected &&
                  selected !== "Settings";
                return (
                  <Grid key={index}>
                    <Box
                      sx={{
                        ml: 2,
                        mr: 1,
                        mt: 1,
                        mb: 2,
                        overflow: "hidden",
                        display: sideBarOpen && "none",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      {list.label}
                    </Box>
                    {list.type !== "label" && (
                      <>
                        <ListItemButton
                          sx={{
                            borderRadius: 3,
                            ml: 2,
                            mr: 2,
                            height: 40,
                            backgroundColor: isSelected ? "#89EDBD" : "",
                            color: isSelected ? "white" : "black",
                            "&:hover": {
                              backgroundColor: "#89EDBD",
                              color: "white",
                            },
                          }}
                          onClick={(e) =>
                            handleItemsClick(list.title, list.path)
                          }
                        >
                          <ListItemIcon
                            sx={{
                              color: "gray",
                              display: "flex",
                              ml: sideBarOpen ? -0.7 : 0,
                            }}
                          >
                            {list.icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{ display: sideBarOpen && "none" }}
                            primary={list.title}
                          />
                        </ListItemButton>
                        <Collapse
                          in={settingsOpen === list.title}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {list.items !== undefined &&
                              list.items.map((item, index) => {
                                const isChildSelected =
                                  childSelected === item.title;
                                return (
                                  <ListItemButton
                                    key={index}
                                    sx={{
                                      borderRadius: 10,
                                      mr: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      pl: sideBarOpen ? 2 : 6,
                                      ml: 1,
                                      mt: 1,
                                      backgroundColor: isChildSelected
                                        ? "#89EDBD"
                                        : "transparent",
                                      color: isChildSelected
                                        ? "white"
                                        : "black",
                                      "&:hover": {
                                        backgroundColor: "#89EDBD",
                                        color: "white",
                                      },
                                    }}
                                    onClick={() => {
                                      handleChildClick(item.title, item.path);
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        color: "gray",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ display: sideBarOpen && "none" }}
                                      primary={item.title}
                                    />
                                  </ListItemButton>
                                );
                              })}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </Grid>
                );
              })}
            </List>

            {/* Logout button */}
          </div>
        </Box>
      </Grid>
      <Grid item position={"relative"} sx={{}} zIndex={3}>
        <Box
          position={"fixed"}
          style={shadowStyleRight}
          sx={{
            height: "100%",
            display: innerWith ? "flex" : "none",
            width: sideBarOpen ? 0 : "270px",
            background: "white",
            overflowX: "auto",
          }}
        >
          {/* Sidebar content */}
          <div style={{ width: "100%" }}>
            {/* Logo and toggle button */}
            <Grid
              position="absolute"
              sx={{
                mt: -6,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              top={15}
            >
              <Box
                onClick={(e) => handleItemsClick("Orders", "/dashboard")}
                sx={{
                  ml: 2,
                  cursor: "pointer",
                  display: sideBarOpen ? "none" : "flex",
                }}
                width={"100%"}
              >
                <img src={logo} width={150} alt="Logo" />
              </Box>
              <Box
                mr={sideBarOpen ? 0.5 : 2}
                mt={sideBarOpen ? 8 : 1}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: sideBarOpen ? "center" : "end",
                }}
              >
                <IconButton
                  onClick={() => {
                    handleSideBarClose();
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Menu items */}
            <List sx={{ mt: 10, overflowX: "hidden" }} component="nav">
              {sidebarMenu.map((list, index) => {
                const isSelected =
                  selected === list.title &&
                  !childSelected &&
                  selected !== "Settings";
                return (
                  <Grid key={index}>
                    <Box
                      sx={{
                        ml: 2,
                        mr: 1,
                        mt: 1,
                        mb: 2,
                        overflow: "hidden",
                        display: sideBarOpen && "none",
                        color: "gray",
                        fontSize: 13,
                      }}
                    >
                      {list.label}
                    </Box>
                    {list.type !== "label" && (
                      <>
                        <ListItemButton
                          sx={{
                            borderRadius: 3,
                            ml: 2,
                            mr: 2,
                            height: 40,
                            backgroundColor: isSelected ? "#89EDBD" : "",
                            color: isSelected ? "white" : "black",
                            "&:hover": {
                              backgroundColor: "#89EDBD",
                              color: "white",
                            },
                          }}
                          onClick={(e) =>
                            handleItemsClick(list.title, list.path)
                          }
                        >
                          <ListItemIcon
                            sx={{
                              color: "gray",
                              display: "flex",
                              ml: sideBarOpen ? -0.7 : 0,
                            }}
                          >
                            {list.icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{ display: sideBarOpen && "none" }}
                            primary={list.title}
                          />
                        </ListItemButton>
                        <Collapse
                          in={settingsOpen === list.title}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {list.items !== undefined &&
                              list.items.map((item, index) => {
                                const isChildSelected =
                                  childSelected === item.title;
                                return (
                                  <ListItemButton
                                    key={index}
                                    sx={{
                                      borderRadius: 10,
                                      mr: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      pl: sideBarOpen ? 2 : 6,
                                      ml: 1,
                                      mt: 1,
                                      backgroundColor: isChildSelected
                                        ? "#89EDBD"
                                        : "transparent",
                                      color: isChildSelected
                                        ? "white"
                                        : "black",
                                      "&:hover": {
                                        backgroundColor: "#89EDBD",
                                        color: "white",
                                      },
                                    }}
                                    onClick={() => {
                                      handleChildClick(item.title, item.path);
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        color: "gray",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ display: sideBarOpen && "none" }}
                                      primary={item.title}
                                    />
                                  </ListItemButton>
                                );
                              })}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </Grid>
                );
              })}
            </List>

            {/* Logout button */}
          </div>
        </Box>
      </Grid>

      {/* Main content */}
      <Grid item sx={{ width: "100%" }}>
        <div
          style={{
            height: "100vh",
            marginLeft: innerWith
              ? sideBarOpen
                ? "0px"
                : "0"
              : sideBarOpen
              ? "75px"
              : "270px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              // overflowY: "auto",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                ...shadowStyleBottom,
                pt: 2,
                pb: 1,
                pr: 2,
                // position: "fixed",
                width: "100%",
                // right: 0,
                zIndex: 2,
                background: "white",
              }}
            >
              {/* Breadcrumbs */}
              {innerWith ? (
                <NavMobileDiv>
                  <IconButton
                    onClick={() => {
                      handleSideBarClose();
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 0,
                    }}
                  >
                    <Chip
                      size="small"
                      label={`Credit :$ ${user?.balance?.toFixed(2)}`}
                    />
                    <SingleSelect
                      title={user?.username}
                      options={OPTIONS_NAVBAR}
                      variant={"outlined"}
                      border="none"
                      fontWeight={600}
                      {...{
                        anchor,
                        handleMenuItemClick,
                        handleClick,
                        handleClose,
                      }}
                    />
                  </Box>
                </NavMobileDiv>
              ) : (
                <Box
                  pl={1}
                  width={"100%"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // pl: "2",
                  }}
                >
                  <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    sx={{ marginY: "20px" }}
                  >
                    {pathnames.map((name, index) => {
                      const isLast = index === pathnames.length - 1;
                      const capitalizedFirstLetter =
                        name.charAt(0).toUpperCase() + name.slice(1);

                      return (
                        <Typography
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: isLast ? "700" : "500",
                          }}
                        >
                          {iconMapping[name] && (
                            <>
                              {iconMapping[name]}
                              <span style={{ margin: "0px 5px" }}> › </span>
                            </>
                          )}
                          <span style={{ margin: "0px 5px" }}>
                            {capitalizedFirstLetter}
                          </span>
                        </Typography>
                      );
                    })}
                  </Breadcrumbs>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // width: "100%"
                      alignItems: "center",
                      gap: 0,
                    }}
                  >
                    <Chip
                      size="small"
                      label={`Credit :$ ${user?.balance?.toFixed(2)}`}
                    />
                    <SingleSelect
                      title={user?.username}
                      options={OPTIONS_NAVBAR}
                      variant={"outlined"}
                      border="none"
                      fontWeight={600}
                      {...{
                        anchor,
                        handleMenuItemClick,
                        handleClick,
                        handleClose,
                      }}
                    />
                  </Box>
                </Box>
              )}
              {/* User info and select dropd  own */}
            </Box>
            {children}{" "}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
