import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

// Import pdfjs library separately
import * as pdfjs from "pdfjs-dist";
import { Download } from "@mui/icons-material";

const PdfViewer = ({ pdfContent }) => {
  const [pdfBlob, setPdfBlob] = useState(null);

  // useEffect(() => {
  //   // Convert the base64 PDF content to a Blob
  //   const decodedData = atob(pdfContent);
  //   const arrayBuffer = new ArrayBuffer(decodedData.length);
  //   const uint8Array = new Uint8Array(pdfContent.data);

  //   for (let i = 0; i < decodedData.length; i++) {
  //     uint8Array[i] = decodedData.charCodeAt(i);
  //   }

  //   const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  //   setPdfBlob(blob);
  // }, [pdfContent]);

  useEffect(() => {
    if (pdfContent && pdfContent.data && pdfContent.data.length > 0) {
      const uint8Array = new Uint8Array(pdfContent.data);
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      setPdfBlob(blob);
    } else {
      console.error("Invalid PDF content");
    }
  }, [pdfContent]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(pdfBlob);
    a.download = "downloaded-pdf.pdf";
    a.click();
  };

  return (
    <div style={{ height: "700px" }}>
      {pdfBlob && (
        <div>
          <Download onClick={handleDownload} style={{ cursor: "pointer" }}>
            Download PDF
          </Download>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
          </Worker>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
