import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DataTable, { createTheme, TableProps } from "react-data-table-component";
import { Box, CircularProgress } from "@mui/material";
const CustomTable = (props) => {
  const {
    customClass = "d-flex flex-column-reverse",
    columns,
    data,
    highlightOnHover = true,
    rowClickable = false,
    handlePagesizeChange,
    handlePageChange,
    totalRecords,
    isLoading = false,
    selectableRows = false,
    handleTableSelectChange = () => {},
    fixedHeader = true,
    onRowClicked = () => {},
    noTableHead = false,
    pagination = true,
  } = props;

  const customStyles = {
    rows: {
      style: {
        "&:hover": {
          cursor: rowClickable === true ? "pointer" : "",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    pagination: {
      style: {
        color: "#10D48E",
        borderTop: "0",
      },
      pageButtonsStyle: {
        color: "#10D48E",
        fill: "#10D48E",
        "&:hover:not(:disabled)": {
          fill: "#10D48E",
        },
      },
    },
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handlePerRowsChange = (event) => {
    setRowsPerPage(event);
    handlePagesizeChange(event);
  };

  const handleTablePageChange = (pgNo) => {
    handlePageChange(pgNo);
  };

  return (
    <Fragment>
      <Box className={customClass}>
        <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
          <DataTable
            columns={columns}
            data={data ? data : []}
            paginationServer
            highlightOnHover={highlightOnHover}
            customStyles={customStyles}
            onChangePage={handleTablePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={totalRecords ? totalRecords : 0}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
            progressPending={isLoading}
            progressComponent={
              <Box flex justifyContent={"center"} my={2} gap={1}>
                <CircularProgress />
              </Box>
            }
            striped
            selectableRows={selectableRows}
            onSelectedRowsChange={handleTableSelectChange}
            fixedHeader={fixedHeader}
            onRowClicked={onRowClicked}
            noTableHead={noTableHead}
            pagination={pagination}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  customClass: PropTypes.string,
  columns: PropTypes.any,
  data: PropTypes.any,
  highlightOnHover: PropTypes.bool,
  pagination: PropTypes.any,
  rowClickable: PropTypes.bool,
  handlePagesizeChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.any,
  isLoading: PropTypes.bool,
  selectableRows: PropTypes.bool,
  handleTableSelectChange: PropTypes.func,
  fixedHeader: PropTypes.bool,
  onRowClicked: PropTypes.func,
};
