import { makeAPICall } from "../../common";
import {
  ADD_BALANCE,
  ADD_CUSTOMER,
  GET_ALL_QUICK_SHIP,
  GET_DYNAMIC_RATE,
  GET_MY_DETAILS,
  GET_ORDERS,
  GET_ORDER_DETAILS,
  GET_RATES,
  POST_ADMIN_CREATE_LABEL,
  POST_BULK_DELETE_ORDER_ITEM,
  POST_CREATE_LABEL,
  POST_DISCONNECT_STORE,
  POST_QUICK_SHIP_RATES,
  POST_QUICK_SHIP_SHIPMENT,
  POST_SYNC_ORDERS,
  POST_UPDATE_ORDER_STATUS,
  POST_UPDATE_TRACKING_NUMBER,
  POST_USER_LOGIN,
  POST_USER_REGISTER,
  UPDATE_BALANCE,
  UPDATE_USER,
} from "./urlHelper";

export const getAllOrders = (params, orderType) =>
  makeAPICall({
    option: { method: "get", url: `/${orderType}/${GET_ORDERS}` },
    params,
  });
export const postSyncOrders = (params, orderType) =>
  makeAPICall({
    option: { method: "post", url: `/${orderType}/${POST_SYNC_ORDERS}` },
    params,
  });
export const postBulkDeleteOrderItem = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `/${orderType}/${POST_BULK_DELETE_ORDER_ITEM}`,
    },
    data,
  });
export const postUserLogin = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_USER_LOGIN}` },
    data,
  });
export const postUserRegister = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_USER_REGISTER}` },
    data,
  });
export const getMyDetails = (params) =>
  makeAPICall({
    option: { method: "get", url: `/${GET_MY_DETAILS}` },
    params,
  });
export const updateUserDetails = (data) =>
  makeAPICall({
    option: { method: "put", url: `/${UPDATE_USER}` },
    data,
  });

export const postDisconnectStore = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_DISCONNECT_STORE}` },
    data,
  });
export const postCreateLabel = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_CREATE_LABEL}` },
    data,
  });
export const postAdminCreateLabel = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_ADMIN_CREATE_LABEL}` },
    data,
  });
export const postUpdateTackingNumber = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `${orderType}/${POST_UPDATE_TRACKING_NUMBER}`,
    },
    data,
  });
export const postUpdateOrderStatus = (data, orderType) =>
  makeAPICall({
    option: {
      method: "post",
      url: `${orderType}/${POST_UPDATE_ORDER_STATUS}`,
    },
    data,
  });
export const getAllQuickShip = (id) =>
  makeAPICall({
    option: {
      method: "get",
      url: `/${GET_ALL_QUICK_SHIP}/${id}`,
    },
  });
export const getOrderDetails = (params, orderDetailType) =>
  makeAPICall({
    option: { method: "get", url: `/${orderDetailType}/${GET_ORDER_DETAILS}` },
    params,
  });

export const getRatesData = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${GET_RATES}` },
    data,
  });

export const addBalanceClient = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${ADD_BALANCE}` },
    data,
  });

export const addCustomerDetails = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${ADD_CUSTOMER}` },
    data,
  });
export const updateBalace = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${UPDATE_BALANCE}` },
    data,
  });
export const postQuickShipRates = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_QUICK_SHIP_RATES}` },
    data,
  });
export const postQuickShipShipment = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${POST_QUICK_SHIP_SHIPMENT}` },
    data,
  });
export const getDynamicRates = (data) =>
  makeAPICall({
    option: { method: "post", url: `/${GET_DYNAMIC_RATE}` },
    data,
  });
