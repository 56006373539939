import React, { useEffect, useState } from "react";
import { Divider, Typography, styled } from "@mui/material";
import BasicSelect from "../../OrderSettings/shared/BasicSelect";
import BasicTextField from "../../OrderSettings/shared/BasicTextField";

const FromFormContainer = styled("div")(() => ({
  // width: "48%",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "10px 15px 10px 15px",
}));

const ResetButton = styled("button")(() => ({
  fontWeight: 600,
  backgroundColor: "white",
  border: "0px",
  cursor: "pointer",
}));

const ShipForm = (props) => {
  const { handleForm, formValue, setFormValue, type, initialShipValues } =
    props;
  const {
    country,
    company,
    name,
    state,
    postal_zip_code,
    phone,
    street1,
    street2,
    city,
    country_code,
    province_code,
    provinces,
  } = formValue;

  const [countryList, setCountryList] = useState([
    /* Your country list here */
  ]);
  const [provinceList, setProvinceList] = useState([]);

  useEffect(() => {
    if (country === "" || country === null) {
      setProvinceList([]);
      setFormValue({ ...formValue, province: "" });
    } else {
      setProvinceList(provinces[country] || []);
    }
  }, [country]);

  const enableResetButton =
    Object.values(formValue).filter((value) => value !== "").length >= 2;

  return (
    <FromFormContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ fontWeight: 600 }}>
          {type === "shipFrom" ? "Ship From" : "Ship To"}
        </Typography>
        <ResetButton
          disabled={!enableResetButton}
          onClick={() => setFormValue(initialShipValues)}
          style={{ color: enableResetButton ? "#00b8c4" : "gray" }}
        >
          Reset
        </ResetButton>
      </div>
      <Divider style={{ marginBottom: 7, marginTop: 7 }} />
      <div>
        <div style={{ display: "flex" }}>
          <BasicTextField
            handleForm={handleForm}
            width={"50%"}
            label="Company *"
            fieldName="company"
            fieldStyle={{ marginRight: 5 }}
            value={company}
          />

          <BasicTextField
            handleForm={handleForm}
            width={"50%"}
            label="Name *"
            fieldName="name"
            value={name}
          />
        </div>
        <BasicTextField
          handleForm={handleForm}
          width={"100%"}
          label="Street 1 *"
          fieldName="street1"
          value={street1}
        />
        <BasicTextField
          handleForm={handleForm}
          width={"100%"}
          label="Street 2 "
          fieldName="street2"
          value={street2}
        />
      </div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <BasicSelect
          dataArrayObjects={countryList}
          handleForm={handleForm}
          label="Country *"
          dropDownName="country"
          value={country}
        />
        <BasicTextField
          handleForm={handleForm}
          label="Postal/zip code *"
          fieldName="postal_zip_code"
          fieldStyle={{ marginRight: 5 }}
          value={postal_zip_code}
        />
        <BasicSelect
          dataArrayObjects={provinceList}
          handleForm={handleForm}
          label="State *"
          dropDownName="state"
          provClicked={true}
          value={state}
          noOptionsText="First Select Country"
          // disabled={provinceList.length <= 0 ? true : false}
        />
        <BasicTextField
          handleForm={handleForm}
          label="City *"
          fieldName="city"
          value={city}
        />
      </div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <BasicTextField
          handleForm={handleForm}
          width={"100%"}
          label="Phone *"
          fieldName="phone"
          fieldStyle={{ marginRight: 5 }}
          value={phone}
        />
        <BasicTextField
          handleForm={handleForm}
          width={"100%"}
          label="Country code *"
          fieldName="country_code"
          fieldStyle={{ marginRight: 5 }}
          value={country_code}
        />
        <BasicTextField
          handleForm={handleForm}
          width={"100%"}
          label="Province Code *"
          fieldName="province_code"
          value={province_code}
        />
      </div>
    </FromFormContainer>
  );
};

export default ShipForm;
