import React, { useEffect } from "react";
import LogIn from "./app/views/sessions/LogIn";
import Register from "./app/views/sessions/Register";
import Forgot from "./app/views/sessions/Forgot.jsx";
import Reset from "./app/views/sessions/Reset.jsx";
import Analytics from "./app/views/dashboard/Analytics";
import QuickShip from "./app/views/shippment/QuickShip/QuickShip.jsx";
import Company from "./app/views/settings/CompanySettings/Company";
import Stores from "./app/views/settings/StoreSettings/Stores";
import Billing from "./app/views/settings/BillingSettings/Billing.jsx";
import CreateOrder from "./app/views/settings/OrderSettings/CreateOrder";
import OrderDetails from "./app/views/dashboard/OrderDetails";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "./app/contexts/AuthContext.js";
import Loading from "./app/components/MatxLoading.jsx";
import PageNotFound from "./app/views/page-not-found/PageNotFound.jsx";
import Shipment from "./app/views/shippment/QuickShip/Shipment.jsx";
import Dashboard from "./app/views/home/Dashboard.jsx";

const AppRouting = () => {
  const { user, profileLoading } = useAuth();

  if (profileLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      {!user ? (
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/" element={<LogIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset-password/:token" element={<Reset />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<Analytics />} />
          <Route path="/shipping/shipment" element={<Shipment />} />
          <Route path="/shipping/quick-ship" element={<QuickShip />} />
          <Route path="/setting/connect" element={<Company />} />
          <Route path="/setting/store" element={<Stores />} />
          <Route path="/setting/payment" element={<Billing />} />
          <Route path="/order/create" element={<CreateOrder />} />
          <Route path="/order/:orderType/:id" element={<OrderDetails />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </>
  );
};

export default AppRouting;
