import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Card,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller } from "react-hook-form";
//import { getAllPackages, getPackageById } from 'app/helpers/BackendHelper';
// import { Toast } from "../../../../components/Toaster/Toaster";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
const PackageDetailForm = ({
  packageControl,
  packageSetValue,
  fields,
  append,
  remove,
  packageErrors,
  getValues,
  setDisablePackages,
  disablePackages,
}) => {
  const packages = [
    {
      id: 1,
      label: "Envelope",
    },
    {
      id: 2,
      label: "Pak",
    },
    {
      id: 3,
      label: "Package",
    },
    {
      id: 4,
      label: "Pallet",
    },
  ];

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const commonInputStyle = {};
  // const commonInputStyle = {
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: primary,
  //   },
  //   '&:hover > .MuiOutlinedInput-notchedOutline': {
  //     borderColor: primary,
  //   },
  // };

  const [loading, setLoading] = useState(false);

  const [selectedPackageLoading, setSelectedPackageLoading] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [indexValue, setIndexValue] = useState("");
  useEffect(() => {
    getAllPackagesAPICall();
    // eslint-disable-next-line
  }, []);

  const packageFieldsArrayValue = [
    "packageName",
    "length",
    "width",
    "height",
    "weight",
    "weightOZ",
  ];
  useEffect(() => {
    if (selectedPackage) {
      packageFieldsArrayValue.forEach((field) => {
        packageSetValue(
          `packageField.${indexValue}.${field}`,
          selectedPackage?.[field]
        );
      });
    }
  }, [selectedPackage]);
  const getAllPackagesAPICall = () => {
    // setLoading(true);
    // getAllPackages()
    //   .then((response) => {
    //     // console.info('response =>', response);
    //     setPackageList(response?.data);
    //   })
    //   .catch((error) => {
    //     // console.info('error =>', error)
    //     Toast.error(error?.response?.errorMessage);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const getPackageByIdAPICall = (id) => {
    // setSelectedPackageLoading(true);
    // getPackageById(id)
    //   .then((response) => {
    //     setSelectedPackage(response?.data);
    //   })
    //   .catch((error) => {
    //     Toast.error(error?.response?.errorMessage);
    //   })
    //   .finally(() => {
    //     setSelectedPackageLoading(false);
    //   });
  };
  const defaultPackages = {
    length: "1",
    width: "1",
    height: "1",
    weight: "1",
    weightOZ: "0",
    qty: " ",
    packageName: "",
  };
  const handleAddPackage = () => {
    append(defaultPackages, { shouldFocus: false });
  };
  const handlePackageSelectChange = (e, onChange) => {
    const { value: selectedValue } = e.target;
    onChange(selectedValue);
    if (selectedValue === 1) {
      setDisablePackages(true);
      packageSetValue("packageField", [defaultPackages]);
    } else {
      if (selectedPackage) {
        packageFieldsArrayValue.forEach((field) => {
          packageSetValue(
            `packageField.${indexValue}.${field}`,
            selectedPackage?.[field]
          );
        });
      }
      setDisablePackages(false);
    }
  };
  const innerWidth = useMediaQuery("(max-width:760px)");

  return (
    <Card elevation={4}>
      <Box overflow="auto">
        <Grid sx={{ p: 2 }} spacing={2} container>
          <TableContainer sx={{ padding: innerWidth ? "0" : "16px" }}>
            <Table>
              <TableBody>
                {fields &&
                  fields?.length > 0 &&
                  fields?.map((packageValue, i) => {
                    return (
                      <Grid
                        container
                        spacing={3}
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                          justifyContent: innerWidth
                            ? "center"
                            : "space-between",
                          alignItem: "center",
                          padding: innerWidth ? "20px 0px 0px 20px" : "0px",
                        }}
                      >
                        {/* <TableCell>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Default Package
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.packageName`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <Select
                                    style={{ width: "150px" }}
                                    labelId={`packageField.${i}.packageName`}
                                    id={`packageField.${i}.packageName`}
                                    disabled={disablePackages}
                                    label="Default Package"
                                    onChange={(event, val) => {
                                      let packageValueId = event.target.value;
                                      getPackageByIdAPICall(packageValueId);
                                      setIndexValue(i);
                                      return onChange(packageValueId);
                                    }}
                                    sx={commonInputStyle}
                                  >
                                    {packageList?.length > 0 &&
                                    !disablePackages ? (
                                      packageList?.map(
                                        (packageValue, index) => {
                                          if (packageValue?.isActive === true) {
                                            return (
                                              <MenuItem
                                                value={`${packageValue?.id}`}
                                                key={index}
                                              >
                                                {packageValue?.packageName}
                                              </MenuItem>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <MenuItem value={"No Data"}>
                                        No Data Found
                                      </MenuItem>
                                    )}
                                  </Select>
                                );
                              }}
                            />
                          </FormControl>
                        </TableCell> */}

                        <Grid
                          item
                          md={2}
                          xs={12}
                          sm={12}
                          sx={{ width: innerWidth ? "fullLength" : "none" }}
                        >
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Length (In)
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.length`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <OutlinedInput
                                    id={`packageField.${i}.length`}
                                    name={`packageField.${i}.length`}
                                    label="Length (In)"
                                    value={disablePackages ? "1" : value}
                                    disabled={disablePackages}
                                    error={
                                      !!packageErrors?.packageField?.[i]?.length
                                    }
                                    onChange={(event, val) => {
                                      return onChange(event.target.value);
                                    }}
                                    type={"number"}
                                    sx={commonInputStyle}
                                  />
                                );
                              }}
                            />
                            {packageErrors.packageField?.[i]?.length && (
                              <ErrorLabel>
                                {
                                  packageErrors.packageField?.[i]?.length
                                    ?.message
                                }
                              </ErrorLabel>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Width (In)
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.width`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <OutlinedInput
                                    id={`packageField.${i}.width`}
                                    name={`packageField.${i}.width`}
                                    label="Width (In)"
                                    disabled={disablePackages}
                                    value={disablePackages ? "1" : value}
                                    error={
                                      !!packageErrors?.packageField?.[i]?.width
                                    }
                                    onChange={(event, val) => {
                                      return onChange(event.target.value);
                                    }}
                                    type={"number"}
                                    sx={commonInputStyle}
                                  />
                                );
                              }}
                            />
                            {packageErrors.packageField?.[i]?.width && (
                              <ErrorLabel>
                                {
                                  packageErrors.packageField?.[i]?.width
                                    ?.message
                                }
                              </ErrorLabel>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Height (In)
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.height`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <OutlinedInput
                                    id={`packageField.${i}.height`}
                                    name={`packageField.${i}.height`}
                                    label="Height (In)"
                                    value={disablePackages ? "1" : value}
                                    disabled={disablePackages}
                                    error={
                                      !!packageErrors?.packageField?.[i]?.height
                                    }
                                    onChange={(event, val) => {
                                      return onChange(event.target.value);
                                    }}
                                    type={"number"}
                                    sx={commonInputStyle}
                                  />
                                );
                              }}
                            />
                            {packageErrors.packageField?.[i]?.height && (
                              <ErrorLabel>
                                {
                                  packageErrors.packageField?.[i]?.height
                                    ?.message
                                }
                              </ErrorLabel>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Weight (lb)
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.weight`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <OutlinedInput
                                    id={`packageField.${i}.weight`}
                                    name={`packageField.${i}.weight`}
                                    label="Weight (lb)"
                                    value={disablePackages ? "1" : value}
                                    disabled={disablePackages}
                                    error={
                                      !!packageErrors?.packageField?.[i]?.weight
                                    }
                                    onChange={(event, val) => {
                                      return onChange(event.target.value);
                                    }}
                                    type={"number"}
                                    sx={commonInputStyle}
                                  />
                                );
                              }}
                            />
                            {packageErrors.packageField?.[i]?.weight && (
                              <ErrorLabel>
                                {
                                  packageErrors.packageField?.[i]?.weight
                                    ?.message
                                }
                              </ErrorLabel>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="label-order-number">
                              Quantity
                            </InputLabel>
                            <Controller
                              control={packageControl}
                              name={`packageField.${i}.qty`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <OutlinedInput
                                    id={`packageField.${i}.qty`}
                                    name={`packageField.${i}.qty`}
                                    label="Quantity"
                                    value={disablePackages ? "1" : value}
                                    disabled={disablePackages}
                                    error={
                                      !!packageErrors?.packageField?.[i]?.qty
                                    }
                                    onChange={(event, val) => {
                                      return onChange(event.target.value);
                                    }}
                                    type={"number"}
                                    sx={commonInputStyle}
                                  />
                                );
                              }}
                            />
                            {packageErrors.packageField?.[i]?.qty && (
                              <ErrorLabel>
                                {packageErrors.packageField?.[i]?.qty?.message}
                              </ErrorLabel>
                            )}
                          </FormControl>
                        </Grid>

                        {/* <TableCell sx={{ width: "60px" }}>
                          {i === 0 ? (
                            <IconButton
                              aria-label="Add Package"
                              title="Add Package"
                              disabled={disablePackages}
                              onClick={() => handleAddPackage()}
                            >
                              <AddIcon className="icon" color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="Add Package"
                              title="Add Package"
                              onClick={() => remove(i)}
                              disabled={disablePackages}
                            >
                              <Icon className="icon" color="error">
                                delete
                              </Icon>
                            </IconButton>
                          )}
                        </TableCell> */}
                      </Grid>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Card>
  );
};

export default PackageDetailForm;
const ErrorLabel = styled("span")(() => ({
  color: "red",
  margin: "5px 0px 0px 5px",
}));
