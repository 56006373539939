import HomeIcon from "@mui/icons-material/Home";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

export const topBarHeight = 84;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const OPTIONS_NAVBAR = [
  {
    id: 0,
    label: "Home",
    value: "Home",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: "Logout",
    value: "Logout",
    icon: <PowerSettingsNewIcon />,
  },
];
export const OPTIONS_STORE = [
  {
    id: 0,
    label: "Amazon",
    value: "amazon",
  },
  {
    id: 1,
    label: "Ebay",
    value: "ebay",
  },
  {
    id: 2,
    label: "Walmart",
    value: "walmart",
  },
];
export const OPTIONS_STATUS = [
  {
    id: 0,
    label: "All",
    value: "",
  },
  {
    id: 1,
    label: "Unfulfilled",
    value: "Unfulfilled",
  },
  {
    id: 2,
    label: "Ready for Ship",
    value: "Ready for Ship",
  },
  {
    id: 3,
    label: "Shipped",
    value: "Shipped",
  },
];
export const TIME_ZONE_OPTIONS = [
  {
    id: 1,
    label: "All Orders By Timezone",
    value: "all",
  },
  {
    id: 2,
    label: "Today",
    value: "today",
  },
  {
    id: 3,
    label: "This Week",
    value: "thisWeek",
  },
  {
    id: 4,
    label: "This Month",
    value: "thisMonth",
  },
  {
    id: 5,
    label: "Custom Range",
    value: "customDateRange",
  },
];

export const CARRIER_LOGOS = {
  Aramex: "Aramex.png",
  "Canada Post": "canadapost.png",
  Canpar: "Canpar.png",
  CPX: "cpx.png",
  "Day And Ross": "day_and_ross.png",
  DHL: "DHL.png",
  Eshipper: "eshipper.png",
  "eShipper Sameday": "eShipperSameday.png",
  "Federal Express": "federalexpress.png",
  FedEx: "FedEx.png",
  FlashBox: "FlashBox.png",
  "Fleet Optics": "fleetoptics.png",
  "Franchise Logo": "franchise-logo.svg",
  Intelcom: "Intelcom.png",
  P44: "p44.png",
  Project44: "project44.png",
  Purolator: "purolator.svg",
  PYK: "pyk.png",
  Rivo: "Rivo.svg",
  Sameday: "sameday.png",
  "SmartePost INTL": "smartepost.png",
  Texity: "texity.png",
  "Uni Uni": "uni-uni.png",
  UPS: "ups.png",
  USPS: "usps.png",
  "Amazon Shipping": "amazon.png",
};
export const CLOSING_TIME = [
  "03:00",

  "03:30",

  "04:00",

  "04:30",

  "05:00",

  "05:30",

  "06:00",

  "06:30",

  "07:00",

  "07:30",

  "08:00",

  "08:30",

  "09:00",

  "09:30",

  "10:00",

  "10:30",

  "11:00",

  "11:30",

  "12:00",

  "12:30",

  "13:00",

  "13:30",

  "14:00",

  "14:30",

  "15:00",

  "15:30",

  "16:00",

  "16:30",

  "17:00",

  "17:30",

  "18:00",

  "18:30",

  "19:00",

  "19:30",

  "20:00",

  "20:30",

  "21:00",

  "21:30",

  "22:00",

  "22:30",

  "23:00",

  "23:30",
];
export const PICKUP_LOCATION = [
  "None",
  "SideDoor",
  "OutsideDoor",
  "FrontPorch",
  "Basement",
  "FrontDesk",
  "BetweenDoors",
  "Security",
  "Counter",
  "Lobby",
  "Mailbox",
  "GateHouse",
  "Desk",
  "Kiosk",
  "Shipping",
  "ProShop",
  "FrontDoor",
  "Receiving",
  "Reception",
  "BackDoor",
  "Lab",
];
export const PICKUP_TIME = [
  "00:00",

  "00:30",

  "01:00",

  "01:30",

  "02:00",

  "02:30",

  "03:00",

  "03:30",

  "04:00",

  "04:30",

  "05:00",

  "05:30",

  "06:00",

  "06:30",

  "07:00",

  "07:30",

  "08:00",

  "08:30",

  "09:00",

  "09:30",

  "10:00",

  "10:30",

  "11:00",

  "11:30",

  "12:00",

  "12:30",

  "13:00",

  "13:30",

  "14:00",

  "14:30",

  "15:00",

  "15:30",

  "16:00",

  "16:30",

  "17:00",

  "17:30",

  "18:00",

  "18:30",

  "19:00",

  "19:30",

  "20:00",

  "20:30",
];
