import React, { useState } from "react";
import RenderModal from "../../../components/Modal/RenderModal";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  Divider,
  Button,
  styled,
  TextField,
  Autocomplete,
} from "@mui/material";
import { countryList, pricingData } from "../../../../countryData";
import CustomButton from "../../../components/CustomButton";
import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from "../../../contexts/AuthContext";
import PaymentForm from "./PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { BASE_URL } from "../../../../common";

const PaymentModal = ({ handleClose, open }) => {

  const quickShipScheme = Yup.object().shape({
    name: Yup.string().nullable().required(),
    country: Yup.string().nullable().required(),
    street: Yup.string().nullable().required(),
    city: Yup.string().nullable().required(),
    state: Yup.string().nullable().required(),
    zip: Yup.string().nullable().required(),
    phone: Yup.string().nullable().required(),
    price: Yup.string().nullable().required(),
  });
  const defaultValue = {
    name: null,
    street: null,
    street2: null,
    country: null,
    state: null,
    city: null,
    zip: null,
    phone: null,
    email: null,
    price: null,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(quickShipScheme),
    defaultValues: defaultValue,
  });
  const [submitData, setSubmitData] = useState(null);
  const {user}= useAuth()
  console.log(errors, "errors");
  const handleSubmitData = (data) => {
    setSubmitData(data);
  };
  const getOptionLabelCountry = (option) => {
    const correspondingCountry = countryList.find(
      (country) => country.code === option.code
    );
    if (correspondingCountry) {
      return correspondingCountry.name;
    } else {
      return "";
    }
  };
  // const handleOpenUrl = () => {
  //   // Specify the URL you want to open in a new tab
  //   const url =
  //     "https://marketplace.paypro.com.pk/pyb?bid=MTY5MjIzMzQ2MDAwMDE%3d";

  //   // Open a new tab with the specified URL
  //   window.open(url, "_blank");
  // };

  const stripe =  loadStripe("pk_live_51LhaMCDvHAvdTIHCga0LoG3FEaQYdl4Wq0djH9Z25dnkD3oHfhWItDlj2YXsYDIflPA9XXOjf6OdJu1M6IqYBHvJ005iNov4X9");
  // const stripe =  loadStripe("pk_test_51LhaMCDvHAvdTIHC6qZBEQJvxrdNsHduFwmCmKKRFvqTZHC7KxvhSCxDIQdOg2jVvG2ftO6iN2f8hlKqNNObFMnX00qcpZQeRH");


  const makePayment = async()=>{

    const body = {
        price: 100,
        user: user?.id,
        userData: submitData

    }
    const headers = {
        "Content-Type":"application/json"
    }
    const response = await fetch(`${BASE_URL}create-checkout-session`,{
        method:"POST",
        headers:headers,
        body:JSON.stringify(body)
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
        sessionId:session.id
    });
    
    if(result.error){
        console.log(result.error);
    }
}


  return (
    <RenderModal
      title={"Card Details"}
      open={open}
      handleClose={() => {
        setSubmitData(null);
        reset(defaultValue);
        handleClose();
      }}
      displayCloseIcon
    >
      <Elements stripe={stripe}>
        <PaymentForm handleClose={handleClose} />
      </Elements>

      {/* {submitData !== null ? (
        <Grid sx={{ p: 2 }} spacing={2} container>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Name :</div>
            <div>{submitData?.name}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Street :</div>
            <div>{submitData?.street}</div>
          </Grid>{" "}
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Street2 :</div>
            <div>{submitData?.street2}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Country :</div>
            <div>{submitData?.country}</div>
          </Grid>{" "}
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Price :</div>
            <div>${submitData?.price}</div>
          </Grid>{" "}
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>State :</div>
            <div>{submitData?.state}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>City :</div>
            <div>{submitData?.city}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Postal/Zip Code :</div>
            <div>{submitData?.zip}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Email :</div>
            <div>{submitData?.email}</div>
          </Grid>
          <Grid sx={{ display: "flex" }} item md={12} xs={12} sm={12}>
            <div style={{ fontWeight: 600 }}>Phone :</div>
            <div>{submitData?.phone}</div>
          </Grid>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "end" }}>
            <div style={{ marginRight: 10 }}>
              <CustomButton
                width="120px"
                label={"Change Data"}
                onClick={() => setSubmitData(null)}
              />
            </div>
            <CustomButton
              green
              width="120px"
              label={"Make Payment"}
              onClick={makePayment}
            />
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid sx={{ p: 2 }} spacing={2} container>
            <Grid item md={12} xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-company">Name</InputLabel>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="name"
                        name="name"
                        label="Company"
                        // autoComplete="of"
                        error={!!errors.name}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-address1">Street</InputLabel>
                <Controller
                  control={control}
                  name="street"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="street"
                        name="street"
                        label="Street"
                        // autoComplete="of"
                        error={!!errors.street}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-address2">Street2</InputLabel>
                <Controller
                  control={control}
                  name="street2"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="street2"
                        name="street2"
                        label="Street2"
                        // autoComplete="of"
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={6} sm={6}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => {
                    return (
                      <>
                        <Autocomplete
                          id="virtualize-demo"
                          autoComplete="of"
                          disablePortal={false}
                          onChange={(event, value) => {
                            if (value) {
                              field.onChange(value.name);
                            }
                          }}
                          value={
                            countryList.find(
                              (country) => country.name === field.value
                            ) || null
                          }
                          getOptionLabel={getOptionLabelCountry}
                          options={countryList}
                          fullWidth
                          clearIcon={null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              autoComplete="of"
                              type="text"
                              label="Country"
                              error={!!errors.country}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={6} sm={6}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="price"
                  render={({ field }) => {
                    return (
                      <>
                        <Autocomplete
                          id="virtualize-demo"
                          autoComplete="of"
                          disablePortal={false}
                          onChange={(event, value) => {
                            if (value) {
                              field.onChange(value); // Set value to the desired property
                            }
                          }}
                          options={pricingData.map((i) => i.priceLabel)}
                          fullWidth
                          clearIcon={null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              autoComplete="of"
                              type="text"
                              label="Price (Currency USD)"
                              error={!!errors.price}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-city">State</InputLabel>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="state"
                        name="state"
                        label="State"
                        // autoComplete="of"
                        error={!!errors.state}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-city">City</InputLabel>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="city"
                        name="city"
                        label="City"
                        // autoComplete="of"
                        error={!!errors.city}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-zipCodeFrom">
                  Postal/Zip Code
                </InputLabel>
                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="zip"
                        name="zip"
                        // autoComplete="of"
                        label="Postal/Zip Code"
                        error={!!errors.zip}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"text"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item md={6} xs={6} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-email">Email</InputLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="email"
                        name="email"
                        label="Email"
                        // autoComplete="of"
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"email"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={6} sm={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="label-phone">Phone</InputLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <OutlinedInput
                        id="phone"
                        name="phone"
                        label="Phone"
                        // autoComplete="of"
                        error={!!errors.phone}
                        value={value ? value : ""}
                        onChange={(event, val) => {
                          return onChange(event.target.value);
                        }}
                        type={"number"}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              sx={{ display: "flex", justifyContent: "end" }}
              item
              md={12}
              xs={12}
              sm={4}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#30881e", textTransform: "none" }}
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      )} */}
    </RenderModal>
  );
};
const ButtonNext = styled("div")(({ green }) => ({
  fontSize: "16px",
  fontWeight: "700",
  color: green ? "white" : "#586566",
  borderRadius: "5px",
  paddingBottom: 10,
  paddingTop: 10,
  textAlign: "center",
  width: 130,
  cursor: "pointer",
  boxShadow: green ? "rgba(0, 0, 0, 0.16) 0px 1px 4px   " : "",
  background: green ? "#30881e" : "none",
  "&:hover": {
    boxShadow:
      "rgba(0, 0, 0, 0.27) 0px 0px .25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em  ",
    background: green ? "#226015" : "white",
  },
}));
export default PaymentModal;
