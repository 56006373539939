import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, TextField, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../../assets/images/DeleveryDayLogo.png";
import { BASE_URL } from "../../../common";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email address").required(""),
});

const Forgot = () => {
  const innerWith = useMediaQuery("(max-width:360px)");
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [reset, setReset] = useState("");

  const handleFormSubmit = async (values) => {
    setLoading(true);

    try {
      const response = await fetch(`${BASE_URL}user/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Failed to reset password");
      }

      const data = await response.json(); // Assuming the response contains JSON data
      setMessage(data.message); // Assuming the response has a key 'message'
      setReset(data.reset); // Assuming the response has a key 'message'

      console.log("reset password email has been sent");
      console.log("Message from server:", data.message);
    } catch (error) {
      console.error("Error resetting password:", error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#e9f1f5",
      }}
      container
    >
      <Grid item>
        <Card
          sx={{
            textAlign: "center",
            m: innerWith ? 1 : 2,
            borderRadius: 4,
            maxWidth: 500,
          }}
          elevation={4}
        >
          <div style={{ height: 150, marginTop: -55 }}>
            <img src={logo} width={200} />
          </div>
          <Grid>
            <Box p={innerWith ? 1 : 4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={
                        touched.email &&
                        (Boolean(errors.email) || values.email === "")
                      }
                      sx={{ mb: 2 }}
                    />

                    <LoadingButton
                      type="submit"
                      disabled={disable}
                      style={{
                        backgroundColor: "#89edbd",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}
                    >
                      Forgot password
                    </LoadingButton>
                    <div>
                      Go back to
                      <NavLink
                        to="/"
                        style={{
                          color: "#89edbd",
                          marginLeft: 5,
                        }}
                      >
                        Login
                      </NavLink>
                    </div>
                  </form>
                )}
              </Formik>
            </Box>{" "}
            {message && (
              <div>
                <div>
                  This is your reset password link{" "}
                  <Link to={`/${reset}`}>Reset</Link>
                </div>
              </div>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Forgot;
