import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DataTable, { createTheme, TableProps } from "react-data-table-component";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  CircularProgress,
  styled,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useAuth } from "../../contexts/AuthContext";
import CustomButton from "../CustomButton";

const TableCustom = (props) => {
  const {
    customClass = "d-flex flex-column-reverse",
    columns,
    data,
    selectedValue,
    handleChange,
    handleSearchOrder,
    highlightOnHover = true,
    rowClickable = false,
    handlePagesizeChange,
    handlePageChange = () => {},
    totalRecords,
    isLoading = false,
    selectableRows = false,
    handleTableSelectChange = () => {},
    fixedHeader = true,
    onRowClicked = () => {},
    noTableHead = false,
    searchFinalValue,
    syncOrders,
    setSearch,
    serachField = true,
    pagination = true,
    tableMaxHeight,
    tableHeight,
  } = props;

  const customStyles = {
    table: {
      style: {
        maxHeight: tableMaxHeight,
        height: tableHeight,
      },
    },
    rows: {
      style: {
        "&:hover": {
          cursor: rowClickable === true ? "pointer" : "",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    pagination: {
      style: {
        color: "#10D48E",
        borderTop: "0",
      },
      pageButtonsStyle: {
        color: "#10D48E",
        fill: "#10D48E",
        "&:hover:not(:disabled)": {
          fill: "#10D48E",
        },
      },
    },
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handlePerRowsChange = (event) => {
    console.log(event, "pagesize");
    setRowsPerPage(event);
    handlePageChange(1);
    // handlePagesizeChange
    // setPage(0);
    // handlePagesizeChange(1);
  };

  const handleTablePageChange = (pgNo) => {
    console.log(pgNo, "pagenumber");
    handlePageChange(pgNo);
    // handlePagesizeChange(pgSize);
  };

  const navigator = useNavigate();

  return (
    <Fragment>
      <Box className={customClass}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <DataTable
            columns={columns}
            data={data ? data : []}
            paginationServer
            highlightOnHover={highlightOnHover}
            customStyles={customStyles}
            onChangePage={handleTablePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={totalRecords ? totalRecords : 0}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
            progressPending={isLoading}
            progressComponent={
              <Box flex justifyContent={"center"} my={2} gap={1}>
                <CircularProgress />
              </Box>
            }
            striped
            selectableRows={selectableRows}
            onSelectedRowsChange={handleTableSelectChange}
            fixedHeader={fixedHeader}
            onRowClicked={onRowClicked}
            noTableHead={noTableHead}
            pagination={pagination}
            syncOrders={syncOrders}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default TableCustom;

TableCustom.propTypes = {
  customClass: PropTypes.string,
  columns: PropTypes.any,
  data: PropTypes.any,
  highlightOnHover: PropTypes.bool,
  pagination: PropTypes.any,
  rowClickable: PropTypes.bool,
  handlePagesizeChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.any,
  isLoading: PropTypes.bool,
  selectableRows: PropTypes.bool,
  handleTableSelectChange: PropTypes.func,
  fixedHeader: PropTypes.bool,
  onRowClicked: PropTypes.func,
};
