import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Select,
  InputLabel,
  FormControl,
  Chip,
  Grid,
  OutlinedInput,
  InputAdornment,
  Button,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import CustomTable from "../../components/Table/CustomTable";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import ActionMenu from "./ActionMenu/ActionMenu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CountryFlag from "../../components/CountryFlag";
import "../../../index.css";
import moment from "moment";
import { useAuth } from "../../contexts/AuthContext";
import SingleSelect from "../../components/AppSelect/SingleSelect";
import { OPTIONS_STATUS, OPTIONS_STORE } from "../../utils/constant";
const DashboardOrder = (props) => {
  const innerWidth = useMediaQuery("(max-width:760px)");
  const {
    searchFinalValue,
    setSearchFinalValue,
    paginationValue,
    setPaginationValue,
    handleChange,
    totalPages,
    selectOrderStausValue,
    handleStatusChange,
    anchorStatus,
    handleStatusSelectClick,
    handleStatusSelectClose,
    handleStatusMenuItemClick,
  } = props;
  const {
    dateRangeType,
    setDateRangeType,
    filterOrderData,
    setFilterOrderData,
    stateDateMulti,
    setStateDateMulti,
    allOrders,
    selectedValue,
    setSelectedValue,
    syncOrdersApiCall,
    bulkDeleteOrderItemApiCall,
  } = useDashboardContext();
  const { user } = useAuth();
  console.log(allOrders, "allOrders");
  const [search, setSearch] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const navigate = useNavigate();
  const handleSearchOrder = () => {
    setSearchFinalValue(search);
  };
  const { dashboardLoading } = useDashboardContext();
  const handlePagesizeChange = (pgSize) => {
    let paramValue = {
      ...paginationValue,
      pageSize: pgSize,
    };
    console.log(paramValue, "paramValue");
    setPaginationValue(paramValue);
  };
  const handlePageChange = (pgNo) => {
    let paramValue = {
      ...paginationValue,
      pageNumber: pgNo,
    };

    setPaginationValue(paramValue);
  };

  //  row details
  const ebayOrderDetails = [
    {
      id: 1,
      name: <Box className="text-wrap">Order Id</Box>,
      cell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: 250,
              minWidth: 250,
              alignItems: "center",
              py: "5px",
            }}
          >
            <img
              width={"50px"}
              src={"/assets/media/stores/ebay_logo.png"}
              alt=""
            />
            <div
              style={{
                fontSize: innerWidth ? "10px" : "16px",
                marginLeft: "5px",
                color: "#0fd38c",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/order/${selectedValue}/${row?._id}`)}
            >
              {row?._id}
            </div>
          </Box>
        );
      },
      sortable: false,
      // center: true,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Store</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div style={{ fontSize: innerWidth ? "12px" : "" }} className="">
                {row.sellerId}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: (
        <Box paddingLeft={"10px"} className="text-wrap">
          Shipping Address
        </Box>
      ),
      marginLeft: "10px",

      cell: (row) => {
        const shipToAddress =
          row?.fulfillmentStartInstructions?.[0]?.shippingStep?.shipTo;
        return (
          <Box sx={{ padding: "10px", display: "flex", py: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="">
                {/* <img
                  width={"20px"}
                  src="https://apps.goshippo.com/c2e1a15939a23c5894eb4af1f20e3e73.svg"
                  alt=""
                /> */}
                <CountryFlag
                  countryCode={shipToAddress?.contactAddress?.countryCode}
                />
              </div>
            </div>
            <div style={{ marginLeft: 10 }} className="">
              <div style={{ fontSize: innerWidth ? "10px" : "" }}>
                {shipToAddress?.fullName}
              </div>
              <div className="">
                {shipToAddress?.contactAddress?.addressLine1},
                {shipToAddress?.contactAddress?.city},
                {shipToAddress?.contactAddress?.stateOrProvince},
                {shipToAddress?.contactAddress?.postalCode},
                {shipToAddress?.contactAddress?.countryCode}
              </div>
            </div>
          </Box>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Items</Box>,
      cell: (row) => {
        return (
          <>
            {row?.lineItems && (
              <Box sx={{ py: "5px" }}>
                <ul>
                  {row.lineItems.map((item, index) => (
                    <li
                      key={index}
                      style={{ fontSize: innerWidth ? "12px" : "" }}
                    >
                      {item.title ? item.title.toLowerCase() : ""}
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Total Price</Box>,
      cell: (row) => {
        const paymentStatus = row?.orderPaymentStatus?.toLowerCase();
        return (
          <>
            <Box sx={{ py: "5px" }}>
              <div style={{ fontSize: innerWidth ? "12px" : "" }} className="">
                {row.pricingSummary?.total?.value}{" "}
                {row.pricingSummary?.total?.currency}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        const readyChip = {
          backgroundColor: "#e0f2fe",
          color: "#026aa2",
        };
        const shipChip = {
          backgroundColor: "#0fd38c",
          color: "#fff",
        };
        let additionClass;
        if (row?.status === "Ready for Ship") {
          additionClass = readyChip;
        }
        return (
          <>
            <Box sx={{ py: "5px", fontSize: innerWidth ? "10px" : "" }}>
              {row?.trackingUpdated ? (
                <Chip style={shipChip} size="small" label={"Shipped"} />
              ) : (
                <Chip
                  style={additionClass}
                  size="small"
                  label={row?.status ? row?.status : "Unfulfilled"}
                />
              )}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Estimated Delivery</Box>,
      cell: (row) => {
        const deliveryDate =
          row?.fulfillmentStartInstructions?.[0]?.maxEstimatedDeliveryDate.replace(
            "T",
            " "
          );
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div style={{ fontSize: innerWidth ? "10px" : "" }} className="">
                {deliveryDate}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 6,
      width: "10%",
      cell: (row) => {
        const id = row?._id;
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexShrink: 0,
                py: "5px",
              }}
            >
              <Button
                label={"View order"}
                sx={{
                  padding: innerWidth ? "5px 8px" : "6px 16px",
                  width: innerWidth ? "80px" : "120px",
                  fontSize: innerWidth ? "10px" : "",
                }}
                variant="contained"
                onClick={() => navigate(`/order/${selectedValue}/${id}`)}
              >
                View order
              </Button>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];
  const amazonOrderDetails = [
    {
      id: 1,
      name: <Box className="text-wrap">Order Id</Box>,
      cell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: 250,
              alignItems: "center",
              py: "5px",
            }}
          >
            <img
              width={"20px"}
              src="https://apps.goshippo.com/73f6cc553b39f08a3dba516b8a7290ce.svg"
              alt=""
            />
            <div
              style={{
                fontSize: innerWidth ? "12px" : "",
                marginLeft: "5px",
                marginTop: "5px",
                color: "#0fd38c",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/order/${selectedValue}/${row?._id}`)}
            >
              {row?._id}
            </div>
          </Box>
        );
      },
      sortable: false,
      // center: true,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Shipping Address</Box>,
      cell: (row) => {
        const fullAddress = row?.fullAddress?.ShippingAddress;

        return (
          <div style={{ display: "flex", alignItems: "center", py: "5px" }}>
            <div>
              <CountryFlag countryCode={fullAddress?.CountryCode} />
            </div>
            <div style={{ marginLeft: 10 }} className="">
              <div
                style={{ fontSize: innerWidth ? "12px" : "", fontWeight: 600 }}
              >
                {fullAddress?.Name}
              </div>
              <div className="">
                {fullAddress?.AddressLine1},{fullAddress?.City},
                {fullAddress?.StateOrRegion},{fullAddress?.PostalCode},
                {fullAddress?.CountryCode}
              </div>
              {fullAddress?.Phone}
            </div>
          </div>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Unshipped Item</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div
                style={{
                  fontSize: innerWidth ? "12px" : "",
                  textAlign: "center",
                }}
                className=""
              >
                {row?.NumberOfItemsUnshipped}
              </div>
              {/* <div style={{ display: 'flex', gap: '5px' }} className="">
                <div>5.00 lb 2 x 2 x 2 in</div>
              </div> */}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Order Total</Box>,
      cell: (row) => {
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div style={{ fontSize: innerWidth ? "15px" : "" }} className="">
                {row?.OrderTotal?.Amount}{" "}
                <span>{row?.OrderTotal?.CurrencyCode}</span>
              </div>
              {/* <div style={{ display: 'flex', gap: '5px' }} className="">
                <div>5.00 lb $15.00</div>
              </div> */}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        const readyChip = {
          backgroundColor: "#e0f2fe",
          color: "#026aa2",
        };
        const shipChip = {
          backgroundColor: "#0fd38c",
          color: "#fff",
        };
        let additionClass;
        if (row?.status === "Ready for Ship") {
          additionClass = readyChip;
        }
        return (
          <>
            <Box sx={{ py: "5px" }}>
              {row?.trackingUpdated ? (
                <Chip style={shipChip} size="small" label={"Shipped"} />
              ) : (
                <Chip
                  style={additionClass}
                  size="small"
                  label={row?.status ? row?.status : "Unfulfilled"}
                />
              )}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Estimated Delivery</Box>,
      cell: (row) => {
        const deliveryDate = row?.EarliestDeliveryDate?.replace("T", " ");
        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div
                style={{
                  fontSize: innerWidth ? "12px" : "",
                  textAlign: "center",
                }}
                className=""
              >
                {deliveryDate}
              </div>
              {/* <div style={{ display: 'flex', gap: '5px' }} className="">
                <div>5.00 lb 2 x 2 x 2 in</div>
              </div> */}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 6,

      cell: (row) => {
        const id = row?._id;
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexShrink: 0,
                py: "5px",
              }}
            >
              <Button
                label={"View order"}
                sx={{
                  padding: innerWidth ? "5px 8px" : "6px 16px",
                  width: innerWidth ? "80px" : "120px",
                  fontSize: innerWidth ? "10px" : "",
                }}
                variant="contained"
                onClick={() => navigate(`/order/${selectedValue}/${id}`)}
              >
                View order
              </Button>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];
  const walmartOrderDetails = [
    {
      id: 1,
      name: <Box className="text-wrap">Order Id</Box>,
      cell: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              width: 250,
              alignItems: "center",
              py: "5px",
            }}
          >
            <img
              width={"50px"}
              src={"/assets/media/stores/walmart_us_logo.png"}
              alt=""
            />
            <div
              style={{
                fontSize: innerWidth ? "12px" : "",
                marginLeft: "5px",
                marginTop: "5px",
                color: "#0fd38c",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/order/${selectedValue}/${row?._id}`)}
            >
              {row?._id}
            </div>
          </Box>
        );
      },
      sortable: false,
      // center: true,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Shipping Address</Box>,
      cell: (row) => {
        return (
          <Box sx={{ padding: "10px 0px", display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                py: "5px",
              }}
            >
              <div style={{ marginRight: 10 }} className="">
                <CountryFlag countryCode={"US"} />
              </div>
            </div>

            <div className="">
              <div style={{ fontSize: innerWidth ? "12px" : "" }}>
                {row?.shippingInfo?.postalAddress?.name}
              </div>
              <div className="">
                {row?.shippingInfo?.postalAddress?.address1},
                {row?.shippingInfo?.postalAddress?.city},
                {row?.shippingInfo?.postalAddress?.state},
                {row?.shippingInfo?.postalAddress?.postalCode},
              </div>
              {row?.shippingInfo?.phone}
            </div>
          </Box>
        );
      },
      sortable: false,
    },
    {
      id: 3,
      name: <Box className="text-wrap">Item</Box>,
      cell: (row) => {
        return (
          <div>
            {row?.orderLines && (
              <ul>
                {row?.orderLines?.orderLine?.map((order, index) => (
                  <li
                    key={index}
                    style={{ fontSize: innerWidth ? "12px" : "" }}
                  >
                    {order?.item?.productName
                      ? order?.item?.productName?.toLowerCase()
                      : ""}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      },
      sortable: false,
    },
    {
      id: 4,
      name: <Box className="text-wrap">Status</Box>,
      cell: (row) => {
        const readyChip = {
          backgroundColor: "#e0f2fe",
          color: "#026aa2",
        };

        let additionClass;
        if (row?.status === "Ready for Ship") {
          additionClass = readyChip;
        }
        return (
          <>
            <Box sx={{ py: "5px" }}>
              {row?.trackingUpdated ? (
                <Chip style={readyChip} size="small" label={"Shipped"} />
              ) : (
                <Chip
                  style={additionClass}
                  size="small"
                  label={row?.status ? row?.status : "Unfulfilled"}
                />
              )}
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 2,
      name: <Box className="text-wrap">Estimated Delivery</Box>,
      cell: (row) => {
        const timestamp = row?.shippingInfo?.estimatedDeliveryDate;
        const date = timestamp && new Date(timestamp);
        const formattedDate = date && date?.toISOString()?.replace("T", " ");

        return (
          <>
            <Box sx={{ marginY: "10px", py: "5px" }}>
              <div style={{ fontSize: innerWidth ? "12px" : "" }} className="">
                {formattedDate}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 6,
      cell: (row) => {
        const id = row?._id;
        return (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexShrink: 0,
                width: 170,
                py: "5px",
              }}
            >
              <Button
                label={"View order"}
                sx={{
                  padding: innerWidth ? "5px 8px" : "6px 16px",
                  width: innerWidth ? "80px" : "120px",
                  fontSize: innerWidth ? "10px" : "",
                }}
                variant="contained"
                onClick={() => navigate(`/order/${selectedValue}/${id}`)}
              >
                View order
              </Button>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];
  const mappingEcommerce = {
    ebay: ebayOrderDetails,
    amazon: amazonOrderDetails,
    walmart: walmartOrderDetails,
  };
  const onHandleTimezone = (timezone) => {
    let startDate = "";
    let endDate = "";
    if (timezone === "customDateRange") {
    } else if (timezone === "today") {
      startDate = moment().format("YYYY-MM-DD HH:mm:ss");
      endDate = moment().format("YYYY-MM-DD HH:mm:ss");
    } else if (timezone === "thisWeek") {
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss");
      endDate = moment().format("YYYY-MM-DD HH:mm:ss");
    } else if (timezone === "thisMonth") {
      startDate = moment().subtract(32, "days").format("YYYY-MM-DD HH:mm:ss");
      endDate = moment().format("YYYY-MM-DD HH:mm:ss");
    } else if (timezone === "all") {
      startDate = moment().startOf("year").format("YYYY-MM-DD HH:mm:ss");
      endDate = moment().format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = "";
      endDate = "";
    }
    setStateDateMulti({
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: "selection",
    });

    setFilterOrderData({
      ...filterOrderData,
      startDate: moment(startDate ? new Date(startDate) : new Date())
        .set({ hour: 0, minute: 0, second: 0 })
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate ? new Date(endDate) : new Date())
        .set({ hour: 0, minute: 0, second: 0 })
        .format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  const handleTableSelectChange = (selectedOrderRows) => {
    let ids = []; // Initialize an array to store the orderIds
    console.log(selectedOrderRows.selectedRows, "selectedOrderRows");

    selectedOrderRows.selectedRows.forEach((row) => {
      // ids.push(row.orderId); // Push the orderId to the ids array ebay
      ids.push(row.purchaseOrderId); // Push the orderId to the ids array walmart
    });
    setSelectedRowIds(ids);
  };
  // table component
  const generateCustomTable = (selectedValue, columns) => (
    <CustomTable
      columns={columns}
      data={allOrders}
      selectedValue={selectedValue}
      handleChange={handleChange}
      totalRecords={totalPages}
      handlePagesizeChange={handlePagesizeChange}
      handleSearchOrder={handleSearchOrder}
      handlePageChange={handlePageChange}
      searchFinalValue={searchFinalValue}
      setSearch={setSearch}
      isLoading={dashboardLoading}
      selectOrderStausValue={selectOrderStausValue}
      handleStatusChange={handleStatusChange}
      dateRangeType={dateRangeType}
      setDateRangeType={setDateRangeType}
      filterOrderData={filterOrderData}
      setFilterOrderData={setFilterOrderData}
      stateDateMulti={stateDateMulti}
      setStateDateMulti={setStateDateMulti}
      onHandleTimezone={onHandleTimezone}
      handleTableSelectChange={handleTableSelectChange}
      selectableRows={true}
    />
  );
  const [anchorStore, setAnchorStore] = useState(null);

  const handleStoreSelectClick = (event) => {
    setAnchorStore(event.currentTarget);
  };
  const handleStoreSelectClose = () => {
    setAnchorStore(null);
  };
  const handleStoreMenuItemClick = (value) => {
    setSelectedValue(value);
    handleStoreSelectClose();
  };
  const innerWith = useMediaQuery("(max-width:600px)");
  return (
    <React.Fragment>
      <Card elevation={4} sx={{ mb: 3, borderRadius: "20px" }}>
        {selectedRowIds?.length > 0 ? (
          <Box
            sx={{
              pt: 2,
              pb: 2,
              pr: 2,
              backgroundColor: "#89edbd",
              height: "100%",
            }}
          >
            <Grid container>
              <Grid
                item
                md={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  sx={{ width: "120px" }}
                  label={"Delete"}
                  onClick={() => {
                    bulkDeleteOrderItemApiCall(
                      user?.id,
                      selectedValue,
                      selectedRowIds
                    );
                    setSelectedRowIds([]);
                  }}
                  variant="contained"
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            // spacing={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pl: 2,
              pr: 2,
              pb: 2,
              pt: 2,
            }}
          >
            <Grid item lg={2} mb={1} sx={{ width: "100%" }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  label="Search"
                  type={"text"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleSearchOrder()}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  defaultValue={searchFinalValue}
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                  onKeyDown={(event) =>
                    event?.key === "Enter" && handleSearchOrder()
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              item
              lg={10}
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              <SingleSelect
                title={selectedValue}
                options={OPTIONS_STORE}
                variant={"outlined"}
                anchor={anchorStore}
                handleMenuItemClick={handleStoreMenuItemClick}
                handleClick={handleStoreSelectClick}
                handleClose={handleStoreSelectClose}
              />
              <SingleSelect
                title={
                  selectOrderStausValue !== "None" && selectOrderStausValue
                    ? selectOrderStausValue
                    : "Select Status"
                }
                options={OPTIONS_STATUS}
                variant={"outlined"}
                anchor={anchorStatus}
                handleMenuItemClick={handleStatusMenuItemClick}
                handleClick={handleStatusSelectClick}
                handleClose={handleStatusSelectClose}
              />

              <Button
                sx={{ width: "120px" }}
                variant="contained"
                label={"Sync Order"}
                onClick={() => syncOrdersApiCall(user?.id, selectedValue)}
              >
                Sync Order
              </Button>
            </Grid>
          </Grid>
        )}
        <Box overflow="auto">
          {generateCustomTable(selectedValue, mappingEcommerce[selectedValue])}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default DashboardOrder;
