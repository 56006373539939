import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import {
  Box,
  Card,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  Typography,
  styled,
  useTheme,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const CustomTable = (props) => {
  const {
    customClass = "d-flex flex-column-reverse",
    columns,
    data,
    handleSearchOrder,
    pagination = true,
    highlightOnHover = true,
    rowClickable = false,
    handlePagesizeChange = () => {},
    handlePageChange = () => {},
    totalRecords,
    isLoading = false,
    selectableRows = false,
    handleTableSelectChange = () => {},
    fixedHeader = true,
    onRowClicked = () => {},
    noTableHead = false,
    searchFinalValue,
    children,
    setSearch,
    searchField = true,
  } = props;

  const customStyles = {
    responsiveWrapper: {
      style: {
        maxHeight: "none",
      },
    },
    rows: {
      style: {
        "&:hover": {
          cursor: rowClickable === true ? "pointer" : "",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    pagination: {
      style: {
        color: "#3778A6",
        borderTop: "0",
      },
      pageButtonsStyle: {
        color: "#3778A6",
        fill: "#3778A6",
        "&:hover:not(:disabled)": {
          fill: "#3778A6",
        },
      },
    },
  };

  const handlePerRowsChange = (pgSize) => {
    handlePagesizeChange(pgSize);
  };

  const handleTablePageChange = (pgNo) => {
    handlePageChange(pgNo);
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          paginationServer
          highlightOnHover={highlightOnHover}
          customStyles={customStyles}
          onChangePage={handleTablePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationTotalRows={totalRecords ? totalRecords : 0}
          paginationPerPage={20}
          paginationRowsPerPageOptions={[20, 50, 100, 150, 200]}
          progressPending={isLoading}
          progressComponent={
            <Box flex justifyContent={"center"} my={2} gap={1}>
              <CircularProgress />
            </Box>
          }
          striped
          selectableRows={selectableRows}
          onSelectedRowsChange={handleTableSelectChange}
          fixedHeader={fixedHeader}
          onRowClicked={onRowClicked}
          noTableHead={noTableHead}
        />
      </Box>
    </Fragment>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  customClass: PropTypes.string,
  columns: PropTypes.any,
  data: PropTypes.any,
  highlightOnHover: PropTypes.bool,
  pagination: PropTypes.any,
  rowClickable: PropTypes.bool,
  handlePagesizeChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  totalRecords: PropTypes.any,
  isLoading: PropTypes.bool,
  selectableRows: PropTypes.bool,
  handleTableSelectChange: PropTypes.func,
  fixedHeader: PropTypes.bool,
  onRowClicked: PropTypes.func,
};

const FilterSection = styled("div")(() => ({
  margin: "10px 10px 0px 10px",
}));
