import React from "react";
import TextField from "@mui/material/TextField";


const BasicTextField = ({ width, label, fieldName, handleForm ,value,fieldStyle,type="text",showPasswordIcon}) => {
  return (
    <TextField
      name={fieldName}
      size="small"
      onChange={(e) => {
        handleForm(e.target.value, e.target.name,e);
      }}
      type={type}
      style={{ width: width ? width : 170,marginTop:10, ...fieldStyle }}
      id="outlined-basic"
      variant="outlined"
      label={label}
      value={value}
      InputProps={showPasswordIcon}
    />
  );
};

export default BasicTextField;
