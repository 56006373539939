import { Checkbox } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ReturnContainer = styled.div`
  margin: 30px;
  width: 95%;
  height: 100%;
  max-height: 300px;
  // max-width: 400px; /* Adjust the max-width as needed */
  border: 1px solid lightblue;
  background-color: #ffffff;
`;

const ReturnInnerContainer = styled.div`
  margin: 15px;
`;

const SenderReturnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SenderTitle = styled.div`
  font-size: 14px;
  color: black;
`;

const ReturnTitle = styled.div`
  font-size: 14px;
  color: gray;
`;

const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Default = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Email = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const Phone = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -13px;
`;

const CheckBoxAddress = styled.div`
  font-size: 12px;
  margin-top: 12px;
  pointer-events: none;
`;

const ReturnCard = (props) => {
  const { title, address, email, phone } = props;
  return (
    <ReturnContainer>
      <ReturnInnerContainer>
        <SenderReturnContainer>
          <SenderTitle>{title}</SenderTitle>
          <ReturnTitle>Addresses...</ReturnTitle>
        </SenderReturnContainer>
        <DefaultContainer>
          <Default>Default:</Default>
          <span>{address}</span>
        </DefaultContainer>
        <Email>{email}</Email>
        <Phone>{phone}</Phone>
        <CheckBoxContainer>
          <Checkbox />
          <CheckBoxAddress>Save as Default Sender Address</CheckBoxAddress>
        </CheckBoxContainer>
      </ReturnInnerContainer>
    </ReturnContainer>
  );
};

export default ReturnCard;
