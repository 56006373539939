import { Icon, Menu, MenuItem } from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import logo from "../../assets/images/DeleveryDayLogo.png";
const TopbarRoot = styled("div")(() => ({
  top: 0,
  zIndex: 96,
  transition: "all 0.3s ease",
  height: 84,
}));

const TopbarContainer = styled(Box)(({ theme }) => ({
  padding: "8px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#e9f1f5",
  paddingLeft: 16,
  paddingRight: 16,
}));

const Button = styled("div")(({ theme, active }) => ({
  fontSize: "18px",
  fontWeight: "700",
  color: "#1f2d3a",
  padding: "10px",
  display: "flex",
  borderRadius: "5px",
  alignItems: "center",
  margin: "auto",
  marginLeft: "10px",
  height: "50px",
  cursor: "pointer",
  // boxShadow: active ? theme.shadows[5] : 'none',
  background: active ? "white" : "none",
  "&:hover": {
    // boxShadow: theme.shadows[8],
    background: "white",
  },
}));

const BitsLogo = styled("div")(() => ({
  cursor: "pointer",
}));
const Image = styled("img")(() => ({
  width: "100px",
}));

const Navbar = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButtonClick = (buttonName) => {
    if (activeButton === buttonName) {
      setActiveButton(null); // Deactivate the button if it's already active
    } else {
      setActiveButton(buttonName); // Activate the clicked button
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.localStorage.clear("accessToken");
    navigate("/");
  };
  return (
    <TopbarRoot>
      <TopbarContainer>
        <Box display="flex">
          <BitsLogo
            onClick={() =>
              handleButtonClick("Orders") || navigate("/dashboard")
            }
          >
            <Image src={logo} />
          </BitsLogo>

          <Button
            onClick={() =>
              handleButtonClick("Orders") || navigate("/dashboard")
            }
            active={activeButton === "Orders"}
          >
            Orders
          </Button>
          <Button
            onClick={() => handleButtonClick("Shipments")}
            active={activeButton === "Shipments"}
          >
            Shipments
          </Button>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            onClick={() =>
              handleButtonClick("Setting") || navigate("/setting/connect")
            }
            active={activeButton === "Setting"}
          >
            <SettingsOutlinedIcon />
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            active={activeButton === "Account"}
          >
            <AccountCircleOutlinedIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </Box>
      </TopbarContainer>
    </TopbarRoot>
  );
};

export default Navbar;
