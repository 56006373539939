// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./app/contexts/AuthContext";

import { Toaster } from "react-hot-toast";

import { DashboardContextProvider } from "./app/contexts/DashboardContext/DashboardContext";
import { SettingsProvider } from "./app/contexts/SettingsContext";
import { MenuSideBarContextProvider } from "./app/contexts/MenuSideBarContext.js";
import "./App.css";
import { ToastContainer } from "react-toastify";
import AppRouting from "./AppRouting.js";
import { ThemeProvider, createTheme } from "@mui/material";

const App = () => {
  const appTheme = createTheme({
    palette: {
      primary: {
        main: "#89edbd",
        contrastText: "#ffffff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: 600,
          },
        },
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <AuthProvider>
            <DashboardContextProvider>
              <SettingsProvider>
                <MenuSideBarContextProvider>
                  <ToastContainer />
                  <Toaster />
                  <AppRouting />
                </MenuSideBarContextProvider>
              </SettingsProvider>
            </DashboardContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default App;
