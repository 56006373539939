import { Checkbox } from "@mui/material";
import React from "react";
import styled from "styled-components";

const AddressContainer = styled.div`
  margin: 30px;
  width: 95%;
  max-height: 300px;
  // max-width: 400px; /* Adjust the max-width as needed */
  height: 100%;
  border: 1px solid lightblue;
  background-color: #ffffff;
`;

const AddressInnerContainer = styled.div`
  margin: 15px;
`;

const SenderAddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SenderTitle = styled.div`
  font-size: 14px;
  color: black;
`;

const AddressTitle = styled.div`
  font-size: 14px;
  color: gray;
`;

const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Default = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Email = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const Phone = styled.div`
  font-size: 14px;
  margin-top: 5px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -13px;
  cursor: pointer; /* Change cursor to pointer for better UX */
`;

const CheckBoxAddress = styled.div`
  font-size: 12px;
  margin-top: 12px;
  pointer-events: none;
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddressCard = (props) => {
  const { title, address, email, phone, handleOpen } = props;
  return (
    <AddressContainer>
      <AddressInnerContainer>
        <SenderAddressContainer>
          <SenderTitle>{title}</SenderTitle>
          <AddressTitle>Addresses...</AddressTitle>
        </SenderAddressContainer>
        <DefaultContainer>
          <Default>Default:</Default>
          <span>{address}</span>
        </DefaultContainer>
        <Email>{email}</Email>
        <Phone>{phone}</Phone>
        <CheckBoxContainer style={{ pointerEvents: "none" }}>
          <Checkbox />
          <CheckBoxAddress>Save as Default Sender Address</CheckBoxAddress>
        </CheckBoxContainer>
        <CheckBoxContainer onClick={handleOpen}>
          <Checkbox {...label} defaultChecked />
          <CheckBoxAddress>Use as Return Address</CheckBoxAddress>
        </CheckBoxContainer>
      </AddressInnerContainer>
    </AddressContainer>
  );
};

export default AddressCard;
