import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';


const PopUpContainer = styled('div')(({ theme }) => ({
  width: '500px',
  padding: '15px',
}));

const PopUpForm = styled('div')(() => ({
  marginTop: 10,
}));

const CheckBoxRecipient = styled('div')(() => ({
  fontSize: 12,
  marginTop: 12,
}));

const CheckBoxContainer = styled('div')(() => ({
  display: 'flex',
  marginLeft: -13,
}));

const usStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

const getUSStates = () => usStates;

const PopUp = ({ onClose, headingText, address,EbayShipTo ,WalmartShipTo,AmazonShipTo, senderAddress,setSenderAddress}) => {
  // console.log(EbayShipTo ,WalmartShipTo,AmazonShipTo, senderAddress);
  const [countryList, setCountryList] = useState([]);
  const [formValues, setFormValues] = useState({
    name:EbayShipTo?.fullName ||WalmartShipTo?.name ||AmazonShipTo?.Name||senderAddress?.name,
    company: EbayShipTo?.fullName ||WalmartShipTo?.name ||AmazonShipTo?.Name||senderAddress?.name,
    email:EbayShipTo?.email||WalmartShipTo?.customerEmailId||AmazonShipTo?.BuyerInfo?.BuyerEmail ,
    phone: AmazonShipTo?.Phone || WalmartShipTo?.phone ||EbayShipTo?.primaryPhone?.phoneNumber ||senderAddress?.phone,
    country: EbayShipTo?.contactAddress?.countryCode||WalmartShipTo?.country||AmazonShipTo?.CountryCode|| 'US',
    street: AmazonShipTo?.AddressLine1|| WalmartShipTo?.address1 ||EbayShipTo?.contactAddress?.addressLine1||senderAddress?.street,
    streetLine2: '',
    city: EbayShipTo?.contactAddress?.city || WalmartShipTo?.city ||AmazonShipTo?.City||senderAddress?.city,
    state:AmazonShipTo?.StateOrRegion||WalmartShipTo?.state|| EbayShipTo?.contactAddress?.stateOrProvince||senderAddress?.state,
    zip_code: AmazonShipTo?.PostalCode ||WalmartShipTo?.postalCode ||EbayShipTo?.contactAddress?.postalCode||senderAddress?.zip_code,
  });

  // Name: EbayShipTo?.fullName ||WalmartShipTo?.name ||AmazonShipTo?.Name ,
  // street1:AmazonShipTo?.AddressLine1|| WalmartShipTo?.address1 ||EbayShipTo?.contactAddress?.addressLine1,
  // city: EbayShipTo?.contactAddress?.city || WalmartShipTo?.city ||AmazonShipTo?.City,
  // state: AmazonShipTo?.StateOrRegion||WalmartShipTo?.state|| EbayShipTo?.contactAddress?.stateOrProvince ,
  // zip: EbayShipTo?.contactAddress?.postalCode ||WalmartShipTo?.postalCode  ||AmazonShipTo?.PostalCode,
  // country: "US",
  // phone: AmazonShipTo?.Phone || walmartOrderItem?.shippingInfo?.phone ||EbayShipTo?.primaryPhone?.phoneNumber,
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    country: false,
    street: false,
    city: false,
    state: false,
    zip_code: false,
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getCountryList();
  //       setCountryList(response.data.countryList);
  //     } catch (error) {
  //       console.error('Error fetching country list', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleApply = () => {
    const newErrors = {};
    let hasError = false;

    for (const key in formValues) {
      if (
        formValues[key] === '' &&
        key !== 'addressType' &&
        key !== 'company' &&
        key !== 'streetLine2' &&
        key !== 'state'
      ) {
        newErrors[key] = true;
        hasError = true;
      } else {
        newErrors[key] = false;
      }
    }

    if (hasError) {
      setErrors({ ...newErrors });
    } else {
      console.log(formValues, 'Form Values:');
      setSenderAddress(formValues)
      onClose();
    }
    console.log(formValues, 'Form Values:');
  };

  const handleChange = (event) => {
    const { name, value } = event.target || event;

    // Update form values
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === 'country') {
      // Check if the selected country is the United States and if the state is empty
      const isUnitedStates = value === 'United States';
      const stateError = isUnitedStates && !formValues.state;

      // Update errors state accordingly
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: stateError,
        country: value === '', // Update country error based on value
      }));
    } else if (name === 'state') {
      // Update state error based on whether it's empty and the selected country
      const isUnitedStates = formValues.country === 'United States';
      const stateError = value === '' && isUnitedStates;

      // Update errors state accordingly
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === '',
        state: stateError,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value === '',
      }));
    }
  };

  const handleCountryChange = (event, value) => {
    // Update formValues and reset state if a new country is selected
    setFormValues((prevValues) => ({
      ...prevValues,
      country: value || '', // Set an empty string if the value is null or undefined
      state: '', // Reset state when a new country is selected
    }));

    // Reset state validation error when a non-US country is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      state: value !== 'United States' ? false : prevErrors.state,
    }));
  };
  const isUnitedStates = formValues.country === 'United States';

  const handleStateChange = (event, value) => {
    // Ensure to check if the value is null or undefined before updating state
    const updatedState = value || ''; // Set an empty string if the value is null or undefined

    setFormValues((prevValues) => ({
      ...prevValues,
      state: updatedState,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      state: updatedState === '' && isUnitedStates,
    }));
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <PopUpContainer>
        <div style={{ fontSize: '20px', fontWeight: '600' }}>{headingText}</div>
        <PopUpForm>
          <div>
            <div>Name*</div>
            <TextField
              defaultValue={formValues?.name}
              size="small"
              fullWidth
              name="name"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.name}
              helperText={errors.name ? 'name is a required field.' : ''}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <div>Company</div>
            <TextField
              defaultValue={formValues?.name}
              size="small"
              fullWidth
              name="company"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: '10px', width: '100%', marginTop: '5px' }}>
            <div>
              <div>Email</div>
              <TextField
              defaultValue={formValues.email}
                size="small"
                style={{ width: '230px' }}
                name="email"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                // error={errors.email}
                // helperText={errors.email ? 'Email is a required field.' : ''}
              />
            </div>
            <div>
              <div>Phone</div>
              <TextField
                defaultValue={formValues.phone}
                size="small"
                style={{ width: '230px' }}
                name="phone"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.phone}
                helperText={errors.phone ? 'Phone is a required field.' : ''}
              />
            </div>
          </div>
        </PopUpForm>
        {headingText === 'Edit Recipient' && (
          <div style={{ marginTop: '10px', fontWeight: 'bold' }}>Ship To</div>
        )}
        <PopUpForm style={{ marginTop: '5px' }}>
          <div>
            <div>Country *</div>
            <Autocomplete
              defaultChecked={formValues.country}
              fullWidth
              size="small"
              disablePortal
              options={countryList.map((country) => country.name)}
              value={formValues.country}
              onChange={handleCountryChange}
              renderInput={(params) => <TextField {...params} />}
              error={errors.country}
              helperText={errors.country ? `Country can't be blank` : ''}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <div>Street *</div>
            <TextField
              defaultValue={formValues.street}
              size="small"
              fullWidth
              name="street"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.street}
              helperText={errors.phone ? 'Street1 is a required field.' : ''}
            />
          </div>
          <div style={{ display: 'flex', gap: '10px', width: '100%', marginTop: '5px' }}>
            <div>
              <div>Street (line2)</div>
              <TextField
                size="small"
                style={{ width: '230px' }}
                name="streetLine2"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <div>City *</div>
              <TextField
                defaultValue={formValues.city}
                size="small"
                error={errors.city}
                helperText={errors.city ? 'Street1 is a required field.' : ''}
                style={{ width: '230px' }}
                name="city"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px', width: '100%', marginTop: '5px' }}>
            <div>
              <div>
                <div>{errors.country && !formValues.state ? 'State*' : 'State'}</div>

                {formValues.country === 'United States' ? (
                  <Autocomplete
                    style={{ width: '230px' }}
                    size="small"
                    disablePortal
                    options={getUSStates()}
                    value={formValues.state}
                    onChange={handleStateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.state}
                        helperText={errors.state ? 'State is required' : ''}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    size="small"
                    fullWidth
                    style={{ width: '230px' }}
                    name="state"
                    value={formValues.state || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              <div>Postal code / zip *</div>
              <TextField
                defaultValue={formValues.zip_code}
                size="small"
                style={{ width: '230px' }}
                name="zip_code"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.zip_code}
                helperText={errors.zip_code ? 'Zip is a required field.' : ''}
              />
            </div>
          </div>
          {headingText === 'Edit Recipient' && <div style={{ marginTop: '5px' }}>Address Type</div>}
          {headingText === 'Edit Sender Address' || headingText === 'Edit Return Address' ? (
            <CheckBoxContainer style={{ marginBottom: '-10px' }}>
              <Checkbox
                {...label}
                defaultChecked={formValues.addressType}
                name="Save Address"
                onChange={handleChange}
              />
              <CheckBoxRecipient>Save to Addresses</CheckBoxRecipient>
            </CheckBoxContainer>
          ) : (
            <></>
          )}
          {headingText === 'Edit Sender Address' || headingText === 'Edit Recipient' ? (
            <CheckBoxContainer>
              <Checkbox
                {...label}
                defaultChecked={formValues.addressType}
                name="Return Address"
                onChange={handleChange}
              />
              <CheckBoxRecipient>Use as Return Address</CheckBoxRecipient>
            </CheckBoxContainer>
          ) : (
            <></>
          )}
        </PopUpForm>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            onClick={onClose}
            sx={{ color: 'black', border: '0.5px solid black', padding: 'auto 15px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            sx={{
              color: 'white',
              backgroundColor: '#10C66F',
              '&:hover': {
                backgroundColor: '#10C66F',
              },
            }}
          >
            Apply
          </Button>
        </div>
      </PopUpContainer>
    </>
  );
};

export default PopUp;
