import {
  styled,
  Icon,
  Button,
  Card,
  IconButton,
  Chip,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import CustomButton from "../../components/CustomButton";
const OrderIdContainer = styled("div")(() => ({
  display: "flex",
  fontWeight: "600",
  fontSize: "16px",
  fontFamily:
    " Roboto,pragmatica-web,Helvetica Neue,Helvetica,Arial,sans-serif",
}));
const OrderDetailContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const BuyButtonContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
const OrderName = styled("div")(() => ({
  fontFamily: "Roboto,pragmatica-web,Helvetica Neue,Helvetica,Arial,sans-serif",
  fontSize: ".875rem",
  lineHeight: "1.25rem",
  color: "rgb(184, 206, 217)",
}));
const readyChip = {
  backgroundColor: "#e0f2fe",
  color: "#026aa2",
};
const OrderDetailNav = ({
  orderId,
  isBuyButtonDisabled,
  amzOrderStatus,
  handleCreateLabel,
  currentStatusValue,
  orderType,
  userId,
  trackingUpdated,
  resetOrderDetails,
  userRole,
}) => {
  const innerWidth = useMediaQuery("(max-width:760px)");

  const { postUpdateOrderStatusApiCall } = useDashboardContext();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard"); // Replace '/dashboard' with your actual dashboard route
    resetOrderDetails();
  };
  const handleUpdateStatus = () => {
    const data = {
      userId,
      orderId,
      orderType,
    };
    postUpdateOrderStatusApiCall(data, orderType);
  };
  return (
    <Card
      elevation={4}
      sx={{
        ml: 2,
        mr: 2,
        mt: 2,
        display: "flex",
        flexDirection: innerWidth ? "column" : "row",
        gap: innerWidth ? "10px" : "",
        p: 2,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <OrderDetailContainer>
          <OrderIdContainer
            sx={{
              fontSize: innerWidth ? "12px" : "16px",
            }}
          >
            #{orderId}
          </OrderIdContainer>
          {/* <OrderName>Nashit Farooq</OrderName> */}
        </OrderDetailContainer>
        {trackingUpdated ? (
          <Box sx={{ marginY: "10px" }}>
            <Chip
              style={{ ...readyChip, marginLeft: 10 }}
              size="small"
              label={"Shipped"}
            />
          </Box>
        ) : (
          <>
            {currentStatusValue ? (
              <Box sx={{ marginY: "10px" }}>
                <Chip
                  style={{ ...readyChip, marginLeft: 10 }}
                  size="small"
                  label={currentStatusValue}
                />
              </Box>
            ) : (
              <Box sx={{ marginY: "10px" }}>
                <Chip
                  style={{ marginLeft: 10 }}
                  size="small"
                  label={"Unshipped"}
                />
              </Box>
            )}
          </>
        )}

        <Chip
          size="small"
          label="Paid"
          sx={{ color: "green", backgroundColor: "#f2ffeb", ml: 2 }}
        />
      </div>
      <BuyButtonContainer
        sx={{ justifyContent: innerWidth ? "space-between" : "center" }}
      >
        {currentStatusValue !== "Ready for Ship" && (
          <Button
            disabled={trackingUpdated}
            width={150}
            sx={{
              padding: innerWidth ? "8px 9px" : "6px 16px",
              width: innerWidth ? "120px" : "150px",
              fontSize: innerWidth ? "12px" : "",
            }}
            variant="contained"
            onClick={handleUpdateStatus}
            label={"Mark as Shipped"}
          >
            Mark as Shipped
          </Button>
        )}
        <div style={{ marginLeft: 10 }}>
          <Button
            variant="contained"
            disabled={isBuyButtonDisabled}
            sx={{ width: innerWidth ? "100px" : "150px" }}
            onClick={() => {
              handleCreateLabel();
            }}
            label={"Buy"}
          >
            Buy
          </Button>
        </div>
      </BuyButtonContainer>
    </Card>
  );
};

export default OrderDetailNav;
