import { styled } from "@mui/material";
import React from "react";

const CustomButton = ({
  label,
  onClick,
  width = 120,
  disabled = false,
  type = "button",
  height,
}) => {
  const Button = styled("button")(({ green }) => ({
    fontSize: "16px",
    border: disabled ? "1px solid lightgray" : "none",
    fontWeight: "700",
    cursor: disabled ? "not-allowed" : "pointer",
    color: green ? "white" : "#a6a6a6",
    borderRadius: "5px",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center",
    width: width,
    height: height,
    boxShadow: green ? "rgba(0, 0, 0, 0.16) 0px 1px 4px   " : "",
    background: green ? "#89edbd" : "#e0e0e0",
    transition: "background-color 0.3s, transform 0.3s",
    "&:hover": {
      boxShadow:
        green &&
        "rgba(0, 0, 0, 0.27) 0px 0px .25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em  ",
      background:disabled ? "#e0e0e0" : "#5fa584",
    },
  }));
  return (
    <Button
      disabled={disabled}
      green={!disabled}
      width={width}
      height={height}
      onClick={onClick}
      type={type}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
