import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  // Chip,
  Dialog,
  Grid,
  // Icon,
  styled,
  useTheme,
} from "@mui/material";
import ShipAddressFrom from "./shared/ShipAddressFrom";
import PackageDetailForm from "./shared/PackageDetailForm";
// import { getAllCountriesList } from 'app/helpers/BackendHelper';
import { useFieldArray, useForm } from "react-hook-form";
import ShipAddressTo from "./shared/ShipAddressTo";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// import { useShipmentContext } from "../../../contexts/ShipmentContext/ShipmentContext";
// import { MatxLoading } from "app/components";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDashboardContext } from "../../../contexts/DashboardContext/DashboardContext";
// import moment from "moment";
import MenuSidebar from "../../../components/MenuSideBar";
import { useAuth } from "../../../contexts/AuthContext";
import Loading from "../../../components/MatxLoading";
import { CARRIER_LOGOS } from "../../../utils/constant";

const country = [
  { name: "Pakistan", code: "pk" },
  { name: "India", code: "IN" },
  { name: "China", code: "CH" },
  { name: "Imaginaria", code: "IG" },
  { name: "Fantasia", code: "FA" },
];

const AllShippingRates = ({
  openPopUpRates,
  shippingRates,
  activeCard,
  handleRate,
  handleAddShippingMethod,
  setOpenPopUpRates,
  shippingRatesLoading,
  user,
}) => {
  return (
    <div>
      <Dialog open={openPopUpRates}>
        <Card>
          <Box sx={{ ml: 2 }}>
            <h4>Quick Rates</h4>
          </Box>
          <Box sx={{ p: 4, pb: 2, minWidth: "200px" }}>
            <Grid container spacing={2}>
              {shippingRatesLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  {shippingRates &&
                    shippingRates.map((item, i) => {
                      const carrierLogo = CARRIER_LOGOS[item?.carrierCode];
                      return (
                        <div
                          onClick={() => handleRate(item)}
                          key={item?.service}
                          style={{
                            width: "100%",
                            border:
                              activeCard === item?.service
                                ? "1px solid rgb(48, 136, 30)"
                                : "1px solid #e9f1f5",
                            backgroundColor:
                              activeCard === item?.service
                                ? "rgb(242, 255, 235)"
                                : "#ffffff",
                            borderRadius: "5px",
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              marginLeft: 10,
                              marginRight: 10,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "60%",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                {carrierLogo && (
                                  <div>
                                    <img
                                      width="30px"
                                      src={`/assets/media/carrier/${carrierLogo}`}
                                      alt="Carrier Logo"
                                    />
                                  </div>
                                )}
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                <div>{item?.service}</div>
                                <div>{item?.serviceType}</div>
                              </div>
                            </div>

                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  ${item?.shippingCost}
                                </div>
                                <div
                                  style={{
                                    textDecoration: "none",
                                    marginLeft: "5px",
                                    fontSize: "12px",
                                    borderRadius: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "2px 4px",
                                    color: "white",
                                    background: "#fcae1e",
                                  }}
                                >
                                  {user?.ratePercentage}% SAVE
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: "20px",
                                }}
                              >
                                ${item?.discountShippingCost}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {item?.transitTime}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </Grid>
          </Box>{" "}
          <div>
            <hr color="#ebebeb" />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                disabled={shippingRatesLoading}
                sx={{
                  backgroundColor: "lightgray",
                  ":hover": {
                    backgroundColor: "lightgray",
                  },
                  mr: 2,
                }}
                onClick={() => setOpenPopUpRates(false)}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleAddShippingMethod}
                disabled={!activeCard || shippingRatesLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </Card>
      </Dialog>
    </div>
  );
};

const QuickShip = () => {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  // const [countryList, setCountryList] = useState([]);
  const [disablePackages, setDisablePackages] = useState(false);
  // const [quickRates, setQuickRates] = useState([]);
  const [shipFoarmPayload, setShipFormPayload] = useState("");
  const [shipToPayload, setShipToPayload] = useState("");
  const [label, setlabel] = useState("");
  const [percelsPayload, setPercelsPayload] = useState("");
  // const [parcelsString, setParcelsString] = useState("");
  const { user } = useAuth();
  // const [payload, setPayload] = useState();
  const {
    postQuickShipRatesApiCall,
    shippingRates,
    postQuickShipShipmentApiCall,
    shippingRatesLoading,
    openPopUpRates,
    setOpenPopUpRates,
  } = useDashboardContext();

  const [activeCard, setActiveCard] = useState(null);

  const quickShipScheme = Yup.object().shape({
    // companyFrom: Yup.string().nullable().required("Field is required!"),
    // companyTo: Yup.string().nullable().required("Field is required!"),
    nameFrom: Yup.string().nullable().required("Field is required!"),
    nameTo: Yup.string().nullable().required("Field is required!"),
    address1From: Yup.string() // Validate originAddressFrom as a string
      // .transform((value) => value?.address || "") // Extract the address string
      .nullable()
      .required("Field is required!"),
    address1To: Yup.string() // Validate originAddressFrom as a string
      // .transform((value) => value?.address || "") // Extract the address string
      .nullable()
      .required("Field is required!"),
    // countryFrom: Yup.string().nullable().required("Field is required!"),
    // countryTo: Yup.string().nullable().required("Field is required!"),
    zipCodeFrom: Yup.string().nullable().required("Field is required!"),
    zipCodeTo: Yup.string().nullable().required("Field is required!"),
    stateFrom: Yup.string().nullable().required("Field is required!"),
    stateTo: Yup.string().nullable().required("Field is required!"),
    cityFrom: Yup.string().nullable().required("Field is required!"),
    cityTo: Yup.string().nullable().required("Field is required!"),
    phoneFrom: Yup.string()
      .nullable()
      .required("Field is required!")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Please enter a valid US phone number"
      ),
    phoneTo: Yup.string()
      .nullable()
      .required("Field is required!")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Please enter a valid US phone number"
      ),
    // packageType: Yup.string().nullable().required("Field is required!"),
    packageField: Yup.array().of(
      Yup.object().shape({
        length: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required("Field is required!")
          .positive("Length should be greater than 0")
          .max(499, "Maximum 499"),
        width: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required("Field is required!")
          .positive("Width should be greater than 0")
          .max(499, "Maximum 499"),
        height: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required("Field is required!")
          .positive("Height should be greater than 0")
          .max(499, "Maximum 499"),
        weight: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required("Field is required!")
          .positive("Weight should be greater than 0")
          .max(499, "Maximum 499"),
        qty: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required("Field is required!")
          .positive("Quantity should be greater than 0")
          .max(499, "Maximum 499"),
      })
    ),
  });
  const handleRate = (item) => {
    setActiveCard(item?.service === activeCard ? null : item?.service);

    let updatedPayload = label;
    updatedPayload = {
      serviceName: item?.serviceType,
      amount: item?.discountShippingCost,
      providerName: item?.carrierCode,
    };
    setlabel(updatedPayload);
  };
  const defaultValue = {
    companyFrom: "",
    companyTo: "",
    nameFrom: "",
    nameTo: "",
    address1From: "",
    address1To: "",
    address2From: "",
    address2To: "",
    countryFrom: "",
    countryTo: "",
    zipCodeFrom: "",
    zipCodeTo: "",
    stateFrom: "",
    stateTo: "",
    cityFrom: "",
    cityTo: "",
    phoneFrom: "",
    phoneTo: "",
    emailFrom: "",
    emailTo: "",
    packageField: defaultPackageValues,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    // reset,
    setValue,
    getValues,
    watch,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(quickShipScheme),
    defaultValues: defaultValue,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "packageField",
  });

  const shipFormData = (data, event) => {
    event.preventDefault();
    let payload;
    console.log(data);
    const shipFrom = {
      company: data?.companyFrom,
      name: data?.nameFrom,
      addressLine1: data?.address1From,
      addressLine2: data?.address2From,
      email: data?.emailFrom,
      postalCode: data?.zipCodeFrom,
      stateOrRegion: data?.stateFrom,
      city: data?.cityFrom,
      phoneNumber: data?.phoneFrom,
      countryCode: data?.countryFrom,
      // stateCode: data?.stateCodeFrom,
    };
    const shipTo = {
      company: data?.companyTo,
      name: data?.nameTo,
      addressLine1: data?.address1To,
      addressLine2: data?.address2To,
      email: data?.emailTo,
      postalCode: data?.zipCodeTo,
      stateOrRegion: data?.stateTo,
      city: data?.cityTo,
      phoneNumber: data?.phoneTo,
      countryCode: data?.countryTo,
      // stateCode: data?.stateCodeTo,
    };
    const additionalRequest = {
      is_residential: data?.isResidential ? data?.isResidential : false,
      reference_code: data?.referenceCode,
      signature_required: data?.signatureRequired
        ? data?.signatureRequired
        : false,
      insurance: {
        type: 1,
        value: data?.insuranceValue ? data?.insuranceValue : 0,
      },
    };
    let quickRates = data?.packageField;

    const shipmentPackages = quickRates?.map((pack) => {
      return {
        ...pack,
        unit: data?.unit,
      };
    });
    // const quickShipRate = {
    //   // serviceDetails: user.serviceDetails,
    //   // ratePercentage: user.ratePercentage,
    //   userId: user.id,
    //   packageDetails: [
    //     {
    //       length: `${quickRates[0]?.length}`,
    //       width: `${quickRates[0]?.width}`,
    //       height: `${quickRates[0]?.height}`,
    //       weight: `${quickRates[0]?.weight}`,
    //     },
    //   ],
    // };
    const parcelData = [
      {
        length: `${quickRates[0]?.length}`,
        width: `${quickRates[0]?.width}`,
        height: `${quickRates[0]?.height}`,
        weight: `${quickRates[0]?.weight}`,
      },
    ];
    setShipFormPayload(shipFrom);
    setShipToPayload(shipTo);
    setPercelsPayload(parcelData);
    payload = {
      shipFrom,
      shipTo,
      currency: data?.currency,
      // // packageType: data?.packageType,
      additionalRequest,
      shipmentPackages,
      userId: user.id,
    };

    console.log(payload, "payload");

    postQuickShipRatesApiCall(payload);
  };

  // const handleCreateShipping = (
  //   serviceId,
  //   shippingCarrierId,
  //   dateValue,
  //   pickupTimeValue,
  //   closingTimeValue,
  //   pickupLocationValue,
  //   notesValue
  // ) => {
  //   const data = getValues();
  //   const shipFrom = {
  //     company: data?.companyFrom,
  //     name: data?.nameFrom,
  //     address1: data?.address1From,
  //     address2: data?.address2From,

  //     zip: data?.zipCodeFrom,
  //     state: data?.stateFrom,
  //     city: data?.cityFrom,
  //     phone: data?.phoneFrom,
  //     countryCode: "US",
  //     stateCode: data?.stateCodeFrom,
  //     email: data?.emailFrom,
  //   };
  //   const shipTo = {
  //     company: data?.companyTo,
  //     name: data?.nameTo,
  //     address1: data?.address1To,
  //     address2: data?.address2To,

  //     zip: data?.zipCodeTo,
  //     state: data?.stateTo,
  //     city: data?.cityTo,
  //     phone: data?.phoneTo,
  //     countryCode: "US",
  //     stateCode: data?.stateCodeTo,
  //     email: data?.email,
  //   };
  //   const additionalRequest = {
  //     is_residential: data?.isResidential ? data?.isResidential : false,
  //     reference_code: data?.referenceCode,
  //     signature_required: data?.signatureRequired
  //       ? data?.signatureRequired
  //       : false,
  //     insurance: {
  //       type: 1,
  //       value: data?.insuranceValue ? data?.insuranceValue : 0,
  //     },
  //   };
  //   const shipmentPackages = data?.packageField?.map((pack) => {
  //     return {
  //       ...pack,
  //       unit: data?.unit,
  //     };
  //   });
  //   let payload = {
  //     shipFrom,
  //     shipTo,
  //     currency: data?.currency,
  //     // packageType: data?.packageType,
  //     additionalRequest,
  //     shipmentPackages,
  //   };

  //   let pickupRequest = {
  //     contactName: data.nameFrom,
  //     phoneNumber: data.phoneFrom,
  //     pickupDate:
  //       dateValue === "" ? "" : moment(dateValue).format("YYYY-MM-DD"),
  //     pickupTime: pickupTimeValue,
  //     closingTime: closingTimeValue,
  //     location: pickupLocationValue,
  //     instructions: notesValue,
  //   };
  //   const shippingPayload = {
  //     shippingRateRequest: { ...payload },
  //     carrierDetails: { shippingCarrierId, serviceId },
  //     ...(dateValue && {
  //       pickupRequest,
  //     }),
  //   };
  //   // reset(defaultValue);
  //   // postQuickShipAPICall(shippingPayload);
  // };

  // useEffect(() => {
  //   setQuoteList([]);
  //   getAllCountriesList().then((res) => {
  //     setCountryList(res.data.countryList);
  //   });

  //   setValue('currency', 'CAD');
  //   setValue('unit', 'IMPERIAL');
  //   setValue('packageType', 3);
  // }, []);

  // if (quotesLoading) {
  //   return <MatxLoading />;
  // }
  const handleAddShippingMethod = (data) => {
    const userId = user?.id;
    let payload;
    payload = {
      shipFrom: shipFoarmPayload,
      shipTo: shipToPayload,
      parcels: percelsPayload,
      label: label,
      userId: userId,
    };

    console.log(payload, "pppp");
    postQuickShipShipmentApiCall(payload);
  };

  return (
    <>
      <MenuSidebar>
        <Box sx={{ ml: 3, mt: 2 }} display={"flex"} alignItems={"center"}>
          <QuickShipTitle>Quick Ship</QuickShipTitle>
        </Box>
        {/* {quoteList !== undefined && quoteList?.length > 0 ? (
          <QuickRateCard handleCreateShipping={handleCreateShipping} />
        ) : ( */}
        <form onSubmit={handleSubmit(shipFormData)}>
          <Box sx={{ pb: 2, pt: 1 }}>
            <Grid
              sx={{
                display: "flex",
                pt: 3,
                pr: 3,
                pl: 3,
              }}
              container
            >
              <Grid item md={5.8} xs={12} sx={{ display: "flex" }}>
                <ShipAddressFrom
                  countryList={country}
                  {...{
                    control,
                    errors,
                    setValue,
                    getValues,
                    watch,
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                md={0.4}
                mt={2}
                mb={2}
                xs={12}
              >
                <ShoppingCartIcon style={{ color: primary }} />
              </Grid>
              <Grid item md={5.8} xs={12} sx={{ display: "flex" }}>
                <ShipAddressTo
                  countryList={country}
                  {...{
                    control,
                    errors,
                    setValue,
                    getValues,
                    watch,
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ pt: 3, pr: 3, pl: 3 }} container>
              <Grid item md={12}>
                <PackageDetailForm
                  packageControl={control}
                  packageErrors={errors}
                  packageSetValue={setValue}
                  {...{
                    control,
                    fields,
                    append,
                    remove,
                    getValues,
                    disablePackages,
                    setDisablePackages,
                  }}
                />
              </Grid>
            </Grid>
            <AllShippingRates
              openPopUpRates={openPopUpRates}
              shippingRates={shippingRates}
              activeCard={activeCard}
              handleRate={handleRate}
              handleAddShippingMethod={handleAddShippingMethod}
              setOpenPopUpRates={setOpenPopUpRates}
              shippingRatesLoading={shippingRatesLoading}
              user={user}
            />
            <Grid sx={{ pt: 3, pr: 3, pl: 3, width: "100%" }} container>
              <Grid item md={12} width={"100%"}>
                <Card elevation={4} sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="get_quote_text"
                    >
                      Get A Quote
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </form>
      </MenuSidebar>
    </>
  );
};

export default QuickShip;
const defaultPackageValues = [
  {
    length: "1",
    width: "1",
    height: "1",
    weight: "1",
    weightOZ: "0",
    qty: "1",
    packageName: "",
  },
];
const QuickShipTitle = styled("h3")(() => ({
  fontSize: "20px",
  margin: "0px 0px 0px 0px",
}));
