import { Card } from "@mui/material";
import React from "react";

const DashboardInfoCards = (props) => {
  const { title, info, icon, color, bgColor } = props;

  return (
    <Card
      elevation={3}
      sx={{
        // mb: 3,
        borderRadius: "20px",
        p: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <div
          style={{
            borderRadius: "50px",
            color: color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: bgColor,
            width: "50px",
            height: "50px",
          }}
        >
          <div style={{ fontSize: 30, marginBottom: "-5px" }}>{icon}</div>
        </div>
      </div>
      <div style={{ marginLeft: "20px" }}>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: "#878787",
            marginBottom: 10,
          }}
        >
          {title}
        </div>
        <div style={{ fontSize: 25, fontWeight: 700 }}>{info}</div>
      </div>
    </Card>
  );
};

export default DashboardInfoCards;
