import React, { useState } from "react";
import styled from "styled-components";
import AddressCard from "../../../components/Card/AddressCard";
import ReturnCard from "../../../components/Card/ReturnCard";
import ShipForm from "../OrderSettings/shared/ShipForm";
import { Icon, Grid } from "@mui/material"; // Import Grid from Material-UI
import OrderCreateNav from "./shared/OrderCreateNav";
import { useNavigate } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const MainCreateOrderContainer = styled("div")(() => ({
  height: "100%",

  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  justifyContent: "center",
  width: "100%",
  backgroundColor: "#f7fbfc",
}));

const ShipFromContainer = styled("div")(() => ({
  margin: "10px 30px 10px 30px",
  // width: "95%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  border: "1px solid lightblue",
}));

const CreateOrder = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const goToOrderDetail = () => {
    navigate("order/orderDetails/");
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const [formFromVal, setFormFromVal] = useState(initialShipValues);
  const [formToVal, setFormToVal] = useState(initialShipValues);

  const handleFromForm = (value, name) => {
    setFormFromVal({ ...formFromVal, [name]: value });
  };

  const handleToForm = (value, name) => {
    setFormToVal({ ...formToVal, [name]: value });
  };

  const shipFromData = {
    shipForm: formFromVal,
    shipTo: formToVal,
  };

  console.log(shipFromData, "shipFromData");

  return (
    <>
      <OrderCreateNav goToOrderDetail={goToOrderDetail} />
      <MainCreateOrderContainer>
        <div style={{ margin: "30px" }}>
          <Grid container>
            <Grid item xs={12} md={open ? 12 : 6}>
              <AddressCard
                title={"Sender address"}
                address={
                  "BitsBay, Near Dogar Chowk mohallah Yousaf park#2, Begum Kot ,Shahdrah, Lahore, 54000"
                }
                email={"nnashitfarooq0@gmail.com"}
                phone={"03160499968"}
                handleOpen={handleOpen}
              />
            </Grid>
            {open ? (
            <Grid item xs={12} md={open ? 12 : 6}>
              <ReturnCard
                title={"Return address"}
                address={
                  "BitsBay, Near Dogar Chowk mohallah Yousaf park#2, Begum Kot ,Shahdrah, Lahore, 54000"
                }
                email={"nnashitfarooq0@gmail.com"}
                phone={"03160499968"}
              />
            </Grid>
            ) : (
            <></>
          )}

            <Grid item xs={12}>
              <ShipFromContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5.5}>
                    <ShipForm
                      type="shipFrom"
                      formValue={formFromVal}
                      handleForm={handleFromForm}
                      setFormValue={setFormFromVal}
                      initialShipValues={initialShipValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <SwapHorizIcon />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5.5}>
                    <ShipForm
                      type="ShipTo"
                      formValue={formToVal}
                      handleForm={handleToForm}
                      setFormValue={setFormToVal}
                      initialShipValues={initialShipValues}
                    />
                  </Grid>
                </Grid>
              </ShipFromContainer>
            </Grid>
          </Grid>{" "}
        </div>
      </MainCreateOrderContainer>
    </>
  );
};

const initialShipValues = {
  company: "",
  name: "",
  street1: "",
  country: "",
  postal_zip_code: "",
  state: "",
  city: "",
  phone: "",
};

export default CreateOrder;
