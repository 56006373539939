import React, { Fragment, useEffect, useState } from "react";
import MenuSidebar from "../../components/MenuSideBar";
import { CircularProgress, Grid, Typography, styled } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import DashboardInfoCards from "./shared/DashboardInfoCards";
import { BsBoxes } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa6";
import { MdLocalShipping } from "react-icons/md";
import { FcShipped } from "react-icons/fc";
import DashboardGraph from "./shared/DashboardGraph";
import axios from "axios";
import { BASE_URL } from "../../../common";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, profileLoading } = useAuth();
  const userId = user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${BASE_URL}user/dashboard/${userId}`
        );
        setDashboardData(response?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const data = dashboardData?.dashboardDetails;

  return (
    <div style={{ display: "flex" }}>
      <MenuSidebar>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <ContentBox>
              <Typography variant="h6"> 👋🏻 Welcome to DeleveryDay</Typography>
              <Typography
                variant="h6"
                marginBottom={"20px"}
                marginLeft={4}
                style={{ fontWeight: 600 }}
              >
                {user?.username}
              </Typography>
              <Grid container spacing={2} mb={3}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardInfoCards
                    title="Total Orders"
                    bgColor={"#f79009"}
                    color={"#ffff"}
                    info={data?.totalOrder}
                    icon={<BsBoxes />}
                    className="circle-icon1"
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardInfoCards
                    title="Unfulfilled Orders"
                    info={data?.totalUnfulfilledOrder}
                    bgColor={"#dbdbdb"}
                    color={"#808080"}
                    icon={<FaBoxOpen />}
                    className="circle-icon1"
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardInfoCards
                    title="Ready to Ship"
                    info={data?.totalReadyForShipOrder}
                    bgColor={"#e0f2fe"}
                    color={"#026aa2"}
                    icon={<MdLocalShipping />}
                    className="circle-icon1"
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardInfoCards
                    title="Shipped"
                    info={data?.totalShippedOrder}
                    bgColor={"#89edbd"}
                    color={"#808080"}
                    icon={<FcShipped />}
                    className="circle-icon1"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardGraph
                    title={"Order"}
                    graphData={data?.orderByPlatform}
                    totalQuantity={data?.totalOrder}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardGraph
                    title={"Unfulfilled"}
                    graphData={data?.unfulfilledOrderByPlatform}
                    totalQuantity={data?.totalOrder}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardGraph
                    title={"Ready to Ship"}
                    graphData={data?.readyForShipByPlatform}
                    totalQuantity={data?.totalOrder}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <DashboardGraph
                    title={"Shipped"}
                    graphData={data?.shippedByPlatform}
                    totalQuantity={data?.totalOrder}
                  />
                </Grid>
              </Grid>
            </ContentBox>
          </Fragment>
        )}
      </MenuSidebar>
    </div>
  );
};

export default Dashboard;

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: {
    margin: "16px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
}));
