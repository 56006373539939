import React from "react";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";
import PaymentIcon from "@mui/icons-material/Payment";
import { useAuth } from "./app/contexts/AuthContext";
import HomeIcon from "@mui/icons-material/Home";
import { FaShippingFast } from "react-icons/fa";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";

const MENU = [
  {
    icon: <HomeIcon />,
    title: "Dashboard",
    path: "/dashboard",
    items: [],
  },
  { label: "ECOMMERCE", labelIcon: <ShoppingCartIcon />, type: "label" },
  {
    icon: <ShoppingCartIcon />,
    title: "Orders",
    path: "/orders",
    items: [],
  },
  { label: "SHIPPING", labelIcon: <LocalShippingIcon />, type: "label" },
  {
    title: "Quick Ship",
    path: "/shipping/quick-ship",
    icon: <LocalShippingIcon />,
    items: [],
  },
  {
    title: "Shipment",
    path: "/shipping/shipment",
    icon: <DepartureBoardIcon />,
    items: [],
  },
  { label: "SETTINGS", labelIcon: <SettingsIcon />, type: "label" },
  {
    title: "Store",
    path: "/setting/store",
    icon: <StoreIcon />,
    items: [],
  },
  {
    title: "Company Info",
    path: "/setting/connect",
    icon: <SecurityOutlinedIcon />,
    items: [],
  },
  {
    title: "Payment",
    path: "/setting/payment",
    icon: <PaymentIcon />,
    items: [],
  },
];
const ADMIN_MENU = [
  {
    icon: <ShoppingCartIcon />,
    title: "Orders",
    path: "/orders",
    items: [],
  },
];
export const useRoleSidebar = () => {
  const { user } = useAuth();
  let sidebarMenu;

  switch (user?.role) {
    case "USER":
      sidebarMenu = MENU;
      break;
    case "SUPER_USER":
      sidebarMenu = MENU;
      break;
    case "ADMIN":
      sidebarMenu = ADMIN_MENU;
      break;
    default:
      // Default to user menu if role is not recognized
      sidebarMenu = MENU;
      break;
  }
  return sidebarMenu;
};
