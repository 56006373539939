import { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";

// import { Toast } from '../../Toaster/Toaster';
// import { convertStringToBase64, downloadFileExcel } from 'app/utils/utils';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MenuSideBarContext = createContext();

const useMenuSideBarContext = () => {
  return useContext(MenuSideBarContext);
};

const MenuSideBarContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const currentOrigin = window.location.pathname;

  const [selected, setSelected] = useState();
  const [childSelected, setChildSelected] = useState("");
  const [settingsOpen, setSettingsOpen] = useState("");
  const [sideBarOpen, setSideBarOpen] = useState(false);

  useEffect(() => {
    if (currentOrigin === "/dashboard") {
      setSelected("Orders");
      setChildSelected("");
      setSettingsOpen("");
    } else if (currentOrigin === "/quick-ship") {
      setSelected("Quick Ship");
      setChildSelected("");
      setSettingsOpen("");
    } else if (currentOrigin === "/setting/connect") {
    } else if (currentOrigin === "/setting/store") {
      setChildSelected("Store");
      {
        /* User info and select dropd  own */
      }
      setSettingsOpen("Settings");
      setSelected("");
    } else if (currentOrigin === "/setting/connect") {
      setSettingsOpen("Settings");
      setSelected("");
      setChildSelected("Company Info");
    } else if (currentOrigin === "/setting/payment") {
      setSettingsOpen("Settings");
      setSelected("");
      setChildSelected("Payment");
    }
  }, []);

  const handleItemsClick = (title, path) => {
    if (title !== "Settings") {
      setSelected(title);
      setChildSelected("");
      setSettingsOpen("");

      // Check if the sidebar is open, then navigate
      if (sideBarOpen) {
        navigate(path);
      } else {
        // If the sidebar is closed, navigate only if the path is different
        if (currentOrigin !== path) {
          navigate(path);
        }
      }
    } else {
      setSettingsOpen((prev) => (prev === title ? "" : title));
    }
  };

  const handleChildClick = (title, path) => {
    setChildSelected(title);
    navigate(path);
  };
  return (
    <MenuSideBarContext.Provider
      value={{
        selected,
        childSelected,
        setChildSelected,
        handleItemsClick,
        handleChildClick,
        settingsOpen,
        setSideBarOpen,
        sideBarOpen,
      }}
    >
      {children}
    </MenuSideBarContext.Provider>
  );
};

export { MenuSideBarContextProvider, useMenuSideBarContext };
