import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
const RenderModal = (props) => {
  const {
    title = '',
    handleClose,
    open,
    children,
    displaySubmitAction = false,
    displayCloseIcon = false,
    maxWidth = 'sm',
  } = props;

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={maxWidth}
      >
        {title && (
          <DialogTitle id="customized-dialog-title" onClose={handleClose} marginRight={'45px'}>
            {title}
          </DialogTitle>
        )}

        {displayCloseIcon && (
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              marginLeft: '25px',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        )}

        <DialogContent dividers>{children}</DialogContent>

        {displaySubmitAction && (
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default React.memo(RenderModal);
