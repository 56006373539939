import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableBody,
  InputAdornment,
  Button,
  Grid,
  Card,
  IconButton,
  CircularProgress,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

import styled from "styled-components";
import RecipientCard from "./shared/RecipientCard";
import { admin_Rates, super_User_Rates } from "./CardResData";
import { CARRIER_LOGOS } from "../../utils/constant";
import ordersData from "./EbayData";
import { useLocation, useParams } from "react-router-dom";
import OrderDetailNav from "./OrderDetailNav";
import { useDashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import { useAuth } from "../../contexts/AuthContext";
import MenuSidebar from "../../components/MenuSideBar";
import CustomButton from "../../components/CustomButton";
import SingleSelect from "../../components/AppSelect/SingleSelect";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BatchModal from "../batch-modal/BatchModal";
import { Palette } from "@mui/icons-material";

import { Toast } from "../../components/Toaster/Toaster";
const PackageCardContainer = styled("div")(() => ({
  marginTop: 20,
}));
const unit_values = [
  { value: "in", label: "in" },
  { value: "cm", label: "cm" },
  { value: "mm", label: "mm" },
];
const weight_values = [
  { value: "lb", label: "lb" },
  { value: "g", label: "g" },
  { value: "oz", label: "oz" },
  { value: "kg", label: "kg" },
];

const OrderDetails = () => {
  const { id, orderType } = useParams();

  const {
    getRates,
    setPdfValue,
    postCreateLabelApiCall,
    postSuperAdminCreateLabelApiCall,
    superAdminLabelLoading,
    createLabelLoading,
    orderDetailsLoading,
    getOrderDetailsApiCall,
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    postAdminCreateLabelApiCall,
    postUpdateTackingNumberApiCall,
    updatingTrackingLoading,
    resetOrderDetails,
    getDynamicRatesCallApi,
    dynamicRatesLoading,
    dynamicRates,
    ratesLoading
  } = useDashboardContext();
  console.log(walmartOrderDetails, "walmartOrderDetails");
  const { user } = useAuth();
  // console.log(state, "state");
  const [size, setSize] = useState("in");
  const [weight, setWeight] = useState("lb");
  useEffect(() => {
    getOrderDetailsApiCall(id, orderType, user?.id);
  }, [id, orderType, user]);
  const [senderAddress, setSenderAddress] = useState(
    user?.companies?.senderAddress
  );
  const [anchorUnit, setAnchorUnit] = useState(null);
  const [anchorWeight, setAnchorWeight] = useState(null);
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [rates, setRates] = useState(null);
  
  const [dateValue, setDateValue] = useState(null);
  const [distanceValue, setDistanceValue] = useState("in");
  const [massValue, setMassValue] = useState("lb");
  const [error, setError] = useState(false);
  const [packageDetails, setPackageDetails] = useState("");

  const formValues = {
    length: "",
    width: "",
    height: "",
    unit: "in",
    weight: "",
    weightUnit: "lb",
  };
  const ecommerceDetails =
    amzAllOrderDetails || ebayOrderDetails || walmartOrderDetails;
  const amzOrderStatus = amzAllOrderDetails?.status;
  const ebayStatus = ebayOrderDetails?.status;
  const walmartStatus = walmartOrderDetails?.status;
  const currentStatusValue =
    amzAllOrderDetails?.status ||
    ebayOrderDetails?.status ||
    walmartOrderDetails?.status;
  const trackingNumber =
    amzAllOrderDetails?.trackingNumber ||
    ebayOrderDetails?.trackingNumber ||
    walmartOrderDetails?.trackingNumber;
  const trackingUpdated =
    amzAllOrderDetails?.trackingUpdated ||
    ebayOrderDetails?.trackingUpdated ||
    walmartOrderDetails?.trackingUpdated;
  const carrierName =
    amzAllOrderDetails?.carrierCode ||
    ebayOrderDetails?.carrierCode ||
    walmartOrderDetails?.carrierCode;
    const carrierImage =
    CARRIER_LOGOS[carrierName];

  const labels =
    amzAllOrderDetails?.label ||
    ebayOrderDetails?.label ||
    walmartOrderDetails?.label;
  console.log(amzOrderStatus, "status-1");
  console.log(ebayStatus, "status-2");
  // console.log(walmartStatus, "status-3");
  const disablePackages =
    amzOrderStatus === "Ready for Ship" ||
    ebayStatus === "Ready for Ship" ||
    walmartStatus === "Ready for Ship" ||
    amzOrderStatus === "Shipped" ||
    ebayStatus === "Shipped" ||
    walmartStatus === "Shipped"
      ? true
      : false;

  // const ebayDisablePackages = ebayStatus === "FULFILLED" ? true : false;
  // const walmartDisablePackages = walmartStatus === "Unshipped" ? false : true;

  console.log(disablePackages, "disablePackages-1");

  console.log(
    disablePackages,
    amzOrderStatus,
    ebayStatus,
    // walmartStatus,
    "hhhhhh"
  );
  const generateSuccessToast = (textValue) => {
    Toast.success(textValue);
  };
  useEffect(() => {
    setSenderAddress(user?.companies?.senderAddress);
  }, [user]);

  const [values, setValues] = useState(formValues);
  const [rateType, setRateType] = useState("");
  const [status, setStatus] = useState();
  console.log(values, "values");
  const [payload, setPayload] = useState();
  console.log(payload, "payload");
  const handleChangeValues = (event) => {
    setIsEdited(true);
    const { name, value } = event.target || event;

    // Update form values
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleUnitSelectClick = (event) => {
    setAnchorUnit(event.currentTarget);
  };
  const handleUnitSelectClose = () => {
    setAnchorUnit(null);
  };

  const handleUnitClick = (value) => {
    setDistanceValue(value);
    handleUnitSelectClose();
    setIsEdited(true);
  };
  const handleWeightSelectClose = () => {
    setAnchorWeight(null);
  };
  const handleWeightSelectClick = (event) => {
    setAnchorWeight(event.currentTarget);
  };

  const handleWeightClick = (value) => {
    setMassValue(value);
    handleWeightSelectClose();
    setIsEdited(true);
  };

  const handleCardClick = (serviceId, rateData, providerImage) => {
    setRateType(rateData.serviceName);
    setActiveCard(serviceId === activeCard ? null : serviceId);
    setIsCardSelected(!isCardSelected);

    console.log(serviceId, rateData, payload, "dddddd");
    let updatedPayload = payload;
    updatedPayload.label = {
      serviceName: rateData?.serviceName,
      amount: rateData?.totalCharge,
      providerName: rateData?.provider,
      providerImage: providerImage,
    };
    setPayload(updatedPayload);

    // Disable the Buy button
    setIsBuyButtonDisabled(true);

    // Enable the Buy button after a delay (2 seconds in this case)
    setTimeout(() => {
      setIsBuyButtonDisabled(false);
    }, 500);
  };
  const handleRate = (item) => {
    setActiveCard(item?.service === activeCard ? null : item?.service);
    setIsCardSelected(!isCardSelected);
    let updatedPayload = payload;
    updatedPayload.label = {
      serviceName: item?.serviceType,
      amount: item?.discountShippingCost,
      providerName: item?.carrierCode,
    };
    setPayload(updatedPayload);

    // Disable the Buy button
    setIsBuyButtonDisabled(true);

    // Enable the Buy button after a delay (2 seconds in this case)
    setTimeout(() => {
      setIsBuyButtonDisabled(false);
    }, 500);
  };
  const handleCreateLabel = () => {
    const validatePayload = () => {
      const errors = [];

      // Validate parcels
      if (!payload.parcels || payload.parcels.length === 0) {
        errors.push("Package dimentions are required.");
      } else {
        const parcel = payload.parcels[0]; // Assuming only one parcel for simplicity

        if (
          !parcel.length ||
          !parcel.width ||
          !parcel.height ||
          !parcel.distance_unit ||
          !parcel.weight ||
          !parcel.mass_unit
        ) {
          errors.push("All package dimentions fields are required.");
        }
      }

      // Validate address_from
      const addressFrom = payload.address_from;
      if (
        !addressFrom ||
        !addressFrom.name ||
        !addressFrom.company ||
        !addressFrom.street1 ||
        !addressFrom.city ||
        !addressFrom.state ||
        !addressFrom.zip ||
        !addressFrom.country ||
        !addressFrom.phone
      ) {
        errors.push("All address_from fields are required.");
      }

      // Validate address_to
      const addressTo = payload.address_to;
      if (
        (!addressTo ||
          !addressTo.name ||
          !addressTo.company ||
          !addressTo.street1 ||
          !addressTo.city ||
          !addressTo.state ||
          !addressTo.zip ||
          !addressTo.country ||
          !addressTo.phone) &&
        user.role === "SUPER_USER"
      ) {
        errors.push("All address_to fields are required.");
      }

      // Validate other fields
      // if (!payload.object_purpose || !payload.async || !payload.shipment_date) {
      //   errors.push("All other fields are required.");
      // }

      return errors;
    };

    const validationErrors = validatePayload();
    if (validationErrors.length > 0) {
      Toast.error(`Validation errors: ${validationErrors}`);

      // Handle validation errors (e.g., show error messages to the user)
    } else {
      // Proceed with the valid payload
      // if (user.role === "USER") {
      //   if (
      //     payload?.label.serviceName === "Ground Advantage" ||
      //     payload?.label.serviceName === "Priority Mail"
      //   ) {
      //     postCreateLabelApiCall(payload, id, orderType, user?.id);
      //   } else {
      //     postAdminCreateLabelApiCall(payload, id, orderType, user?.id);
      //   }
      // } else {
      //   postSuperAdminCreateLabelApiCall(payload, id, orderType, user?.id);
      // }
      console.log(payload,"payloadInApi")
      postCreateLabelApiCall(payload, id, orderType, user?.id);

    }
  };
  // useEffect to reset button state when the selected card changes
  useEffect(() => {
    setIsBuyButtonDisabled(true);
  }, [activeCard]);

  useEffect(() => {
    setPayload({
      parcels: [
        {
          length: values?.length,
          width: values?.width,
          height: values?.height,
          distance_unit: distanceValue,
          weight: values?.weight,
          mass_unit: massValue,
        },
      ],
      address_from: {
        name: senderAddress?.name || user?.companies?.senderAddress?.name,
        company: senderAddress?.name || user?.companies?.senderAddress?.name,
        street1:
          senderAddress?.street || user?.companies?.senderAddress?.street,
        city: senderAddress?.city || user?.companies?.senderAddress?.city,
        state: senderAddress?.state || user?.companies?.senderAddress?.state,
        zip:
          senderAddress?.zip_code || user?.companies?.senderAddress?.zip_code,
        country: "US",
        phone: senderAddress?.phone || user?.companies?.senderAddress?.phone,
        email: senderAddress?.email || user?.companies?.senderAddress?.email,
      },
      address_to: {
        name:
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.fullName ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.name ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name,
        company:
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.fullName ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.name ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Name,
        street1:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.AddressLine1 ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.address1 ||
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.contactAddress?.addressLine1,
        city:
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.contactAddress?.city ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.city ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.City,
        state:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.StateOrRegion ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.state ||
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.contactAddress?.stateOrProvince,
        zip:
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.contactAddress?.postalCode ||
          walmartOrderDetails?.shippingInfo?.postalAddress?.postalCode ||
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.PostalCode,
        country: "US",
        phone:
          amzAllOrderDetails?.fullAddress?.ShippingAddress?.Phone ||
          walmartOrderDetails?.shippingInfo?.phone ||
          ebayOrderDetails?.fulfillmentStartInstructions[0]?.shippingStep
            ?.shipTo?.primaryPhone?.phoneNumber,
      },
      object_purpose: "PURCHASE",
      async: false,
      shipment_date: dateValue?.toISOString(),
      userId: user?.id,
      orderId: id,
      orderType: orderType,
    });
  }, [
    senderAddress,
    user,
    amzAllOrderDetails,
    ebayOrderDetails,
    walmartOrderDetails,
    id,
    dateValue,
    values
  ]);
  const [fieldError, setFieldError] = useState({
    length: true,
    width: true,
    height: true,
    weight: true,
  });

  const handleSave = () => {
    // Reset errors
    // setFieldError({
    //   length: true,
    //   width: true,
    //   height: true,
    //   weight: true,
    // });

    // Local variable to track errors
    // let hasErrors = false;
    // console.log(values,"valuesIn")
    // Check and set errors for individual fields

    // if (!values?.length) {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     length: true,
    //   }));
    //   hasErrors = true;
    // } else {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     length: false,
    //   }));
    //   hasErrors = false;
    // }
    // if (!values?.width) {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     width: true,
    //   }));
    //   hasErrors = true;
    // } else {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     width: false,
    //   }));
    //   hasErrors = false;
    // }
    // if (!values?.height) {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     height: true,
    //   }));
    //   hasErrors = true;
    // } else {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     height: false,
    //   }));
    //   hasErrors = false;
    // }
    // if (!values?.weight) {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     weight: true,
    //   }));
    //   hasErrors = true;
    // } else {
    //   setFieldError((prevErrors) => ({
    //     ...prevErrors,
    //     weight: false,
    //   }));
    //   hasErrors = false;
    // }
    // if (hasErrors) {
    //   // Handle the case where there are errors
    //   // You can also display an error message or use any other logic here
    //   return;
    // }
    if (
      values?.length === "" ||
      values?.width === "" ||
      values?.height === "" ||
      values?.weight === ""
    ) {
      return Toast.error("Please Enter package dimension completly");
    }
    // Check if any errors exist

    // Continue with saving the data if no errors
    setIsEdited(false);

    const newParcelValues = {
      length: values?.length,
      width: values?.width,
      height: values?.height,
      distance_unit: distanceValue,
      weight: values?.weight,
      mass_unit: massValue,
    };
    setPayload((prevPayload) => ({
      ...prevPayload,
      parcels: [newParcelValues],
    }));

    const data = {
      userId: user?.id,
      packageDetails: [
        {
          length: values?.length,
          width: values?.width,
          height: values?.height,
          weight: values?.weight,
        },
      ],
    };

    console.log(payload, "kkkk")
    getDynamicRatesCallApi(payload);
  };

  const handleCancel = () => {
    setSize("in");
    setWeight("lb");
    setIsEdited(false);
  };

  const handlePdf = (label) => {
    setDisplayPopup(true);
    setPdfValue(label);
  };
  const handleUpdateTrackingNumber = () => {
    console.log(ecommerceDetails.trackingNumber);
    console.log(user.id);
    console.log(id);
    console.log(orderType);
    const ebayFilter = ebayOrderDetails?.lineItems?.map(
      ({ lineItemId, quantity }) => ({
        lineItemId,
        quantity,
      })
    );
    const trackingInfo = {
      shipDateTime: new Date().toISOString(),
      carrierName: {
        carrier: carrierName,
      },
      methodCode: "Standard",
      trackingNumber: trackingNumber,
    };
    const orderLines = walmartOrderDetails?.orderLines?.orderLine?.map(
      ({ lineNumber, orderLineStatuses }) => ({
        lineNumber,
        sellerOrderId: "",

        orderLineStatuses: {
          orderLineStatus: orderLineStatuses?.orderLineStatus?.map(
            ({ statusQuantity }) => ({
              status: "Shipped",

              statusQuantity: statusQuantity,
              trackingInfo: trackingInfo,
            })
          ),
        },
      })
    );
    console.log(orderLines, "orderLines");
    if (orderType === "ebay") {
      const data = {
        trackingNumber: ecommerceDetails.trackingNumber,
        providerName: ecommerceDetails.carrierName,
        userId: user.id,
        orderId: id,
        orderType: orderType,
        trackingNumber: trackingNumber,
        lineItems: ebayFilter,
        shipmentDate: new Date()?.toISOString(),
      };

      postUpdateTackingNumberApiCall(data, orderType);
    } else if (orderType === "walmart") {
      const data = {
        orderLines,
        orderType,
        orderId: id,
        userId: user?.id,
      };
      postUpdateTackingNumberApiCall(data, orderType);
    }
  };

  return (
    <MenuSidebar>
      {createLabelLoading ||
      orderDetailsLoading ||
      updatingTrackingLoading ||
      superAdminLabelLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {(amzAllOrderDetails !== null ||
            ebayOrderDetails !== null ||
            walmartOrderDetails !== null) && (
            <>
              <OrderDetailNav
                orderId={id}
                isBuyButtonDisabled={isBuyButtonDisabled}
                amzOrderStatus={amzOrderStatus || ebayStatus || walmartStatus}
                userRole={user?.role}
                currentStatusValue={currentStatusValue}
                handleCreateLabel={handleCreateLabel}
                orderType={orderType}
                userId={user?.id}
                trackingUpdated={trackingUpdated}
                resetOrderDetails={resetOrderDetails}
              />
              <Grid container>
                <Grid item md={8.5}>
                  <Card sx={{ ml: 2, mr: 2, mt: 2, p: 2 }} elevation={4}>
                    <Grid item md={12}>
                      {(amzAllOrderDetails ||
                        ebayOrderDetails ||
                        walmartOrderDetails) && (
                        <RecipientCard
                          EbayShipTo={
                            ebayOrderDetails?.fulfillmentStartInstructions[0]
                              ?.shippingStep?.shipTo
                          }
                          WalmartShipTo={
                            walmartOrderDetails?.shippingInfo?.postalAddress
                          }
                          AmazonShipTo={
                            amzAllOrderDetails?.fullAddress?.ShippingAddress
                          }
                          orderStatus={
                            amzOrderStatus || ebayStatus //|| walmartStatus
                          }
                          userRole={user?.role}
                          disablePackages={disablePackages}
                          senderAddress={senderAddress}
                          setSenderAddress={setSenderAddress}
                        />
                      )}

                      <PackageCardContainer>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Package Dimension
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",

                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  color: "#666",
                                  marginBottom: "5px",
                                  fontWeight: "600",
                                }}
                              >
                                Length
                              </div>
                              <TextField
                                defaultValue={values?.length}
                                disabled={disablePackages}
                                onChange={handleChangeValues}
                                name="length"
                                size="small"
                                type="number"
                                style={{ width: 120 }} // Align text to the right
                                inputProps={{
                                  min: 0,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <div
                                      style={{
                                        padding: "3px",

                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {distanceValue}
                                    </div>
                                  ),
                                }}
                              />{" "}
                            </div>
                            <div
                              style={{
                                color: "#5c7786",
                                fontSize: "17px",
                                marginTop: "25px",
                              }}
                            >
                              X
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#666",
                                  marginBottom: "5px",
                                  fontWeight: "600",
                                }}
                              >
                                Width
                              </div>
                              <TextField
                                name="width"
                                disabled={disablePackages}
                                defaultValue={values.width}
                                onChange={handleChangeValues}
                                onBlur={() => setError(false)}
                                size="small"
                                type="number"
                                style={{ width: 120 }}
                                inputProps={{
                                  min: 0,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <div
                                      style={{
                                        padding: "3px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {distanceValue}
                                    </div>
                                  ),
                                }}
                              />
                            </div>

                            <div
                              style={{
                                color: "#5c7786",
                                fontSize: "17px",
                                marginTop: "25px",
                              }}
                            >
                              X
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#666",
                                  marginBottom: "5px",
                                  fontWeight: "600",
                                }}
                              >
                                Height
                              </div>
                              <TextField
                                name="height"
                                disabled={disablePackages}
                                defaultValue={values.height}
                                onChange={handleChangeValues}
                                size="small"
                                type="number"
                                style={{ width: 120 }}
                                inputProps={{
                                  min: 0,
                                }} // Align text to the right
                                InputProps={{
                                  endAdornment: (
                                    <div
                                      style={{
                                        padding: "3px",

                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {distanceValue}
                                    </div>
                                  ),
                                }}
                              />{" "}
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "#666",
                                  marginBottom: "5px",
                                  fontWeight: "600",
                                }}
                              >
                                Weight
                              </div>
                              <TextField
                                name="weight"
                                defaultValue={values.weight}
                                disabled={disablePackages || isCheckboxChecked}
                                onChange={handleChangeValues}
                                size="small"
                                type="number"
                                style={{ width: 120 }} // Align text to the right
                                inputProps={{
                                  min: 0,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <div
                                      style={{
                                        padding: "3px",

                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {massValue}
                                    </div>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "25px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <SingleSelect
                              width={"120px"}
                              height={"40px"}
                              title={distanceValue}
                              options={unit_values}
                              variant={"outlined"}
                              anchor={anchorUnit}
                              handleMenuItemClick={handleUnitClick}
                              handleClick={handleUnitSelectClick}
                              handleClose={handleUnitSelectClose}
                              disabled={disablePackages}
                            />
                            <SingleSelect
                              width={"120px"}
                              height={"40px"}
                              title={massValue}
                              options={weight_values}
                              variant={"outlined"}
                              anchor={anchorWeight}
                              handleMenuItemClick={handleWeightClick}
                              handleClick={handleWeightSelectClick}
                              handleClose={handleWeightSelectClose}
                              disabled={disablePackages}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {isEdited && (
                            <div
                              style={{
                                // padding: '10px',
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Button
                                onClick={handleCancel}
                                sx={{
                                  color: "black",
                                  border: "0.5px solid red",
                                  padding: "auto 15px",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleSave}
                                sx={{
                                  color: "white",
                                  backgroundColor: "#10C66F",
                                  "&:hover": {
                                    backgroundColor: "#10C66F",
                                  },
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </div>
                      </PackageCardContainer>
                    </Grid>
                    <Grid sx={{ mt: 3 }} item md={12}>
                      <div style={{ fontWeight: "bold" }}>Items</div>
                      <div>
                        <Table sx={{ minWidth: 450 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{
                                  color: "#a1abb0",
                                  width: 190,
                                  marginLeft: 10,
                                }}
                              >
                                Item
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ color: "#a1abb0", width: 80 }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ color: "#a1abb0", width: 80 }}
                              >
                                Weight
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ color: "#a1abb0", width: 80 }}
                              >
                                Value
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {ebayOrderDetails && (
                            <TableBody>
                              {ebayOrderDetails?.lineItems?.map((order) => (
                                <TableRow
                                  key={order.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                      paddingLeft: 20,
                                    }}
                                  >
                                    {order?.title}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {order?.quantity}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                      align: "center",
                                    }}
                                  >
                                    0.45 lb
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    CA${order?.lineItemCost?.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          )}
                          {walmartOrderDetails && (
                            <TableBody>
                              {walmartOrderDetails?.orderLines?.orderLine?.map(
                                (item) => (
                                  <TableRow
                                    key={item.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      {item?.item?.productName}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {item?.orderLineQuantity?.amount}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        align: "center",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      CA$
                                      {
                                        item?.charges?.charge[0]?.chargeAmount
                                          ?.amount
                                      }
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          )}
                          {amzAllOrderDetails && (
                            <TableBody>
                              {amzAllOrderDetails?.orderItems?.map((item) => (
                                <TableRow
                                  key={item.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                      paddingLeft: 20,
                                    }}
                                  >
                                    {item?.Title}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {item?.QuantityOrdered}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                      align: "center",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                    }}
                                  >
                                    {item?.ItemPrice?.CurrencyCode}
                                    <span>{item?.ItemPrice?.Amount}</span>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </div>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item md={3.5}>
                  {!disablePackages ? (
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        mt: 2,
                        maxHeight: 560,
                        overflowY: "auto",
                        p: 2,
                      }}
                      elevation={4}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 10,
                          }}
                        >
                          <div style={{ fontWeight: "bold" }}>Rates</div>
                          <div
                            style={{
                              color: "#10C66F",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            Refresh
                          </div>
                        </div>
                        <div style={{ color: "#a1abb0" }}>
                          Transit times may be estimated.
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: "80px",
                            border: "1px solid #e9f1f5",
                            backgroundColor: "#e9f1f5",
                            borderRadius: "5px",
                            marginTop: 10,
                          }}
                        >
                          <div
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              marginLeft: 10,
                              marginRight: 10,
                            }}
                          >
                            <div>CUSTOMER SHIPPING METHOD</div>
                            <div style={{ fontWeight: "bold" }}>
                              "Expedited"
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Shipments
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            defaultValue={dayjs(new Date())}
                            label="Select Date"
                            disablePast
                            onChange={(item) => {
                              setDateValue(item);
                            }}
                          />
                        </LocalizationProvider>
                        <div
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          More Rates
                        </div>

                        <div>
                          {ratesLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            dynamicRates?.rates?.map((item, i) => {
                              const carrierLogo =
                                CARRIER_LOGOS[item?.carrierCode];
                              return (
                                <div
                                  onClick={() => handleRate(item)}
                                  key={item?.service}
                                  style={{
                                    width: "100%",
                                    border:
                                      activeCard === item?.service
                                        ? "1px solid rgb(48, 136, 30)"
                                        : "1px solid #e9f1f5",
                                    backgroundColor:
                                      activeCard === item?.service
                                        ? "rgb(242, 255, 235)"
                                        : "#ffffff",
                                    borderRadius: "5px",
                                    marginTop: 10,
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                      marginLeft: 10,
                                      marginRight: 10,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "60%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        {carrierLogo && (
                                          <div>
                                            <img
                                              width="30px"
                                              src={`/assets/media/carrier/${carrierLogo}`}
                                              alt="Carrier Logo"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div style={{ marginLeft: 5 }}>
                                        <div>{item?.service}</div>
                                        <div>{item?.serviceType}</div>
                                      </div>
                                    </div>

                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          ${item?.shippingCost}
                                        </div>
                                        <div
                                          style={{
                                            textDecoration: "none",
                                            marginLeft: "5px",
                                            fontSize: "12px",
                                            borderRadius: "50px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "2px 4px",
                                            color: "white",
                                            background: "#fcae1e",
                                          }}
                                        >
                                          {user?.ratePercentage}% SAVE
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          fontSize: "20px",
                                        }}
                                      >
                                        ${item?.discountShippingCost}
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {item?.transitTime}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        mt: 2,
                        maxHeight: 560,
                        overflowY: "auto",
                        p: 2,
                      }}
                      elevation={4}
                    >
                      <>
                        <div
                          style={{
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Shipment Details
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {carrierImage && (
                            <img
                              style={{ marginRight: 5 }}
                              width="50px"
                              src={`/assets/media/carrier/${carrierImage}`}
                              />
                          )}
                          -
                          {carrierName && (
                            <div
                              style={{
                                marginLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {carrierName}
                            </div>
                          )}
                        </div>
                        {trackingNumber && (
                          <>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                fontWeight: "bold",
                              }}
                            >
                              Tracking Number :
                            </div>
                            <div>
                              {trackingNumber}{" "}
                              <CopyToClipboard text={trackingNumber}>
                                <MdContentCopy
                                  size={"15px"}
                                  cursor="pointer"
                                  onClick={() =>
                                    generateSuccessToast("Value Copied!")
                                  }
                                  title="Copy Value"
                                />
                              </CopyToClipboard>
                            </div>
                          </>
                        )}
                        <div>
                          {!trackingUpdated && (
                            <CustomButton
                              width={140}
                              label={"Update Tracking"}
                              disabled={ecommerceDetails.trackingUpdated}
                              onClick={() => {
                                handleUpdateTrackingNumber();
                              }}
                            />
                          )}
                        </div>
                        {labels && (
                          <>
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Labels
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 10,
                              }}
                            >
                              <div style={{ fontWeight: "bold" }}>
                                Shipping Label
                              </div>
                              <IconButton onClick={() => handlePdf(labels)}>
                                <PictureAsPdfIcon />
                              </IconButton>
                            </div>
                          </>
                        )}
                      </>
                    </Card>
                  )}
                  {displayPopup && (
                    <BatchModal
                      displayPopup={displayPopup}
                      setDisplayPopup={setDisplayPopup}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </MenuSidebar>
  );
};

export default OrderDetails;
