import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDashboardContext } from '../../contexts/DashboardContext/DashboardContext';
import PdfViewer from '../dashboard/shared/PdfViewer';
// import { Document } from 'react-pdf/dist/esm/entry.webpack';

const BatchModal = ({displayPopup, setDisplayPopup}) => {
  const { pdfValue } = useDashboardContext();
  const [fileURL, setFileURL] = useState(null);
 
  useEffect(() => {
    if (pdfValue) {
      try {
        const decodedPdfData = atob(pdfValue);
        const pdfDataUri = `data:application/pdf;base64,${decodedPdfData}`;
        setFileURL(pdfDataUri);
        setDisplayPopup(true);
      } catch (error) {
        console.error('Error decoding base64:', error);
        // Handle the error, e.g., show an error message or setFileURL(null)
      }
    }
  }, [pdfValue]);
  

  return (
    <>
      <Dialog
        onClose={() => {
          setDisplayPopup(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={displayPopup}
        fullWidth={true}
      >
        <DialogContent>
        {/* <Document file={{data:pdfValue}}> */}
        {/* <Page pageNumber={pageNumber} /> */}
      {/* </Document> */}
      <PdfViewer pdfContent={pdfValue}/>
        {/* // { fileURL&& <iframe */}
        {/* //     src={fileURL} */}
        {/* //     title="PDF Viewer" */}
        {/* //     style={{ width: '100%', height: '80vh' }} */}
        {/* //   ></iframe>} */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BatchModal;
