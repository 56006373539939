import { Box, Button, Icon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const SingleSelect = ({
  title,
  options,
  anchor,
  handleMenuItemClick,
  handleClick,
  handleClose,
  fontWeight,
  border,
  variant,
  textTransform,
  width,
  height,
  disabled,
}) => {
  return (
    <div>
      <Button
        style={{
          border: border,
          borderColor: "#89edbd",
          textTransform: textTransform,
          width: width,
          height: height,
          justifyContent: "space-between",
        }}
        onClick={handleClick}
        className="filter_text"
        disabled={disabled}
        variant={variant}
      >
        <Typography sx={{ fontWeight: fontWeight, color: "#89edbd" }}>
          {title}
        </Typography>{" "}
        <ArrowDropDownIcon style={{ color: "#89edbd" }} />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        // Adjust the width as needed

        slotProps={{
          paper: {
            style: menuStyle,
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option?.value}
            value={option?.value}
            onClick={() => handleMenuItemClick(option?.value)}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <div style={{ marginBottom: -2 }}>{option.icon}</div>
            <Typography sx={{ fontSize: 15 }}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SingleSelect;
const menuStyle = {
  width: "200px",
  maxHeight: "500px",
};
