import React, { useState, useEffect } from "react";
import MenuSidebar from "../../../components/MenuSideBar";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
  Card,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { pricingData } from "../../../../countryData";
import { loadStripe } from "@stripe/stripe-js";
import { Toast } from "../../../components/Toaster/Toaster";
import Loading from "../../../components/MatxLoading";
import PaymentModal from "./PaymentModal";
import TableCustom from "../../../components/Table/TableCustom";
import { useDashboardContext } from "../../../contexts/DashboardContext/DashboardContext";

const Billing = () => {
  const [open, setOpen] = useState(false);
  const [promoValue, setPromoValue] = useState("");
  const { user, profileLoading } = useAuth();
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    const initStripe = async () => {
      const stripeInstance = await loadStripe(
        "pk_live_51LhaMCDvHAvdTIHCga0LoG3FEaQYdl4Wq0djH9Z25dnkD3oHfhWItDlj2YXsYDIflPA9XXOjf6OdJu1M6IqYBHvJ005iNov4X9"
      );
      setStripe(stripeInstance);
    };
    initStripe();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const userHistoryColumns = [
    {
      id: 5,
      name: <Box className="text-wrap">Tracking Id </Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                {row.trackingId}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Amount</Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                $ {row.labelPrice}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Date</Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                {row.date}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];

  const userPaymentColumns = [
    {
      id: 5,
      name: <Box className="text-wrap">Credit </Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                ${row?.credit}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Card last 4</Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                **** **** **** {user?.card}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
    {
      id: 5,
      name: <Box className="text-wrap">Date</Box>,
      cell: (row) => {
        return (
          <>
            <Box>
              <div style={{ fontSize: "16px" }} className="">
                {new Date(row?.date).toLocaleString()}
              </div>
            </Box>
          </>
        );
      },
      sortable: false,
    },
  ];

  const [addBalance, setAddBalance] = useState("");
  const [makePaymentLoading, setMakePaymentLoading] = useState(false);

  const { addBalancefunc, updateUserBalance } = useDashboardContext();

  const handleChange = (event) => {
    setAddBalance(event.target.value);
  };

  const makePayment = async () => {
    setMakePaymentLoading(true);
    const stripeInstance = await loadStripe(
      "pk_live_51LhaMCDvHAvdTIHCga0LoG3FEaQYdl4Wq0djH9Z25dnkD3oHfhWItDlj2YXsYDIflPA9XXOjf6OdJu1M6IqYBHvJ005iNov4X9"
    );

    const body = {
      amount: addBalance,
      user: user?.id,
    };

    try {
      const response = await addBalancefunc(body);

      if (response.success) {
        Toast.success("Balance added successfully");
        setMakePaymentLoading(false);
      } else if (response.requiresAction) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          response?.paymentIntentClientSecret,
          {
            payment_method: response?.paymentMethodId,
          }
        );

        if (paymentIntent?.next_action?.type === "use_stripe_sdk") {
          const { error: confirmationError } =
            await stripeInstance.handleCardAction(paymentIntent.client_secret);

          if (confirmationError) {
            setMakePaymentLoading(false);
            Toast.error("Please try again later");
          } else {
            Toast.success("Payment succeeded after 3D Secure authentication");
            setMakePaymentLoading(false);
          }
        }

        if (error) {
          Toast.error("Please try again later");
          setMakePaymentLoading(false);
        } else if (paymentIntent.status === "succeeded") {
          let body = {
            amount: addBalance,
            userId: user?.id,
          };
          updateUserBalance(body);
          Toast.success("Payment succeeded after 3D Secure authentication");
          setMakePaymentLoading(false);
        }
      } else {
        Toast.error("Please try again later");
        setMakePaymentLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.error("An error occurred. Please try again later.");

      setMakePaymentLoading(false);
    }
  };

  const [paginationValue, setPaginationValue] = useState();
  const handlePagesizeChange = (pgSize) => {
    let paramValue = {
      ...paginationValue,
      pageSize: pgSize,
    };
    setPaginationValue(paramValue);
  };
  const handlePageChange = (pgNo) => {
    let paramValue = {
      ...paginationValue,
      pageNumber: pgNo,
    };
    setPaginationValue(paramValue);
  };

  return (
    <MenuSidebar>
      <PaymentModal open={open} handleClose={handleClose} />
      {profileLoading || makePaymentLoading ? (
        <Loading />
      ) : (
        <div style={{ margin: 30 }}>
          <Typography variant="h6" marginBottom={"20px"}>
            Payment Details
          </Typography>
          <Divider />
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={8}>
              <Card elevation={4} sx={{ p: 2, borderRadius: 3, height: "92%" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  User Payment History
                </Typography>
                <TableCustom
                  columns={userPaymentColumns}
                  data={user?.creditHistory}
                  handlePagesizeChange={handlePagesizeChange}
                  handlePageChange={handlePageChange}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card elevation={4} sx={{ p: 2, borderRadius: 3, height: "92%" }}>
                {user?.paymentMethod ? (
                  <>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Card Details
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={1}
                      mt={1}
                      sx={{ fontSize: 25 }}
                    >
                      <Typography>**** **** **** {user?.card}</Typography>
                      <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        size="small"
                        onClick={handleOpen}
                      >
                        Edit
                      </Button>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <InputLabel>Add balance</InputLabel>
                      <Select value={addBalance} onChange={handleChange}>
                        {pricingData.map((i) => (
                          <MenuItem key={i.priceLabel} value={i.priceLabel}>
                            {i.priceLabel}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => addBalance && makePayment()}
                      disabled={!addBalance}
                    >
                      Add Balance
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Payment Method
                    </Typography>
                    <Typography variant="body1">
                      Add a credit card to get started with DeliveryDay
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={handleOpen}
                      sx={{ mt: 2 }}
                    >
                      Add Card
                    </Button>
                  </>
                )}
                <Box mt={2}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Promo Code
                  </Typography>
                  <Typography>
                    Have a promo code? Lucky you! Enter it here.
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ mt: 1 }}
                    value={promoValue}
                    onChange={(e) => setPromoValue(e.target.value)}
                  />
                  <Button variant="contained" sx={{ mt: 1 }}>
                    Apply
                  </Button>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4} sx={{ p: 2, borderRadius: 3, mb: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  User Label History
                </Typography>
                <TableCustom
                  columns={userHistoryColumns}
                  data={user?.balanceHistory}
                  handlePagesizeChange={handlePagesizeChange}
                  handlePageChange={handlePageChange}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </MenuSidebar>
  );
};

export default Billing;
