import { Card } from "@mui/material";
import React from "react";

import Chart from "react-apexcharts";

const DashboardGraph = ({ title, graphData, totalQuantity }) => {
  const data = [
    {
      name: "amazon",
      quantity: graphData?.amazon ? graphData?.amazon : 0,
    },
    {
      name: "ebay",
      quantity: graphData?.ebay ? graphData?.ebay : 0,
    },
    {
      name: "walmart",
      quantity: graphData?.walmart ? graphData?.walmart : 0,
    },
  ];

  let names = [];
  let quantities = [];
  data.forEach(function (n) {
    names.push(n.name + ": " + n.quantity);
    quantities.push(n.quantity);
  });

  const options = {
    labels: names,
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#00AB55", "#2D99FF", "#f79009"],

    annotations: {
      total: {
        label: {
          show: true,
        },
      },
    },
  };

  return (
    <Card elevation={3} sx={{ borderRadius: "20px", p: 4 }}>
      <div style={{ fontWeight: 600, fontSize: 20, marginBottom: "20px" }}>
        {title}
      </div>
      <Chart type="donut" series={quantities} options={options} />
    </Card>
  );
};

export default DashboardGraph;
