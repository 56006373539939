// AuthContext.js
import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getMyDetails,
  postDisconnectStore,
  postUserLogin,
  postUserRegister,
  updateUserDetails,
} from "../backendHelpers/apiCallerHelper";
import { Toast } from "../components/Toaster/Toaster";
import { PlaylistAddOutlined } from "@mui/icons-material";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [disconnectStoreLoading, setDisconnectStoreLoading] = useState(false);

  const updateUser = async (data) => {
    updateUserDetails(data).then((res) => {
      Toast.success("User updated successfully");
    });
  };

  const postLoginApiCall = async (username, password) => {
    try {
      const data = {
        username,
        password,
      };
      const response = await postUserLogin(data);
      console.log(response, "response");
      window.localStorage.setItem("accessToken", response.token);
      return response;
    } catch (error) {
      Toast.error(error.response.message);
      // You might want to throw the error or handle it accordingly
    }
  };
  const getMyProfileApiCall = async (data) => {
    setProfileLoading(true);
    getMyDetails(data)
      .then((res) => {
        console.log(res, "resres");
        if (res?.user) {
          setUser(res?.user);
        }
        if (res?.message === "Invalid token") {
          Toast.error("Session expire sign in again");
          window.localStorage.clear("accessToken");
        }
      })
      .catch((err) => {
        Toast.error(err.message);
      })
      .finally(() => {
        setProfileLoading(false);
      });
  };
  const getMyProfileCompanyApiCall = async () => {
    try {
      const response = await getMyDetails();
      return response;
    } catch (error) {
      // Handle errors
      return error?.response;
    }
  };

  const postRegisterApiCall = async (values) => {
    try {
      const data = { ...values };
      const response = await postUserRegister(data);
      console.log(response, "response");
      window.localStorage.setItem("accessToken", response.token);
      return response;
    } catch (error) {
      Toast.error(error.response.message);
      // You might want to throw the error or handle it accordingly
    }
  };
  const postDisconnectStoreApiCall = async (data) => {
    setDisconnectStoreLoading(true);

    postDisconnectStore(data)
      .then((res) => {
        console.log(res);
        Toast.success(res.message);
        getMyProfileApiCall();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDisconnectStoreLoading(false);
      });
  };
  useEffect(() => {
    (async () => {
      getMyProfileApiCall();
    })();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        setUser,
        user,
        postLoginApiCall,
        postRegisterApiCall,
        getMyProfileApiCall,
        profileLoading,
        postDisconnectStoreApiCall,
        disconnectStoreLoading,
        getMyProfileCompanyApiCall,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
