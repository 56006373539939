import { useTheme } from "@emotion/react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Popper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import clsx from "clsx";
// import { Toast } from 'app/components/Toaster/Toaster';
// import { getAllProvincesList } from 'app/helpers/BackendHelper';
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const ShipAddressTo = ({
  countryList,
  control,
  errors,
  setValue,
  watch,
  getValues,
}) => {
  const [addressOrigin, setAddressOrigin] = useState("");
  const originDefault = getValues("address1To");

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const commonInputStyle = {};
  // const commonInputStyle = {
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: primary,
  //   },
  //   '&:hover > .MuiOutlinedInput-notchedOutline': {
  //     borderColor: primary,
  //   },
  // };

  const [provinceList, setProvinceList] = useState([]);
  const coutryCodeValue = watch("countryCodeTo");
  // useEffect(() => {
  //   if (coutryCodeValue) {
  //     getAllProvincesList(coutryCodeValue).then((res) => {
  //       setProvinceList(res.data.provinceList);
  //     });
  //   }
  // }, [coutryCodeValue]);

  const handleCountryChange = (code, value) => {
    setValue("countryCodeTo", code);
    setValue("provinceCodeTo", "");
    setValue("provinceTo", "");
    setValue("countryTo", value || ""); // Set an empty string if the value is null or undefined
    setValue("stateTo", ""); // Reset state when a new country is selected
  };
  const handleProvinceChange = (code) => {
    setValue("provinceCodeTo", code);
  };
  const getOptionLabelCountry = (option) => {
    const correspondingCountry = countryList.find(
      (country) => country.code === option.code
    );
    if (correspondingCountry) {
      return correspondingCountry.name;
    } else {
      return "";
    }
  };
  const getOptionLabelProvince = (option) => {
    const correspondingCountry = provinceList.find(
      (province) => province.internalCode === option.internalCode
    );
    if (correspondingCountry) {
      return correspondingCountry.name;
    } else {
      return "";
    }
  };
  const handleSelect = async (selectedAddress, fieldType) => {
    try {
      const results = await geocodeByAddress(selectedAddress);

      // Check if the address is in the USA
      const countryComponent = results[0].address_components.find(
        (component) =>
          component.types.includes("country") && component.short_name === "US"
      );

      if (!countryComponent) {
        console.log("Selected address is not in the USA");
        return;
      }

      const latLng = await getLatLng(results[0]);

      // Extract required information
      const { address_components } = results[0];
      let countryName = "";
      let city = "";
      let countryCode = "";
      let postalCode = "";
      let provinceCode = "";
      let address = "";

      console.log(address_components, "address_components");
      address_components.forEach((component) => {
        if (component.types.includes("route")) {
          address = results[0].formatted_address.split(",")[0];
          countryName = component.long_name;
        } else if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("postal_code")) {
          postalCode = component.short_name;
        } else if (component.types.includes("country")) {
          countryCode = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          // Assuming 'administrative_area_level_1' represents the province
          provinceCode = component.short_name;
        }
      });

      const addressData = {
        address,
        countryName,
        city,
        countryCode,
        postalCode,
        provinceCode,
      };
      if (
        fieldType === "address1To" ||
        fieldType === "cityTo" ||
        fieldType === "stateTo" ||
        fieldType === "countryTo" ||
        fieldType === "zipCodeTo"
      ) {
        setValue("address1To", addressData?.countryName);
        setValue("cityTo", addressData?.city);
        setValue("stateTo", addressData?.provinceCode);
        setValue("countryTo", addressData?.countryCode);
        setValue("zipCodeTo", addressData?.postalCode);
        setAddressOrigin(address);
      }
    } catch (error) {
      // Handle error here
      console.error("Error occurred while handling select:", error);
    }
  };
  useEffect(() => {
    document.getElementById("address1From").value = "";
  }, []);
  const [formattedPhone, setFormattedPhone] = useState("");

  // Function to format phone number to US format
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from input
    const cleaned = phoneNumber.replace(/\D/g, "");
    // Format the number to (XXX) XXX-XXXX
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formatted;
  };

  return (
    // <Card>
    <Card sx={{ overflow: "visible" }}>
      <Grid sx={{ p: 2 }} spacing={2} container>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          md={12}
          xs={12}
        >
          <Typography style={{ fontWeight: 600, width: "100%" }}>
            Destination Address
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Divider />
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-name">Name</InputLabel>
            <Controller
              control={control}
              name="nameTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="nameTo"
                    name="nameTo"
                    label="Name"
                    error={!!errors.nameTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.nameTo && (
              <ErrorLabel>{errors?.nameTo?.message}</ErrorLabel>
            )}
          </FormControl>{" "}
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-company">Company</InputLabel>
            <Controller
              control={control}
              name="companyTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="companyTo"
                    name="companyTo"
                    label="Company"
                    error={!!errors.companyTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.companyTo && (
              <ErrorLabel>{errors?.companyTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <FormControl fullWidth>
            <div style={{ position: "relative" }}>
              <PlacesAutocomplete
                value={addressOrigin}
                onChange={setAddressOrigin}
                onSelect={(selectedAddress) =>
                  handleSelect(selectedAddress, "address1To")
                }
                searchOptions={{
                  types: ["address"],
                  componentRestrictions: { country: "US" },
                }}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <TextField
                      id="address1To"
                      name="address1To"
                      label="Start typing in your address"
                      {...getInputProps()}
                      autoComplete="new-address1"
                      fullWidth
                    />
                    <input
                      type="text"
                      name="fakeAddress1"
                      style={{ display: "none" }}
                      autoComplete="new-address1"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        zIndex: 100,
                        width: "100%",
                      }}
                    >
                      {suggestions.length > 0 && (
                        <div
                          style={{
                            background: "#fff",
                            padding: "10px",
                            paddingTop: "0px",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            marginTop: "2px",
                          }}
                        >
                          {suggestions.map((suggestion) => (
                            <div
                              key={suggestion.id}
                              {...getSuggestionItemProps(suggestion)}
                              style={{
                                color: "#333",
                                padding: 10,
                                borderRadius: 5,
                                marginTop: 5,
                                cursor: "pointer",
                                transition: "background-color 0.3s ease",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#89edbd";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#fff";
                              }}
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            {errors.address1From && !addressOrigin && (
              <ErrorLabel>{errors.address1From.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-address2">Address2</InputLabel>
            <Controller
              control={control}
              name="address2To"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="address2To"
                    name="address2To"
                    label="Address2"
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-city">Country</InputLabel>
            <Controller
              control={control}
              name="countryTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="countryTo"
                    name="countryTo"
                    label="Country"
                    // autoComplete="of"
                    error={!!errors.countryTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.countryTo && (
              <ErrorLabel>{errors?.countryTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-city">State</InputLabel>
            <Controller
              control={control}
              name="stateTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="stateTo"
                    name="stateTo"
                    label="State"
                    // autoComplete="of"
                    error={!!errors.stateTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.stateTo && (
              <ErrorLabel>{errors?.stateTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-city">City</InputLabel>
            <Controller
              control={control}
              name="cityTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="cityTo"
                    name="cityTo"
                    label="City"
                    error={!!errors.cityTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.cityTo && (
              <ErrorLabel>{errors?.cityTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-zipCodeTo">Postal/Zip Code</InputLabel>
            <Controller
              control={control}
              name="zipCodeTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="zipCodeTo"
                    name="zipCodeTo"
                    label="Postal/Zip Code"
                    error={!!errors.zipCodeTo}
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"text"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
            {errors.zipCodeTo && (
              <ErrorLabel>{errors?.zipCodeTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="label-email">Email</InputLabel>
            <Controller
              control={control}
              name="emailTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <OutlinedInput
                    id="emailTo"
                    name="emailTo"
                    label="Email"
                    value={value ? value : ""}
                    onChange={(event, val) => {
                      return onChange(event.target.value);
                    }}
                    type={"email"}
                    sx={commonInputStyle}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="phoneTo">Phone</InputLabel>
            <Controller
              control={control}
              name="phoneTo"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                // Validate if the number of digits entered is less than 10
                const showError =
                  value && value.replace(/\D/g, "").length !== 10;
                // Apply formatting only if 10 digits are entered
                const formattedValue = showError
                  ? value
                  : formatPhoneNumber(value);
                return (
                  <OutlinedInput
                    id="phoneTo"
                    name="phoneTo"
                    label="phoneTo"
                    error={showError}
                    value={formattedValue}
                    onChange={(event) => {
                      const formatted = formatPhoneNumber(event.target.value);
                      setFormattedPhone(formatted);
                      onChange(event.target.value);
                    }}
                    type={"tel"} // Use type "tel" for phone number input
                  />
                );
              }}
            />
            {errors.phoneTo && (
              <ErrorLabel>{errors?.phoneTo?.message}</ErrorLabel>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShipAddressTo;

const ErrorLabel = styled("span")(() => ({
  color: "red",
  margin: "5px 0px 0px 5px",
}));
