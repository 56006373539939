import React, { useState } from "react";
import MenuSidebar from "../../../components/MenuSideBar";

import styled from "styled-components";
import { Box, Button, Card } from "@mui/material";
import CustomTable from "./shared/CustomTable";
import { useDashboardContext } from "../../../contexts/DashboardContext/DashboardContext";
import { useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import CountryFlag from "../../../components/CountryFlag";
import BatchModal from "../../batch-modal/BatchModal";
const data = [
  {
    tracking: "1324171354",
    address:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut, soluta?",
    amount: "45",
    date: "2024-01-06T21:38:21.256Z",
  },
  {
    tracking: "1324171354",
    address:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut, soluta?",
    amount: "45",
    date: "2024-01-06T21:38:21.256Z",
  },
];

const Shipment = () => {
  const {
    getAllQuickShipApiCall,
    allQuickShip,
    setPdfValue,
    allQuickShipLoading,
  } = useDashboardContext();
  const [displayPopup, setDisplayPopup] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    getAllQuickShipApiCall(user?.id);
  }, [user?.id]);
  console.log(allQuickShip, "allQuickShip");
  const handlePdf = (label) => {
    setDisplayPopup(true);
    setPdfValue(label);
  };
  const storesColumn = [
    {
      id: 1,
      name: (
        <Box style={{ marginLeft: 20, fontSize: "20px" }} className="text-wrap">
          Tracking Id
        </Box>
      ),
      // width: "250px",
      cell: (row) => {
        return <div style={{ marginLeft: "20px" }}>{row?.trackingNumber}</div>;
      },
      sortable: false,
      // center: true,
    },
    {
      id: 2,
      name: (
        <Box style={{ fontSize: "20px" }} className="text-wrap">
          Shipping Address
        </Box>
      ),
      // width: '75%',
      cell: (row) => {
        const addressData = row?.shippingAddress;

        return (
          <Box sx={{ display: "flex", alignItems: "center", my:1 }}>
            <div>
              <CountryFlag countryCode={addressData?.countryCode} />
            </div>
            <div style={{ marginLeft: 10 }} className="">
              <div style={{ fontSize: "15px", fontWeight: 600 }}>
                {addressData?.name}
              </div>
              <div style={{ fontSize: "14px", fontWeight: 500 }}>
                {addressData?.company}
              </div>
              <div className="">
                {addressData?.addressLine1},{addressData?.city},
                {addressData?.stateOrRegion},{addressData?.postalCode},
                {addressData?.countryCode}
              </div>
              {addressData?.phoneNumber}
            </div>
          </Box>
        );
      },
      sortable: false,
      // center: true,
    },
    {
      id: 3,
      name: (
        <Box style={{ fontSize: "20px" }} className="text-wrap">
          Amount
        </Box>
      ),
      // width: "200px",
      cell: (row) => {
        return <span>${row?.amount}</span>;
      },
      sortable: false,
    },
    {
      id: 4,
      name: (
        <Box style={{ fontSize: "20px" }} className="text-wrap">
          Status
        </Box>
      ),
      // width: "200px",
      cell: (row) => {
        return <span>{row?.status}</span>;
      },
      sortable: false,
    },
    {
      id: 5,
      name: (
        <Box style={{ fontSize: "20px" }} className="text-wrap">
          Date
        </Box>
      ),
      cell: (row) => <Box sx={{ marginY: "1px" }}>{row?.createdAt}</Box>,
      sortable: false,
    },
    {
      id: 6,
      name: (
        <Box style={{ fontSize: "20px" }} className="text-wrap">
          Action
        </Box>
      ),
      cell: (row) => (
        <Box sx={{ marginY: "1px" }}>
          {" "}
          <Box style={{ fontSize: "20px" }} className="text-wrap">
            <Button
              label={"View order"}
              sx={{ width: "120px" }}
              variant="contained"
              onClick={() => handlePdf(row.label)}
            >
              View
            </Button>
          </Box>
        </Box>
      ),
      sortable: false,
    },
  ];
  return (
    <div>
      <MenuSidebar>
        <Box sx={{ ml: 3, mt: 2 }} display={"flex"} alignItems={"center"}>
          <QuickShipTitle>Shipments</QuickShipTitle>
        </Box>
        <Card elevation={4} sx={{ mb: 3, m: 3 }}>
          <Box sx={{ ml: 3, mt: 2 }} display={"flex"} alignItems={"center"}>
            <QuickShipTitle>Shipment Table</QuickShipTitle>
          </Box>
          <Box overflow="auto">
            <CustomTable
              columns={storesColumn}
              data={allQuickShip}
              isLoading={allQuickShipLoading}
            />
          </Box>
        </Card>
      </MenuSidebar>
      {displayPopup && (
        <BatchModal
          displayPopup={displayPopup}
          setDisplayPopup={setDisplayPopup}
        />
      )}
    </div>
  );
};

export default Shipment;
const QuickShipTitle = styled("h3")(() => ({
  fontSize: "20px",
  margin: "0px 0px 0px 0px",
}));
