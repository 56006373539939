import React, { useEffect } from "react";
import {
  Divider,
  styled,
  Box,
  Modal,
  // Icon,
  TextField,
  // Autocomplete,
  Button,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../contexts/AuthContext";
import MenuSidebar from "../../../components/MenuSideBar";
import { useLocation } from "react-router-dom";

import axios from "axios";
import Loading from "../../../components/MatxLoading";
import { APIBaseURL, BASE_URL } from "../../../../common";
import { Toast } from "../../../components/Toaster/Toaster";

const MainStoreConatiner = styled("div")(() => ({
  padding: " 30px",
  "@media (max-width: 768px)": {
    padding: "20px",
  },
}));

const MainDescription = styled("h1")(() => ({
  marginRight: ".5rem",
  // margin: "10px 0px 10px 10px",
  color: "gray",
  fontSize: "16px",
}));

const StyledButton = styled("button")(({ theme }) => ({
  borderRadius: "10px",
  color: "white",
  width: "140px",
  height: "50px",
  background: "#89edbd",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "none",
  fontSize: 15,
  fontWeight: "bold",
}));
const AmazonContainer = styled("div")(() => ({
  border: "1px solid red",
}));
const StoreDiv = styled("div")(() => ({
  // border: "1px solid red",
}));

const DisconnectReConatiner = styled("div")(() => ({
  height: "100%",
  // display: "flex",
  // marginLeft: "20px",
  // justifyContent: "center",
  // alignItems: "center",
  border: "1px solid green",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};
const Title = styled("div")(() => ({
  color: "gray",
  fontSize: "20px",
}));
const Description = styled("div")(() => ({
  color: "gray",
  fontSize: "18px",
}));
const Feauter = styled("div")(() => ({
  color: "gray",
  fontSize: "16px",
  marginTop: "15px",
}));
const MainModalConatiner = styled("div")(() => ({
  margin: "0px 0px 0px 0px",
}));
const FeauterPlatformsInner = styled("div")(() => ({
  margin: "20px 20px 20px 20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
}));

const FeauterPlatformsImg = styled("div")(() => ({
  margin: "10px 10px 10px 10px",
  width: "90%",
  height: "100%",
  borderRadius: "50px",
  border: "1px solid lightblue",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    margin: "10px 0",
  },
}));
const StoreName = styled("div")(() => ({
  paddingLeft: "10px",
  paddingRight: "10px",
  marginTop: "20px",
  fontSize: "16px",
  width: "100%",
  color: "black",
  "@media (max-width: 768px)": {
    textAlign: "center",
  },
}));
const Input = styled("div")(() => ({
  paddingLeft: "10px",
  fontSize: "13px",
  "@media (max-width: 768px)": {
    textAlign: "center",
  },
}));

const marketPlace = [
  {
    id: "A1F83G8C2ARO7P",
    name: "UK",

    orderindex: 0,
    key: "Amazon UK   ",
  },
  {
    id: "A1PA6795UKMFR9",
    name: "DE",

    orderindex: 1,
    key: "Amazon DE   ",
  },
  {
    id: "A13V1IB3VIYZZH",
    name: "FR",

    orderindex: 2,
    key: "Amazon FR   ",
  },
  {
    id: "APJ6JRA9NG5V4",
    name: "IT",

    orderindex: 3,
    key: "Amazon IT   ",
  },
  {
    id: "A1RKKUPIHCS9HS",
    name: "ES",

    orderindex: 4,
    key: "Amazon ES   ",
  },
  {
    id: "A1805IZSGTT6HS",
    name: "NL",

    orderindex: 5,
    key: "Amazon NL   ",
  },
  {
    id: "A2NODRKZP88ZB9",
    name: "SE",

    orderindex: 6,
    key: "Amazon SE   ",
  },
  {
    id: "A1C3SOZRARQ6R3",
    name: "PL",

    orderindex: 7,
    key: "Amazon PL   ",
  },
  {
    id: "AMEN7PMS3EDWL",
    name: "BE",

    orderindex: 8,
    key: "Amazon BE   ",
  },
  {
    id: "A33AVAJ2PDY3EV",
    name: "TR",

    orderindex: 9,
    key: "Amazon TR   ",
  },
  {
    id: "A2EUQ1WTGCTBG2",
    name: "CA",

    orderindex: 10,
    key: "Amazon CA   ",
  },
  {
    id: "ATVPDKIKX0DER",
    name: "US",

    orderindex: 10,
    key: "Amazon US   ",
  },
  {
    id: "A1AM78C64UM0Y8",
    name: "MX",

    orderindex: 11,
    key: "Amazon MX  ",
  },
  {
    id: "A2Q3Y263D00KWC",
    name: "BR",

    orderindex: 12,
    key: "Amazon BR   ",
  },
];
const ebayMarketPlace = [
  {
    id: "A1F83G8C2ARO7P",
    name: "EBAY-US",
    orderindex: 0,
    key: "eBay United States",
  },
  {
    id: "A1PA6795UKMFR9",
    name: "EBAY-DE",
    orderindex: 1,
    key: "eBay Germany",
  },
  {
    id: "A13V1IB3VIYZZH",
    name: "EBAY-FR",
    orderindex: 2,
    key: "eBay France",
  },
  {
    id: "APJ6JRA9NG5V4",
    name: "EBAY-IT",
    orderindex: 3,
    key: "eBay Italy",
  },
  {
    id: "A1RKKUPIHCS9HS",
    name: "EBAY-ES",
    orderindex: 4,
    key: "eBay Spain",
  },
  {
    id: "A1805IZSGTT6HS",
    name: "EBAY-NL",
    orderindex: 5,
    key: "eBay Netherlands",
  },
  {
    id: "A2NODRKZP88ZB9",
    name: "EBAY-SE",
    orderindex: 6,
    key: "eBay Sweden",
  },
  {
    id: "A1C3SOZRARQ6R3",
    name: "EBAY-PL",
    orderindex: 7,
    key: "eBay Poland",
  },
  {
    id: "AMEN7PMS3EDWL",
    name: "EBAY-BE",
    orderindex: 8,
    key: "eBay Belgium",
  },
  {
    id: "A33AVAJ2PDY3EV",
    name: "EBAY-TR",
    orderindex: 9,
    key: "eBay Turkey",
  },
  {
    id: "A2EUQ1WTGCTBG2",
    name: "EBAY-CA",
    orderindex: 10,
    key: "eBay Canada",
  },
  {
    id: "ATVPDKIKX0DER",
    name: "EBAY-US",
    orderindex: 10,
    key: "eBay United States",
  },
  {
    id: "A1AM78C64UM0Y8",
    name: "EBAY-MX",
    orderindex: 11,
    key: "eBay Mexico",
  },
  {
    id: "A2Q3Y263D00KWC",
    name: "EBAY-BR",
    orderindex: 12,
    key: "eBay Brazil",
  },
];

const Stores = () => {
  const innerWidth = useMediaQuery("(max-width:760px)");
  const ebayUrl =
    "https://auth.ebay.com/oauth2/authorize?client_id=BitsBay-delevery-PRD-9f48985ae-96512535&response_type=code&redirect_uri=BitsBay-BitsBay-delever-cuqvwnim&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/sell.reputation https://api.ebay.com/oauth/api_scope/sell.reputation.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly https://api.ebay.com/oauth/api_scope/sell.stores https://api.ebay.com/oauth/api_scope/sell.stores.readonly";

  const amzURL =
    "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.b22560c5-0b6d-4407-9e39-c5b5ffb8473d&state=stateexample&version=beta";

  const {
    user,
    myProfile,
    postDisconnectStoreApiCall,
    disconnectStoreLoading,
    profileLoading,
  } = useAuth();
  console.log(user, "in store");

  const [modalType, setModalType] = React.useState(null);
  const [selectedMarketplaceKey, setSelectedMarketplaceKey] =
    React.useState("");
  const [ebayMarketplace, setEbayMarkeplace] = React.useState("");
  // const navigate = useNavigation()

  const [storeName, setStoreName] = React.useState("");
  const [ebayStore, setEbayStore] = React.useState("");
  const [storesName, setStoresName] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [clientSecret, setClientSecret] = React.useState("");
  const [jsonData, setJsonData] = React.useState("");

  const handleOpen = (type) => setModalType(type);
  const handleClose = () => setModalType(null);

  const handleMarketplaceChange = (event) => {
    setSelectedMarketplaceKey(event.target.value);
  };
  const handleEbayMarketplaceChange = (event) => {
    setEbayMarkeplace(event.target.value);
  };

  const handleStoreNameChange = (event) => {
    setStoreName(event.target.value);
  };
  const handleeBayStoreNameChange = (event) => {
    setEbayStore(event.target.value);
  };
  const handleClientIdChange = (event) => {
    setClientId(event.target.value);
  };
  const handleClientSecretChange = (event) => {
    setClientSecret(event.target.value);
  };
  const handleStoresNameChange = (event) => {
    setStoresName(event.target.value);
  };

  // const ebayData = {
  //   storesName: storesName,
  //   clientId: clientId,
  //   clientSecret: clientSecret,
  // };
  const accessToken = window.localStorage.getItem("accessToken");

  const handleSaveButton = () => {
    let data = {
      walmart: true,
      walmartKeys: {
        storesName: storesName,
        client_key: clientId,
        secret_key: clientSecret,
      },
    };
    console.log(data, accessToken);

    const updateKeys = async (body, token) => {
      try {
        console.log(body, token, "data");

        let userURL = `${APIBaseURL}/api/v1/users/userId`;

        axios
          .put(userURL, body, {
            headers: {
              Authorization: token,
              "ngrok-skip-browser-warning": "69420",
            },
          })
          .then((res) => {
            console.log(res.data);
            handleClose();
            Toast.success("Store connected successfully!");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            // setDashboardLoading(false);
            myProfile(token);
            console.log("done");
          });
      } catch (err) {
        console.log(err);
      }
    };
    updateKeys(data, accessToken);
  };

  const handleEbaySubmit = (event) => {
    event.preventDefault();

    const selectedMarketplace = ebayMarketPlace.find(
      (item) => item.name === ebayMarketplace
    );

    const newJsonData = {
      storeName: ebayStore,
      selectedMarketplace: selectedMarketplace,
    };
    window.localStorage.setItem("ebay", JSON.stringify(newJsonData));

    console.log("newJsonData:", newJsonData);

    setJsonData(newJsonData);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const selectedMarketplace = marketPlace.find(
      (item) => item.key === selectedMarketplaceKey
    );

    const newJsonData = {
      storeName: storeName,
      selectedMarketplace: selectedMarketplace,
    };
    window.localStorage.setItem("amz", JSON.stringify(newJsonData));

    console.log("newJsonData:", newJsonData);

    setJsonData(newJsonData);
  };

  const hanldeSaveEbay = () => {
    window.open(ebayUrl, "_blank");
    console.log(jsonData, "jsonData");
  };
  const hanldeSaveAmazon = () => {
    window.open(amzURL, "_blank");
    console.log(jsonData, "jsonData");
  };
  const handleDisconnect = (id, type) => {
    const data = {
      userId: id,
      storeType: type,
    };
    // postDisconnectStoreApiCall(data);
  };
  let location = useLocation();

  useEffect(() => {
    // Get the current URL
    let currentURL = location.pathname + location.search;
    let code = location.search;
    console.log(currentURL);
    const urlParams = new URLSearchParams(location.search);
    const eCode = urlParams.get("code");
    const amzCode = urlParams.get("spapi_oauth_code");

    if (eCode) {
      const decodedCode = decodeURIComponent(eCode);
      console.log("Decoded code:", decodedCode);

      axios
        .get(`${APIBaseURL}/auth/ebay`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
          params: {
            code: decodedCode,
          },
        })
        .then(async (res) => {
          // console.log( JSON.parse(res.data.token).refresh_token)
          let ebayData = window.localStorage.getItem("ebay");
          let ebayFinalData = JSON.parse(ebayData);
          // console.log(JSON.parse(amzData), "amzData");
          let userURL = `${BASE_URL}users/userId`;
          let body = {
            ebay_refreshToken: {
              marketplace: ebayFinalData?.selectedMarketplace?.name,
              refresh_token: JSON.parse(res.data.token).refresh_token,
            },
            ebay: true,
          };
          console.log(body, "hello");

          axios
            .put(userURL, body, {
              headers: {
                Authorization: accessToken,
                "ngrok-skip-browser-warning": "69420",
              },
            })
            .then((res1) => {
              console.log(res1.data);
              myProfile(accessToken);

              // navigate('https://dd90-2400-adc7-929-5800-c54-1d5f-1e9a-5e08.ngrok-free.app/setting/store')
              console.log("done");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              window.location.href =
                "https://app.deleveryday.com/setting/store";
            });
        });

      // Now you can use the 'decodedCode' value in your React component state or perform other actions
    } else if (amzCode) {
      axios
        .get(`${APIBaseURL}/auth/amazon`, {
          params: {
            region: "na",
            code: amzCode,
          },
        })
        .then(async (res) => {
          let amzData = window.localStorage.getItem("amz");
          let amzFinalData = JSON.parse(amzData);
          console.log(JSON.parse(amzData), "amzData");
          let userURL = `${BASE_URL}users/userId`;
          let body = {
            amazon_refreshToken: {
              marketplace: [amzFinalData.selectedMarketplace],
              refreshToken: res.data.token,
            },
            amazon: true,
          };

          axios
            .put(userURL, body, {
              headers: {
                Authorization: accessToken,
                "ngrok-skip-browser-warning": "69420",
              },
            })
            .then((res1) => {
              console.log(res1.data);
              myProfile(accessToken);
              window.location.href =
                "https://app.deleveryday.com/setting/store";
              // navigate('https://dd90-2400-adc7-929-5800-c54-1d5f-1e9a-5e08.ngrok-free.app/setting/store')
              console.log("done");
            })
            .catch((err) => {
              console.log(err);
            });
        });

      console.log(amzCode);
    } else {
      console.log("Code parameter not found in the URL.");
    }

    // const currentUrl = window.location.href;

    // // Parse the URL and extract the 'code' parameter
    // const urlParams = new URLSearchParams(currentUrl);
    // console.log(currentUrl)
    // const code = urlParams.get('code');
    // if (code) {
    //   // If 'code' is not null or undefined
    //   const decodedCode = decodeURIComponent(code);
    //   console.log('Extracted code:', decodedCode);

    //   // Now you can use the 'decodedCode' value in your React component state or perform other actions
    // } else {
    //   console.log('Code parameter not found in the URL.');
    // }

    console.log("Extracted code:", currentURL, code.code);

    // Now you can use the 'code' value in your React component state or perform other actions
  }, []);

  return (
    <>
      <MenuSidebar>
        {disconnectStoreLoading || profileLoading ? (
          <Loading />
        ) : (
          <MainStoreConatiner>
            {modalType === "amazonStore" && (
              <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <MainModalConatiner>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div style={{}}>
                      <form onSubmit={handleSubmit}>
                        <StoreName>Store Name</StoreName>
                        <Input>
                          <TextField
                            fullWidth
                            name="store"
                            type="text"
                            size="small"
                            value={storeName}
                            onChange={handleStoreNameChange}
                            sx={{
                              width: "100%",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                            }}
                          />
                        </Input>
                        <StoreName>Marketplace</StoreName>
                        <Input>
                          <select
                            value={selectedMarketplaceKey}
                            onChange={handleMarketplaceChange}
                            style={{
                              width: "697px",
                              marginBottom: "25px",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                              borderRadius: "4px",
                              height: "46px",
                              fontSize: "14px",
                              padding: "6px",
                              backgroundPosition: "right 8px center",
                              backgroundRepeat: "no-repeat",
                              paddingRight: "25px", // Adjust for the arrow icon
                            }}
                          >
                            <option value="">Select a Marketplace</option>
                            {marketPlace.map((item) => (
                              <option key={item.id} value={item.key}>
                                {item.key}
                              </option>
                            ))}
                          </select>
                          <StyledButton onClick={hanldeSaveAmazon}>
                            Submit
                          </StyledButton>

                          {/* <StyledButton>Submit</StyledButton> */}
                        </Input>
                      </form>
                    </div>
                  </MainModalConatiner>
                </Box>
              </Modal>
            )}
            {modalType === "ebayStore" && (
              <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <MainModalConatiner>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div style={{}}>
                      <form onSubmit={handleEbaySubmit}>
                        <StoreName>Store Name</StoreName>
                        <Input>
                          <TextField
                            fullWidth
                            name="store"
                            type="text"
                            size="small"
                            value={ebayStore}
                            onChange={handleeBayStoreNameChange}
                            sx={{
                              width: "100%",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                            }}
                          />
                        </Input>
                        <StoreName>Marketplace</StoreName>
                        <Input>
                          <select
                            value={ebayMarketplace}
                            onChange={handleEbayMarketplaceChange}
                            style={{
                              width: "697px",
                              marginBottom: "25px",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                              borderRadius: "4px",
                              height: "46px",
                              fontSize: "14px",
                              padding: "6px",
                              backgroundPosition: "right 8px center",
                              backgroundRepeat: "no-repeat",
                              paddingRight: "25px", // Adjust for the arrow icon
                            }}
                          >
                            <option value="">Select a Marketplace</option>
                            {ebayMarketPlace.map((item) => (
                              <option key={item.id} value={item.name}>
                                {item.key}
                              </option>
                            ))}
                          </select>
                          <StyledButton onClick={hanldeSaveEbay}>
                            Submit
                          </StyledButton>

                          {/* <StyledButton>Submit</StyledButton> */}
                        </Input>
                      </form>
                    </div>
                  </MainModalConatiner>
                </Box>
              </Modal>
            )}
            {modalType === "walmartStore" && (
              <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <MainModalConatiner>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div style={{}}>
                      <form onSubmit={handleSubmit}>
                        <StoreName>Store Name</StoreName>
                        <Input>
                          <TextField
                            fullWidth
                            name="store"
                            type="text"
                            size="small"
                            value={storesName}
                            onChange={handleStoresNameChange}
                            sx={{
                              width: "100%",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                            }}
                          />
                        </Input>
                        <StoreName>Client Id</StoreName>
                        <Input>
                          <TextField
                            fullWidth
                            name="client_id"
                            type="text"
                            size="small"
                            value={clientId}
                            onChange={handleClientIdChange}
                            sx={{
                              width: "100%",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                            }}
                          />

                          {/* <StyledButton>Submit</StyledButton> */}
                        </Input>
                        <StoreName>Client secret</StoreName>
                        <Input>
                          <TextField
                            fullWidth
                            name="client_secret"
                            type="text"
                            size="small"
                            value={clientSecret}
                            onChange={handleClientSecretChange}
                            sx={{
                              width: "100%",
                              height: "46px",
                              // border: "none",
                              "& fieldset": { border: "none" },
                              border: "1px solid lightBlue",
                            }}
                          />

                          {/* <StyledButton>Submit</StyledButton> */}
                        </Input>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingTop: 20,
                          }}
                        >
                          <StyledButton onClick={handleSaveButton}>
                            Submit
                          </StyledButton>
                        </div>
                      </form>
                    </div>
                  </MainModalConatiner>
                </Box>
              </Modal>
            )}

            {modalType === "connectStore" && (
              <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box style={{ width: "90%" }} sx={style}>
                  <MainModalConatiner>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <Title>Connect Your Store</Title>
                    <Description>
                      Select your store's e-commerce platform to connect and
                      import orders
                    </Description>
                    <Feauter>Featured Platforms</Feauter>
                    <FeauterPlatformsInner>
                      <FeauterPlatformsImg
                        style={
                          user?.ebay
                            ? { cursor: "not-allowed" }
                            : { cursor: "pointer" }
                        }
                      >
                        <a
                        // href={ebayUrl}
                        >
                          <img
                            width="80px"
                            height="80px"
                            src="https://apps.goshippo.com/f80ed9ca7a73728b85f5b500be1fcb9a.svg"
                            onClick={() => handleOpen("ebayStore")}
                          />
                        </a>
                      </FeauterPlatformsImg>
                      <FeauterPlatformsImg
                        style={
                          user?.amazon
                            ? { cursor: "not-allowed" }
                            : { cursor: "pointer" }
                        }
                      >
                        <a style={{ alignItems: "center" }}>
                          <img
                            width="80px"
                            height="80px"
                            src=" https://apps.goshippo.com/aaef143a338d4cd0b464157240c78f5c.svg"
                            onClick={() => handleOpen("amazonStore")}
                          />
                        </a>
                      </FeauterPlatformsImg>
                      <FeauterPlatformsImg
                        style={
                          user?.walmart
                            ? { cursor: "not-allowed" }
                            : { cursor: "pointer" }
                        }
                      >
                        <a style={{}}>
                          <img
                            width="80px"
                            height="80px"
                            src=" https://apps.goshippo.com/ea7219152122a68507577626b9882e51.svg"
                            onClick={() => handleOpen("walmartStore")}
                          />
                        </a>
                      </FeauterPlatformsImg>
                    </FeauterPlatformsInner>
                  </MainModalConatiner>
                </Box>
              </Modal>
            )}
            <Grid
              container
              spacing={3}
              sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                alignItem: "center",
                // border: "1px solid red",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <h4>Stores</h4>
                  <p>Connect and manage your e-commerce stores</p>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: innerWidth ? "flex-start" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleOpen("connectStore")}
                  >
                    + Connect Store
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              style={{
                width: "100%",
                marginBottom: 7,
                marginTop: 7,

                display: "flex",
              }}
            />
            <div
              style={{
                display: "flex",

                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: innerWidth ? "100%" : "90%",
                }}
              >
                {/* ----------------------------------------------------------- Amzaon */}
                {user?.amazon && (
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid purple",
                    }}
                    // container
                    // spacing={3}
                  >
                    <StoreDiv>
                      <img
                        height={"20%"}
                        src="https://apps.goshippo.com/aaef143a338d4cd0b464157240c78f5c.svg"
                      />
                    </StoreDiv>
                    <StoreDiv>
                      <Button
                        onClick={() => handleDisconnect(user?.id, "amazon")}
                        variant="contained"
                        width={innerWidth ? "80px" : "100px"}
                        sx={{ backgroundColor: "red" }}
                      >
                        Disconnect
                      </Button>
                    </StoreDiv>
                  </Grid>
                )}
                {user?.amazon && (
                  <Grid item xs={12}>
                    <Divider
                      style={{
                        width: "100%",
                        marginBottom: 7,
                        marginTop: 7,
                        display: "flex",
                      }}
                    />
                  </Grid>
                )}
                {/* ------------------------------------------------------- Ebay */}
                {user?.ebay && (
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid purple",
                      padding: "0px",
                    }}
                    spacing={3}
                  >
                    <StoreDiv>
                      <img
                        height={"20%"}
                        src={require("../../../../assets/images/ebay_logo.png")}
                      />
                    </StoreDiv>
                    <StoreDiv>
                      <Button
                        onClick={() => handleDisconnect(user?.id, "ebay")}
                        variant="contained"
                        width={innerWidth ? "80px" : "100px"}
                        sx={{ backgroundColor: "red" }}
                      >
                        Disconnect
                      </Button>
                    </StoreDiv>
                  </Grid>
                )}
                {user?.ebay && (
                  <Grid item xs={12}>
                    <Divider
                      style={{
                        width: "100%",
                        marginBottom: 7,
                        marginTop: 7,
                        display: "flex",
                      }}
                    />
                  </Grid>
                )}
                {/* // ------------------------------------------- Walmart */}
                {user?.walmart && (
                  <Grid
                    sx={{
                      width: "100%",
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // border: "1px solid purple",
                      height: "100%",
                    }}
                    container
                    spacing={3}
                  >
                    <StoreDiv>
                      <img
                        height={"20%"}
                        src="https://apps.goshippo.com/ea7219152122a68507577626b9882e51.svg"
                      />
                    </StoreDiv>
                    <StoreDiv>
                      <Button
                        onClick={() => handleDisconnect(user?.id, "walmart")}
                        variant="contained"
                        width={innerWidth ? "80px" : "100px"}
                        sx={{ backgroundColor: "red" }}
                      >
                        Disconnect
                      </Button>
                    </StoreDiv>
                  </Grid>
                )}{" "}
              </div>{" "}
            </div>
          </MainStoreConatiner>
        )}
      </MenuSidebar>
    </>
  );
};

export default Stores;
