import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { NavLink, useParams } from "react-router-dom";
import logo from "../../../assets/images/DeleveryDayLogo.png";
import * as Yup from "yup";
import { BASE_URL } from "../../../common";

const initialValues = {
  newPassword: "",
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required("New password is required"),
});

const Reset = () => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  const handleFormSubmit = async (values) => {
    setLoading(true);

    const url = `${BASE_URL}user/reset-password`;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };

    const body = JSON.stringify({
      password: values.newPassword,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (response.ok) {
        console.log("Your password is successfully changed");
        setLoading(false);
      } else {
        console.error("Failed to change password");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#e9f1f5",
      }}
      container
    >
      <Grid item md={3}>
        <Card sx={{ textAlign: "center", m: 2, borderRadius: 4 }} elevation={4}>
          <div style={{ height: 150, marginTop: -55 }}>
            <img src={logo} alt="Logo" width={200} />
          </div>
          <Grid>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      name="newPassword"
                      type="password"
                      label="Enter your new Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.newPassword}
                      onChange={handleChange}
                      helperText={touched.newPassword && errors.newPassword}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      sx={{ mb: 2 }}
                    />

                    <LoadingButton
                      type="submit"
                      loading={loading}
                      variant="contained"
                      sx={{
                        my: 2,
                        backgroundColor: "#89edbd",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                    >
                      Confirm
                    </LoadingButton>
                    <div>
                      Go back to
                      <NavLink
                        to="/"
                        style={{
                          color: "#89edbd",
                          marginLeft: 5,
                        }}
                      >
                        Login
                      </NavLink>
                    </div>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Reset;
