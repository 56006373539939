import { createContext, useContext, useState } from "react";
import moment from "moment";
import axios from "axios";
import {
  addBalanceClient,
  addCustomerDetails,
  getAllOrders,
  getAllQuickShip,
  getOrderDetails,
  getRatesData,
  postAdminCreateLabel,
  postBulkDeleteOrderItem,
  postCreateLabel,
  postSyncOrders,
  postUpdateOrderStatus,
  postUpdateTackingNumber,
  updateBalace,
  postQuickShipRates,
  postQuickShipShipment,
  getDynamicRates,
} from "../../backendHelpers/apiCallerHelper";
import { Toast } from "../../components/Toaster/Toaster";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
const DashboardContext = createContext();

const useDashboardContext = () => {
  return useContext(DashboardContext);
};

const DashboardContextProvider = ({ children }) => {
  const { user, getMyProfileApiCall, setUser } = useAuth();
  const navigate = useNavigate();
  const start = moment()
    .subtract(7, "days")
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DD HH:mm:ss");

  let end = moment(new Date())
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DD HH:mm:ss");

  const initialOrderFilterState = {
    startDate: start,
    endDate: end,
    search: "",
  };
  const [filterOrderData, setFilterOrderData] = useState(
    initialOrderFilterState
  );
  const [stateDateMulti, setStateDateMulti] = useState({
    startDate: new Date(start),
    endDate: new Date(end),
    key: "selection",
  });
  const [dateRangeType, setDateRangeType] = useState("thisWeek");
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [labelPDF, setLabelPDF] = useState(null);
  const [pdfValue, setPdfValue] = useState();
  const [selectedValue, setSelectedValue] = useState("amazon");

  const [dashboardStatastics, setDashboardStatastics] = useState(null);
  const [franchiseLoading, setFranchiseLoading] = useState(null);
  const [createLabelLoading, setCreateLabelLoading] = useState(false);
  const [orderDetailsLoading, setOrderDetailsLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [amzAllOrderDetails, setAmzAllOrderDetails] = useState(null);
  const [ebayOrderDetails, setEbayOrderDetails] = useState(null);
  const [walmartOrderDetails, setWalmartOrderDetails] = useState(null);
  const [openPopUpRates, setOpenPopUpRates] = useState(false);

  const [allOrders, setOrders] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [updatingTrackingLoading, setUpdatingTrackingLoading] = useState(false);
  const [superAdminLabelLoading, setSuperAdminLabelLoading] = useState(false);
  const [shippingRates, setShippingRates] = useState(null);
  const [shippingRatesLoading, setShippingRatesLoading] = useState(false);

  const [allQuickShip, setAllQuickShip] = useState([]);
  const [allQuickShipLoading, setAllQuickShipLoading] = useState(false);
  const [dynamicRatesLoading, setDynamicRatesLoading] = useState(false);
  const [dynamicRates, setDynamicRates] = useState(false);
  const [ratesLoading, setRatesLoading] = useState(false);
  // const [ratesLoading, setRatesLoading] = useState(false);

  let token = window.localStorage.getItem("accessToken");

  const getAllOrdersApiCall = (orderType, params) => {
    setDashboardLoading(true);
    getAllOrders(params, orderType)
      .then((res) => {
        setOrders(res.orders);
        setTotalPages(res.totalOrders);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDashboardLoading(false);
      });
  };
  const syncOrdersApiCall = async (userId, platform) => {
    // setDashboardLoading(true);
    let params = {
      userId: userId,
    };
    Toast.info("Syncing in progress...");

    postSyncOrders(params, platform)
      .then((res) => {
        console.log(res.data);
        Toast.success("Syncing done you can check orders now");
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDashboardLoading(false);
      });
  };
  const bulkDeleteOrderItemApiCall = async (userId, platform, itemIds) => {
    setDashboardLoading(true);
    let params = {
      userId: userId,
      orderType: platform,
    };
    let data = {
      userId: userId,
      itemIds: itemIds,
      orderType: platform,
    };
    postBulkDeleteOrderItem(data, platform)
      .then((res) => {
        console.log(res.data);
        // getAllOrdersApiCall(platform, params)
        window.location.reload();
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDashboardLoading(false);
      });
  };
  const postCreateLabelApiCall = async (data, id, orderType, userId) => {
    Toast.info("Your label is being created...");
    postCreateLabel(data)
      .then(async (res) => {
        getOrderDetailsApiCall(id, orderType, userId);
        getMyProfileApiCall();
        Toast.success("Your label is created");
      })
      .catch((err) => {
        console.log(err, "errerrerrerr");
        Toast.error(err?.response?.message);
      })
      .finally((err) => {
        setCreateLabelLoading(false);
      });
  };
  const postSuperAdminCreateLabelApiCall = async (
    data,
    id,
    orderType,
    userId
  ) => {
    setSuperAdminLabelLoading(true);
    postCreateLabel(data)
      .then(async (res) => {
        getOrderDetailsApiCall(id, orderType, userId);
        getMyProfileApiCall();
        Toast.success("Your label is created");
      })
      .catch((err) => {
        console.log(err, "errerrerrerr");
        Toast.error(err?.response?.message);
      })
      .finally((err) => {
        setSuperAdminLabelLoading(false);
      });
  };
  const postAdminCreateLabelApiCall = (data, id, orderType, userId) => {
    Toast.info("Your label is being created...");
    console.log("working...");
    setCreateLabelLoading(true);
    postAdminCreateLabel(data)
      .then((res) => {
        console.log(res, "datadatdtas");
        if (res?.success === false) {
          Toast.error(res?.message);
        }
        getOrderDetailsApiCall(id, orderType, userId);
        getMyProfileApiCall();
        Toast.success("Your label is created");
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setCreateLabelLoading(false);
      });
  };
  const resetOrderDetails = () => {
    setAmzAllOrderDetails(null);
    setWalmartOrderDetails(null);
    setEbayOrderDetails(null);
  };
  const getOrderDetailsApiCall = async (id, orderType, userId) => {
    setOrderDetailsLoading(true);
    resetOrderDetails();
    // if (user?.role === "USER") {
    //   Toast.info("Fill package to get rates");
    // }
    const params = {
      orderId: id,
      userId: userId,
    };
    getOrderDetails(params, orderType)
      .then((res) => {
        if (orderType === "amazon") {
          setAmzAllOrderDetails(res?.order);
        } else if (orderType === "ebay") {
          setEbayOrderDetails(res?.order);
        } else if (orderType === "walmart") {
          setWalmartOrderDetails(res?.order);
        }
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setOrderDetailsLoading(false);
      });
  };
  const postUpdateTackingNumberApiCall = (data, orderType) => {
    setUpdatingTrackingLoading(true);
    postUpdateTackingNumber(data, orderType)
      .then((res) => {
        getOrderDetailsApiCall(data.orderId, orderType, data.userId);
        getMyProfileApiCall();
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setUpdatingTrackingLoading(false);
      });
  };
  const postUpdateOrderStatusApiCall = (data, orderType) => {
    postUpdateOrderStatus(data, orderType)
      .then((res) => {
        getOrderDetailsApiCall(data.orderId, orderType, data.userId);
        getMyProfileApiCall();
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {});
  };
  const postQuickShipRatesApiCall = (data) => {
    setShippingRatesLoading(true);
    setOpenPopUpRates(true);
    postQuickShipRates(data)
      .then((res) => {
        setShippingRates(res?.rates);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setShippingRatesLoading(false);
      });
  };
  const postQuickShipShipmentApiCall = (data) => {
    setShippingRatesLoading(true);
    postQuickShipShipment(data)
      .then((res) => {
        Toast.success(res.message);
        setOpenPopUpRates(false);
        navigate("/shipping/shipment");
        getMyProfileApiCall();
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setShippingRatesLoading(false);
      });
  };
  const getAllQuickShipApiCall = (id) => {
    setAllQuickShipLoading(true);
    getAllQuickShip(id)
      .then((res) => {
        setAllQuickShip(res);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setAllQuickShipLoading(false);
      });
  };
  const getDynamicRatesCallApi = (data) => {
    setDynamicRatesLoading(true);
    getDynamicRates(data)
      .then((res) => {
        setDynamicRates(res);
        Toast.success(res.message);
      })
      .catch((err) => {
        Toast.error(err?.response?.errorMessage);
      })
      .finally((err) => {
        setDynamicRatesLoading(false);
      });
  };
  const getRates = async (payload) => {
    setRatesLoading(true);
    let res = await getRatesData(payload);
    setRatesLoading(false);

    return res;
  };

  const addBalancefunc = async (payload) => {
    let res = await addBalanceClient(payload);

    return res;
  };

  const addCustomer = async (payload) => {
    let res = await addCustomerDetails(payload);

    return res;
  };

  const updateUserBalance = async (payload) => {
    let res = await updateBalace(payload);
    return res;
  };

  return (
    <DashboardContext.Provider
      value={{
        getRates,
        shippingRatesLoading,
        getAllOrdersApiCall,
        getAllQuickShipApiCall,
        allQuickShipLoading,
        allQuickShip,
        allOrders,
        totalPages,
        syncOrdersApiCall,
        bulkDeleteOrderItemApiCall,
        dashboardLoading,
        setPdfValue,
        pdfValue,
        postCreateLabelApiCall,
        postAdminCreateLabelApiCall,
        postSuperAdminCreateLabelApiCall,
        superAdminLabelLoading,
        createLabelLoading,
        getOrderDetailsApiCall,
        setOrderDetailsLoading,
        orderDetailsLoading,
        amzAllOrderDetails,
        ebayOrderDetails,
        walmartOrderDetails,
        addBalancefunc,
        addCustomer,
        postUpdateTackingNumberApiCall,
        updatingTrackingLoading,
        postUpdateOrderStatusApiCall,
        selectedValue,
        setSelectedValue,
        resetOrderDetails,
        updateUserBalance,
        postQuickShipRatesApiCall,
        shippingRates,
        postQuickShipShipmentApiCall,
        openPopUpRates,
        setOpenPopUpRates,
        dynamicRates,
        getDynamicRatesCallApi,
        ratesLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContextProvider, useDashboardContext };
