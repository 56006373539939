// orders url
export const GET_ORDERS = "orders";
export const POST_SYNC_ORDERS = "order/sync";
export const POST_BULK_DELETE_ORDER_ITEM = "order/delete";
// users related url
export const POST_USER_LOGIN = "user/login";
export const POST_USER_REGISTER = "user/register";

export const GET_MY_DETAILS = "user/me";
export const POST_DISCONNECT_STORE = "user/disconnect/store";
export const POST_CREATE_LABEL = "labels";
export const POST_ADMIN_CREATE_LABEL = "create/label";
export const POST_UPDATE_TRACKING_NUMBER = "order/tracking";
export const POST_UPDATE_ORDER_STATUS = "order/statusUpdate";
export const GET_ALL_QUICK_SHIP = "quick/ship/all";
export const GET_ORDER_DETAILS = "order/detail";
export const GET_RATES = "getRates";
export const ADD_BALANCE = "add-balance";
export const UPDATE_USER = "users/userId";
export const ADD_CUSTOMER = "create-customer";
export const UPDATE_BALANCE = "updateBalance";
export const POST_QUICK_SHIP_RATES = "quick/ship/getRates";
export const POST_QUICK_SHIP_SHIPMENT = "quick/ship/shipment";
export const GET_DYNAMIC_RATE = "amazon/calculate/rates";
