export const countryList = [
  {
    id: 1,
    code: "CA",
    name: "Canada",
    defaultProvince: null,
  },
  {
    id: 2,
    code: "US",
    name: "United States",
    defaultProvince: null,
  },
  {
    id: 3,
    code: "ES",
    name: "Spain",
    defaultProvince: null,
  },
  {
    id: 4,
    code: "SY",
    name: "Syrian Arab Republic ",
    defaultProvince: null,
  },
  {
    id: 66,
    code: "DK",
    name: "Denmark",
    defaultProvince: null,
  },
  {
    id: 462,
    code: "AD",
    name: "Andorra",
    defaultProvince: null,
  },
  {
    id: 463,
    code: "AE",
    name: "United Arab Emirates",
    defaultProvince: null,
  },
  {
    id: 464,
    code: "AF",
    name: "Afghanistan",
    defaultProvince: null,
  },
  {
    id: 465,
    code: "AG",
    name: "Antigua and Barbuda",
    defaultProvince: null,
  },
  {
    id: 466,
    code: "AI",
    name: "Anguilla",
    defaultProvince: null,
  },
  {
    id: 467,
    code: "AL",
    name: "Albania",
    defaultProvince: null,
  },
  {
    id: 468,
    code: "AM",
    name: "Armenia",
    defaultProvince: null,
  },
  {
    id: 469,
    code: "AO",
    name: "Angola",
    defaultProvince: null,
  },
  {
    id: 470,
    code: "AQ",
    name: "Antarctica",
    defaultProvince: null,
  },
  {
    id: 471,
    code: "AR",
    name: "Argentina",
    defaultProvince: null,
  },
  {
    id: 472,
    code: "AS",
    name: "American Samoa",
    defaultProvince: null,
  },
  {
    id: 473,
    code: "AT",
    name: "Austria",
    defaultProvince: null,
  },
  {
    id: 474,
    code: "AU",
    name: "Australia",
    defaultProvince: null,
  },
  {
    id: 475,
    code: "AW",
    name: "Aruba",
    defaultProvince: null,
  },
  {
    id: 476,
    code: "AX",
    name: "Öland Islands",
    defaultProvince: null,
  },
  {
    id: 477,
    code: "AZ",
    name: "Azerbaijan",
    defaultProvince: null,
  },
  {
    id: 478,
    code: "BA",
    name: "Bosnia and Herzegovina",
    defaultProvince: null,
  },
  {
    id: 479,
    code: "BB",
    name: "Barbados",
    defaultProvince: null,
  },
  {
    id: 480,
    code: "BD",
    name: "Bangladesh",
    defaultProvince: null,
  },
  {
    id: 481,
    code: "BE",
    name: "Belgium",
    defaultProvince: null,
  },
  {
    id: 482,
    code: "BF",
    name: "Burkina Faso",
    defaultProvince: null,
  },
  {
    id: 483,
    code: "BG",
    name: "Bulgaria",
    defaultProvince: null,
  },
  {
    id: 484,
    code: "BH",
    name: "Bahrain",
    defaultProvince: null,
  },
  {
    id: 485,
    code: "BI",
    name: "Burundi",
    defaultProvince: null,
  },
  {
    id: 486,
    code: "BJ",
    name: "Benin",
    defaultProvince: null,
  },
  {
    id: 487,
    code: "BL",
    name: "Saint Barthélemy",
    defaultProvince: null,
  },
  {
    id: 488,
    code: "BM",
    name: "Bermuda",
    defaultProvince: null,
  },
  {
    id: 489,
    code: "BN",
    name: "Brunei Darussalam",
    defaultProvince: null,
  },
  {
    id: 490,
    code: "BO",
    name: "Bolivia",
    defaultProvince: null,
  },
  {
    id: 491,
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    defaultProvince: null,
  },
  {
    id: 492,
    code: "BR",
    name: "Brazil",
    defaultProvince: null,
  },
  {
    id: 493,
    code: "BS",
    name: "Bahamas",
    defaultProvince: null,
  },
  {
    id: 494,
    code: "BT",
    name: "Bhutan",
    defaultProvince: null,
  },
  {
    id: 495,
    code: "BV",
    name: "Bouvet Island",
    defaultProvince: null,
  },
  {
    id: 496,
    code: "BW",
    name: "Botswana",
    defaultProvince: null,
  },
  {
    id: 497,
    code: "BY",
    name: "Belarus",
    defaultProvince: null,
  },
  {
    id: 498,
    code: "BZ",
    name: "Belize",
    defaultProvince: null,
  },
  {
    id: 500,
    code: "CC",
    name: "Cocos (Keeling) Islands",
    defaultProvince: null,
  },
  {
    id: 501,
    code: "CD",
    name: "Congo (the Democratic Republic of the)",
    defaultProvince: null,
  },
  {
    id: 502,
    code: "CF",
    name: "Central African Republic ",
    defaultProvince: null,
  },
  {
    id: 503,
    code: "CG",
    name: "Congo ",
    defaultProvince: null,
  },
  {
    id: 504,
    code: "CH",
    name: "Switzerland",
    defaultProvince: null,
  },
  {
    id: 505,
    code: "CI",
    name: "Côte d'Ivoire",
    defaultProvince: null,
  },
  {
    id: 506,
    code: "CK",
    name: "Cook Islands",
    defaultProvince: null,
  },
  {
    id: 507,
    code: "CL",
    name: "Chile",
    defaultProvince: null,
  },
  {
    id: 508,
    code: "CM",
    name: "Cameroon",
    defaultProvince: null,
  },
  {
    id: 509,
    code: "CN",
    name: "China",
    defaultProvince: null,
  },
  {
    id: 510,
    code: "CO",
    name: "Colombia",
    defaultProvince: null,
  },
  {
    id: 511,
    code: "CR",
    name: "Costa Rica",
    defaultProvince: null,
  },
  {
    id: 512,
    code: "CU",
    name: "Cuba",
    defaultProvince: null,
  },
  {
    id: 513,
    code: "CV",
    name: "Cabo Verde",
    defaultProvince: null,
  },
  {
    id: 514,
    code: "CW",
    name: "Curaçao",
    defaultProvince: null,
  },
  {
    id: 515,
    code: "CX",
    name: "Christmas Island",
    defaultProvince: null,
  },
  {
    id: 516,
    code: "CY",
    name: "Cyprus",
    defaultProvince: null,
  },
  {
    id: 517,
    code: "CZ",
    name: "Czechia",
    defaultProvince: null,
  },
  {
    id: 518,
    code: "DE",
    name: "Germany",
    defaultProvince: null,
  },
  {
    id: 519,
    code: "DJ",
    name: "Djibouti",
    defaultProvince: null,
  },
  {
    id: 521,
    code: "DM",
    name: "Dominica",
    defaultProvince: null,
  },
  {
    id: 522,
    code: "DO",
    name: "Dominican Republic",
    defaultProvince: null,
  },
  {
    id: 523,
    code: "DZ",
    name: "Algeria",
    defaultProvince: null,
  },
  {
    id: 524,
    code: "EC",
    name: "Ecuador",
    defaultProvince: null,
  },
  {
    id: 525,
    code: "EE",
    name: "Estonia",
    defaultProvince: null,
  },
  {
    id: 526,
    code: "EG",
    name: "Egypt",
    defaultProvince: null,
  },
  {
    id: 527,
    code: "EH",
    name: "Western Sahara",
    defaultProvince: null,
  },
  {
    id: 528,
    code: "ER",
    name: "Eritrea",
    defaultProvince: null,
  },
  {
    id: 530,
    code: "ET",
    name: "Ethiopia",
    defaultProvince: null,
  },
  {
    id: 531,
    code: "FI",
    name: "Finland",
    defaultProvince: null,
  },
  {
    id: 532,
    code: "FJ",
    name: "Fiji",
    defaultProvince: null,
  },
  {
    id: 533,
    code: "FK",
    name: "Falkland Islands  [Malvinas]",
    defaultProvince: null,
  },
  {
    id: 534,
    code: "FM",
    name: "Micronesia",
    defaultProvince: null,
  },
  {
    id: 535,
    code: "FO",
    name: "Faroe Islands",
    defaultProvince: null,
  },
  {
    id: 536,
    code: "FR",
    name: "France",
    defaultProvince: null,
  },
  {
    id: 537,
    code: "GA",
    name: "Gabon",
    defaultProvince: null,
  },
  {
    id: 538,
    code: "GB",
    name: "United Kingdom",
    defaultProvince: null,
  },
  {
    id: 539,
    code: "GD",
    name: "Grenada",
    defaultProvince: null,
  },
  {
    id: 540,
    code: "GE",
    name: "Georgia",
    defaultProvince: null,
  },
  {
    id: 541,
    code: "GF",
    name: "French Guiana",
    defaultProvince: null,
  },
  {
    id: 542,
    code: "GG",
    name: "Guernsey",
    defaultProvince: null,
  },
  {
    id: 543,
    code: "GH",
    name: "Ghana",
    defaultProvince: null,
  },
  {
    id: 544,
    code: "GI",
    name: "Gibraltar",
    defaultProvince: null,
  },
  {
    id: 545,
    code: "GL",
    name: "Greenland",
    defaultProvince: null,
  },
  {
    id: 546,
    code: "GM",
    name: "Gambia",
    defaultProvince: null,
  },
  {
    id: 547,
    code: "GN",
    name: "Guinea",
    defaultProvince: null,
  },
  {
    id: 548,
    code: "GP",
    name: "Guadeloupe",
    defaultProvince: null,
  },
  {
    id: 549,
    code: "GQ",
    name: "Equatorial Guinea",
    defaultProvince: null,
  },
  {
    id: 550,
    code: "GR",
    name: "Greece",
    defaultProvince: null,
  },
  {
    id: 551,
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    defaultProvince: null,
  },
  {
    id: 552,
    code: "GT",
    name: "Guatemala",
    defaultProvince: null,
  },
  {
    id: 553,
    code: "GU",
    name: "Guam",
    defaultProvince: null,
  },
  {
    id: 554,
    code: "GW",
    name: "Guinea-Bissau",
    defaultProvince: null,
  },
  {
    id: 555,
    code: "GY",
    name: "Guyana",
    defaultProvince: null,
  },
  {
    id: 556,
    code: "HK",
    name: "Hong Kong",
    defaultProvince: null,
  },
  {
    id: 557,
    code: "HM",
    name: "Heard Island and McDonald Islands",
    defaultProvince: null,
  },
  {
    id: 558,
    code: "HN",
    name: "Honduras",
    defaultProvince: null,
  },
  {
    id: 559,
    code: "HR",
    name: "Croatia",
    defaultProvince: null,
  },
  {
    id: 560,
    code: "HT",
    name: "Haiti",
    defaultProvince: null,
  },
  {
    id: 561,
    code: "HU",
    name: "Hungary",
    defaultProvince: null,
  },
  {
    id: 562,
    code: "ID",
    name: "Indonesia",
    defaultProvince: null,
  },
  {
    id: 563,
    code: "IE",
    name: "Ireland",
    defaultProvince: null,
  },
  {
    id: 564,
    code: "IL",
    name: "Israel",
    defaultProvince: null,
  },
  {
    id: 565,
    code: "IM",
    name: "Isle of Man",
    defaultProvince: null,
  },
  {
    id: 566,
    code: "IN",
    name: "India",
    defaultProvince: null,
  },
  {
    id: 567,
    code: "IO",
    name: "British Indian Ocean Territory",
    defaultProvince: null,
  },
  {
    id: 568,
    code: "IQ",
    name: "Iraq",
    defaultProvince: null,
  },
  {
    id: 569,
    code: "IR",
    name: "Iran (Islamic Republic of)",
    defaultProvince: null,
  },
  {
    id: 570,
    code: "IS",
    name: "Iceland",
    defaultProvince: null,
  },
  {
    id: 571,
    code: "IT",
    name: "Italy",
    defaultProvince: null,
  },
  {
    id: 572,
    code: "JE",
    name: "Jersey",
    defaultProvince: null,
  },
  {
    id: 573,
    code: "JM",
    name: "Jamaica",
    defaultProvince: null,
  },
  {
    id: 574,
    code: "JO",
    name: "Jordan",
    defaultProvince: null,
  },
  {
    id: 575,
    code: "JP",
    name: "Japan",
    defaultProvince: null,
  },
  {
    id: 576,
    code: "KE",
    name: "Kenya",
    defaultProvince: null,
  },
  {
    id: 577,
    code: "KG",
    name: "Kyrgyzstan",
    defaultProvince: null,
  },
  {
    id: 578,
    code: "KH",
    name: "Cambodia",
    defaultProvince: null,
  },
  {
    id: 579,
    code: "KI",
    name: "Kiribati",
    defaultProvince: null,
  },
  {
    id: 580,
    code: "KM",
    name: "Comoros ",
    defaultProvince: null,
  },
  {
    id: 581,
    code: "KN",
    name: "Saint Kitts and Nevis",
    defaultProvince: null,
  },
  {
    id: 582,
    code: "KP",
    name: "Korea (the Democratic People's Republic of)",
    defaultProvince: null,
  },
  {
    id: 583,
    code: "KR",
    name: "Korea (the Republic of)",
    defaultProvince: null,
  },
  {
    id: 584,
    code: "KW",
    name: "Kuwait",
    defaultProvince: null,
  },
  {
    id: 585,
    code: "KY",
    name: "Cayman Islands ",
    defaultProvince: null,
  },
  {
    id: 586,
    code: "KZ",
    name: "Kazakhstan",
    defaultProvince: null,
  },
  {
    id: 587,
    code: "LA",
    name: "Lao People's Democratic Republic ",
    defaultProvince: null,
  },
  {
    id: 588,
    code: "LB",
    name: "Lebanon",
    defaultProvince: null,
  },
  {
    id: 589,
    code: "LC",
    name: "Saint Lucia",
    defaultProvince: null,
  },
  {
    id: 590,
    code: "LI",
    name: "Liechtenstein",
    defaultProvince: null,
  },
  {
    id: 591,
    code: "LK",
    name: "Sri Lanka",
    defaultProvince: null,
  },
  {
    id: 592,
    code: "LR",
    name: "Liberia",
    defaultProvince: null,
  },
  {
    id: 593,
    code: "LS",
    name: "Lesotho",
    defaultProvince: null,
  },
  {
    id: 594,
    code: "LT",
    name: "Lithuania",
    defaultProvince: null,
  },
  {
    id: 595,
    code: "LU",
    name: "Luxembourg",
    defaultProvince: null,
  },
  {
    id: 596,
    code: "LV",
    name: "Latvia",
    defaultProvince: null,
  },
  {
    id: 597,
    code: "LY",
    name: "Libya",
    defaultProvince: null,
  },
  {
    id: 598,
    code: "MA",
    name: "Morocco",
    defaultProvince: null,
  },
  {
    id: 599,
    code: "MC",
    name: "Monaco",
    defaultProvince: null,
  },
  {
    id: 600,
    code: "MD",
    name: "Moldova (the Republic of)",
    defaultProvince: null,
  },
  {
    id: 601,
    code: "ME",
    name: "Montenegro",
    defaultProvince: null,
  },
  {
    id: 602,
    code: "MF",
    name: "Saint Martin (French part)",
    defaultProvince: null,
  },
  {
    id: 603,
    code: "MG",
    name: "Madagascar",
    defaultProvince: null,
  },
  {
    id: 604,
    code: "MH",
    name: "Marshall Islands ",
    defaultProvince: null,
  },
  {
    id: 605,
    code: "MK",
    name: "North Macedonia",
    defaultProvince: null,
  },
  {
    id: 606,
    code: "ML",
    name: "Mali",
    defaultProvince: null,
  },
  {
    id: 607,
    code: "MM",
    name: "Myanmar",
    defaultProvince: null,
  },
  {
    id: 608,
    code: "MN",
    name: "Mongolia",
    defaultProvince: null,
  },
  {
    id: 609,
    code: "MO",
    name: "Macao",
    defaultProvince: null,
  },
  {
    id: 610,
    code: "MP",
    name: "Northern Mariana Islands ",
    defaultProvince: null,
  },
  {
    id: 611,
    code: "MQ",
    name: "Martinique",
    defaultProvince: null,
  },
  {
    id: 612,
    code: "MR",
    name: "Mauritania",
    defaultProvince: null,
  },
  {
    id: 613,
    code: "MS",
    name: "Montserrat",
    defaultProvince: null,
  },
  {
    id: 614,
    code: "MT",
    name: "Malta",
    defaultProvince: null,
  },
  {
    id: 615,
    code: "MU",
    name: "Mauritius",
    defaultProvince: null,
  },
  {
    id: 616,
    code: "MV",
    name: "Maldives",
    defaultProvince: null,
  },
  {
    id: 617,
    code: "MW",
    name: "Malawi",
    defaultProvince: null,
  },
  {
    id: 618,
    code: "MX",
    name: "Mexico",
    defaultProvince: null,
  },
  {
    id: 619,
    code: "MY",
    name: "Malaysia",
    defaultProvince: null,
  },
  {
    id: 620,
    code: "MZ",
    name: "Mozambique",
    defaultProvince: null,
  },
  {
    id: 621,
    code: "NA",
    name: "Namibia",
    defaultProvince: null,
  },
  {
    id: 622,
    code: "NC",
    name: "New Caledonia",
    defaultProvince: null,
  },
  {
    id: 623,
    code: "NE",
    name: "Niger ",
    defaultProvince: null,
  },
  {
    id: 624,
    code: "NF",
    name: "Norfolk Island",
    defaultProvince: null,
  },
  {
    id: 625,
    code: "NG",
    name: "Nigeria",
    defaultProvince: null,
  },
  {
    id: 626,
    code: "NI",
    name: "Nicaragua",
    defaultProvince: null,
  },
  {
    id: 627,
    code: "NL",
    name: "Netherlands ",
    defaultProvince: null,
  },
  {
    id: 628,
    code: "NO",
    name: "Norway",
    defaultProvince: null,
  },
  {
    id: 629,
    code: "NP",
    name: "Nepal",
    defaultProvince: null,
  },
  {
    id: 630,
    code: "NR",
    name: "Nauru",
    defaultProvince: null,
  },
  {
    id: 631,
    code: "NU",
    name: "Niue",
    defaultProvince: null,
  },
  {
    id: 632,
    code: "NZ",
    name: "New Zealand",
    defaultProvince: null,
  },
  {
    id: 633,
    code: "OM",
    name: "Oman",
    defaultProvince: null,
  },
  {
    id: 634,
    code: "PA",
    name: "Panama",
    defaultProvince: null,
  },
  {
    id: 635,
    code: "PE",
    name: "Peru",
    defaultProvince: null,
  },
  {
    id: 636,
    code: "PF",
    name: "French Polynesia",
    defaultProvince: null,
  },
  {
    id: 637,
    code: "PG",
    name: "Papua New Guinea",
    defaultProvince: null,
  },
  {
    id: 638,
    code: "PH",
    name: "Philippines ",
    defaultProvince: null,
  },
  {
    id: 639,
    code: "PK",
    name: "Pakistan",
    defaultProvince: null,
  },
  {
    id: 640,
    code: "PL",
    name: "Poland",
    defaultProvince: null,
  },
  {
    id: 641,
    code: "PM",
    name: "Saint Pierre and Miquelon",
    defaultProvince: null,
  },
  {
    id: 642,
    code: "PN",
    name: "Pitcairn",
    defaultProvince: null,
  },
  {
    id: 643,
    code: "PR",
    name: "Puerto Rico",
    defaultProvince: null,
  },
  {
    id: 644,
    code: "PS",
    name: "Palestine, State of",
    defaultProvince: null,
  },
  {
    id: 645,
    code: "PT",
    name: "Portugal",
    defaultProvince: null,
  },
  {
    id: 646,
    code: "PW",
    name: "Palau",
    defaultProvince: null,
  },
  {
    id: 647,
    code: "PY",
    name: "Paraguay",
    defaultProvince: null,
  },
  {
    id: 648,
    code: "QA",
    name: "Qatar",
    defaultProvince: null,
  },
  {
    id: 649,
    code: "RE",
    name: "Réunion",
    defaultProvince: null,
  },
  {
    id: 650,
    code: "RO",
    name: "Romania",
    defaultProvince: null,
  },
  {
    id: 651,
    code: "RS",
    name: "Serbia",
    defaultProvince: null,
  },
  {
    id: 652,
    code: "RU",
    name: "Russian Federation ",
    defaultProvince: null,
  },
  {
    id: 653,
    code: "RW",
    name: "Rwanda",
    defaultProvince: null,
  },
  {
    id: 654,
    code: "SA",
    name: "Saudi Arabia",
    defaultProvince: null,
  },
  {
    id: 655,
    code: "SB",
    name: "Solomon Islands",
    defaultProvince: null,
  },
  {
    id: 656,
    code: "SC",
    name: "Seychelles",
    defaultProvince: null,
  },
  {
    id: 657,
    code: "SD",
    name: "Sudan ",
    defaultProvince: null,
  },
  {
    id: 658,
    code: "SE",
    name: "Sweden",
    defaultProvince: null,
  },
  {
    id: 659,
    code: "SG",
    name: "Singapore",
    defaultProvince: null,
  },
  {
    id: 660,
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    defaultProvince: null,
  },
  {
    id: 661,
    code: "SI",
    name: "Slovenia",
    defaultProvince: null,
  },
  {
    id: 662,
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    defaultProvince: null,
  },
  {
    id: 663,
    code: "SK",
    name: "Slovakia",
    defaultProvince: null,
  },
  {
    id: 664,
    code: "SL",
    name: "Sierra Leone",
    defaultProvince: null,
  },
  {
    id: 665,
    code: "SM",
    name: "San Marino",
    defaultProvince: null,
  },
  {
    id: 666,
    code: "SN",
    name: "Senegal",
    defaultProvince: null,
  },
  {
    id: 667,
    code: "SO",
    name: "Somalia",
    defaultProvince: null,
  },
  {
    id: 668,
    code: "SR",
    name: "Suriname",
    defaultProvince: null,
  },
  {
    id: 669,
    code: "SS",
    name: "South Sudan",
    defaultProvince: null,
  },
  {
    id: 670,
    code: "ST",
    name: "Sao Tome and Principe",
    defaultProvince: null,
  },
  {
    id: 671,
    code: "SV",
    name: "El Salvador",
    defaultProvince: null,
  },
  {
    id: 672,
    code: "SX",
    name: "Sint Maarten (Dutch part)",
    defaultProvince: null,
  },
  {
    id: 674,
    code: "SZ",
    name: "Eswatini",
    defaultProvince: null,
  },
  {
    id: 675,
    code: "TC",
    name: "Turks and Caicos Islands",
    defaultProvince: null,
  },
  {
    id: 676,
    code: "TD",
    name: "Chad",
    defaultProvince: null,
  },
  {
    id: 677,
    code: "TF",
    name: "French Southern Territories",
    defaultProvince: null,
  },
  {
    id: 678,
    code: "TG",
    name: "Togo",
    defaultProvince: null,
  },
  {
    id: 679,
    code: "TH",
    name: "Thailand",
    defaultProvince: null,
  },
  {
    id: 680,
    code: "TJ",
    name: "Tajikistan",
    defaultProvince: null,
  },
  {
    id: 681,
    code: "TK",
    name: "Tokelau",
    defaultProvince: null,
  },
  {
    id: 682,
    code: "TL",
    name: "Timor-Leste",
    defaultProvince: null,
  },
  {
    id: 683,
    code: "TM",
    name: "Turkmenistan",
    defaultProvince: null,
  },
  {
    id: 684,
    code: "TN",
    name: "Tunisia",
    defaultProvince: null,
  },
  {
    id: 685,
    code: "TO",
    name: "Tonga",
    defaultProvince: null,
  },
  {
    id: 686,
    code: "TR",
    name: "Turkey",
    defaultProvince: null,
  },
  {
    id: 687,
    code: "TT",
    name: "Trinidad and Tobago",
    defaultProvince: null,
  },
  {
    id: 688,
    code: "TV",
    name: "Tuvalu",
    defaultProvince: null,
  },
  {
    id: 689,
    code: "TW",
    name: "Taiwan (Province of China)",
    defaultProvince: null,
  },
  {
    id: 690,
    code: "TZ",
    name: "Tanzania, the United Republic of",
    defaultProvince: null,
  },
  {
    id: 691,
    code: "UA",
    name: "Ukraine",
    defaultProvince: null,
  },
  {
    id: 692,
    code: "UG",
    name: "Uganda",
    defaultProvince: null,
  },
  {
    id: 693,
    code: "UM",
    name: "United States Minor Outlying Islands",
    defaultProvince: null,
  },
  {
    id: 695,
    code: "UY",
    name: "Uruguay",
    defaultProvince: null,
  },
  {
    id: 696,
    code: "UZ",
    name: "Uzbekistan",
    defaultProvince: null,
  },
  {
    id: 697,
    code: "VA",
    name: "Holy See",
    defaultProvince: null,
  },
  {
    id: 698,
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    defaultProvince: null,
  },
  {
    id: 699,
    code: "VE",
    name: "Venezuela",
    defaultProvince: null,
  },
  {
    id: 700,
    code: "VG",
    name: "Virgin Islands (British)",
    defaultProvince: null,
  },
  {
    id: 701,
    code: "VI",
    name: "Virgin Islands (U.S.)",
    defaultProvince: null,
  },
  {
    id: 702,
    code: "VN",
    name: "Viet Nam",
    defaultProvince: null,
  },
  {
    id: 703,
    code: "VU",
    name: "Vanuatu",
    defaultProvince: null,
  },
  {
    id: 704,
    code: "WF",
    name: "Wallis and Futuna",
    defaultProvince: null,
  },
  {
    id: 705,
    code: "WS",
    name: "Samoa",
    defaultProvince: null,
  },
  {
    id: 706,
    code: "YE",
    name: "Yemen",
    defaultProvince: null,
  },
  {
    id: 707,
    code: "YT",
    name: "Mayotte",
    defaultProvince: null,
  },
  {
    id: 708,
    code: "ZA",
    name: "South Africa",
    defaultProvince: null,
  },
  {
    id: 709,
    code: "ZM",
    name: "Zambia",
    defaultProvince: null,
  },
  {
    id: 710,
    code: "ZW",
    name: "Zimbabwe",
    defaultProvince: null,
  },
  {
    id: 712,
    code: "AN",
    name: "NETHERLANDS ANITLLES",
    defaultProvince: null,
  },
  {
    id: 713,
    code: "IC",
    name: "CANARY ISLANDS",
    defaultProvince: null,
  },
  {
    id: 714,
    code: "TP",
    name: "East Timor",
    defaultProvince: null,
  },
  {
    id: 715,
    code: "XB",
    name: "BONAIRE",
    defaultProvince: null,
  },
  {
    id: 716,
    code: "XC",
    name: "CURACAO",
    defaultProvince: null,
  },
  {
    id: 717,
    code: "XE",
    name: "ST EUSTATIUS",
    defaultProvince: null,
  },
  {
    id: 718,
    code: "XM",
    name: "ST MAARTEN",
    defaultProvince: null,
  },
  {
    id: 719,
    code: "XN",
    name: "NEVIS",
    defaultProvince: null,
  },
  {
    id: 720,
    code: "XY",
    name: "ST BARTHELEMY",
    defaultProvince: null,
  },
  {
    id: 721,
    code: "YU",
    name: "YUGOSLAVIA",
    defaultProvince: null,
  },
];
export const pricingData=[
  {
    priceLabel:"100"
  },
  {
    priceLabel:"200"
  },
  {
    priceLabel:"300"
  },
  {
    priceLabel:"500"
  },
  {
    priceLabel:"600"
  },
  {
    priceLabel:"700"
  },
  {
    priceLabel:"800"
  },
  {
    priceLabel:"900"
  },
  {
    priceLabel:"1000"
  },
]