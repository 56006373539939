import React from 'react';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const CountryFlag = ({ countryCode }) => {
  // Assuming countryCode is in the format 'US', 'GB', etc.
  const flagClass = `fi fi-${countryCode?.toLowerCase()}`;
  return <span className={flagClass}></span>

};

export default CountryFlag;