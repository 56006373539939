import { styled, Icon, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
const Nav = styled("div")(() => ({
  //   backgroundColor: 'red',
  height: "70px",

  width: "100%",
}));

const MainContainer = styled("div")(() => ({
  backgroundColor: "white",
  height: "70px",
  position: "fixed",
  width: "100%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
}));
const BackButtonContainer = styled("div")(() => ({
  display: "flex",
  cursor: " pointer",
  //   lineHeight: ' 24px',
  color: "rgb(34, 96, 21) !important",
  fontSize: "16px",
  alignItems: "center",
}));
const OrderIdContainer = styled("div")(() => ({
  display: "flex",

  lineHeight: " 1.5rem",
  fontWeight: "500",
  fontSize: "20px",
  fontFamily:
    " Roboto,pragmatica-web,Helvetica Neue,Helvetica,Arial,sans-serif",
}));
const OrderDetailContainer = styled("div")(() => ({
  width: "500px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const BuyButtonContainer = styled("div")(() => ({}));
const OrderName = styled("div")(() => ({
  //   fontFamily: 'Roboto,pragmatica-web,Helvetica Neue,Helvetica,Arial,sans-serif',
  fontSize: "12px",
  //   lineHeight: '1.25rem',
  //   color: 'rgb(184, 206, 217)',
}));

const OrderCreateNav = ({ goToOrderDetail }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard"); // Replace '/dashboard' with your actual dashboard route
  };

  return (
    <Nav>
      <MainContainer>
        <BackButtonContainer onClick={goBack}>
          <div style={{ paddingTop:5 }}>
            <KeyboardArrowLeftIcon />
          </div>
          <span style={{ fontSize: "14px", fontWeight: 500 }}>
            {" "}
            Back to Orders
          </span>
        </BackButtonContainer>
        <OrderDetailContainer>
          <OrderIdContainer>Create New Label</OrderIdContainer>
          <OrderName>Step 1 of 2</OrderName>
        </OrderDetailContainer>
        <BuyButtonContainer>
          <Button
            onClick={goToOrderDetail}
            sx={{
              cursor: "pointer",
              color: "white",
              backgroundColor: "#2f861d",
              "&:hover": {
                backgroundColor: "#226015",
              },
            }}
          >
            Next: Order Details
          </Button>
        </BuyButtonContainer>
      </MainContainer>
    </Nav>
  );
};

export default OrderCreateNav;
