import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { TextField, Button, Grid } from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { useDashboardContext } from "../../../contexts/DashboardContext/DashboardContext";
import { Toast } from "../../../components/Toaster/Toaster";

const PaymentForm = ({ handleClose }) => {
  let { user, getMyProfileApiCall } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    line1: "",
    city: "",
    state: "",
    postal_code: "",
  });
  const [error, setError] = useState("");
  const { addCustomer } = useDashboardContext();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    console.log(cardElement);
    // pi_3OP356DvHAvdTIHC21E6q7hi_secret_sLGxxv07mbVWmRj9RZ4APH34p

    //   const result = await stripe.confirmCardPayment('pi_3OP3IpDvHAvdTIHC0t0IsS1B_secret_WLz6I29ToHwAx2ekTNArIjkDa', {
    //       payment_method: 'pm_1OP2oPDvHAvdTIHCBz5mTlUR',
    //       amount: 1000,
    // currency: 'usd',
    //     });

    //     console.log(result)

    // Validate billing details
    if (
      !billingDetails.name ||
      !billingDetails.email ||
      !billingDetails.line1 ||
      !billingDetails.city ||
      !billingDetails.state ||
      !billingDetails.postal_code
    ) {
      setError("Please fill in all billing details.");
      return;
    }

    // Clear previous errors
    setError("");

    // Create PaymentMethod
    console.log(cardElement, CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,

      billing_details: {
        address: {
          city: billingDetails.city,
          line1: billingDetails.line1,
          state: billingDetails.state,
        },
        email: billingDetails.email,
      },
    });
    console.log(paymentMethod, error);

    if (error) {
      setError(error.message);
    } else {
      // Send paymentMethod.id and billing details to your server to create a Customer

      let body = JSON.stringify({
        paymentMethodId: paymentMethod.id,
        billingDetails,
        userId: user?.id,
      });

      const response = await addCustomer(body);
      console.log(response);

      if (response.success) {
        handleClose();
        getMyProfileApiCall();
        Toast.success(response.message);
      } else {
        setError(response.error);
      }

      //   const customer = await response.json();

      //   console.log('Customer ID:', customer.id);
      // Now you can use customer.id for future transactions
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Cardholder Name"
            variant="outlined"
            fullWidth
            name="name"
            value={billingDetails.name}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={billingDetails.email}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Street Address"
            variant="outlined"
            fullWidth
            name="line1"
            value={billingDetails.line1}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            value={billingDetails.city}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            name="state"
            value={billingDetails.state}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Postal Code"
            variant="outlined"
            fullWidth
            name="postal_code"
            value={billingDetails.postal_code}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            border: "1px solid grey",
            marginTop: 10,
            padding: 20,
            width: "80%",
            marginLeft: 17,
            borderRadius: 4,
          }}
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{ backgroundColor: "#10D48E" }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentForm;
