import React, { createContext, useContext, useEffect, useState } from "react";
import { merge } from "lodash";

import axios from "axios";
import { useAuth } from "./AuthContext";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const {updateUser} = useAuth()


  const updateUserDetails = async (data, token) => {

    try {

      updateUser(data)
      // let orderUrl = `http://localhost:8081/api/v1/users/userId`;

      // axios
      //   .put(orderUrl,data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `${token}`,
      //     },
          
      //   })
      //   .then((res) => {
      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        updateUserDetails,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
