import React from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import innerWith from "../../components/MenuSideBar";
import { useMediaQuery } from "@mui/material";

const PageNotFound = () => {
  const innerWidth = useMediaQuery("(max-width:760px)");
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        style={{ width: innerWidth ? "100%" : "760px" }}
        src={"/assets/images/illustrations/page_not_found.jpg"}
      />
      <CustomButton
        label={"Go Back"}
        width={"100px"}
        onClick={() => navigate(-1)}
      />
    </div>
  );
};

export default PageNotFound;
