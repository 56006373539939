import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import SettingSidebar from "../CompanySettings/SettingSidebar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MapIcon from "@mui/icons-material/Map";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WebIcon from "@mui/icons-material/Web";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useSettings } from "../../../contexts/SettingsContext";
import { useAuth } from "../../../contexts/AuthContext";
import MenuSidebar from "../../../components/MenuSideBar";
import Loading from "../../../components/MatxLoading";
import CustomButton from "../../../components/CustomButton";
const MainTitle = styled("h1")(() => ({
  marginRight: ".5rem",
  display: "flex",

  fontSize: "20px",
  fontWeight: 700,
}));

const MainCompanyConatiner = styled("div")(() => ({ width: "100%" }));
const MainDescription = styled("h1")(() => ({
  marginRight: ".5rem",
  color: "gray",
  fontSize: "16px",
}));
const SubScriptionRow = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
const StyledButton = styled("div")(({ theme }) => ({
  borderRadius: "10px",
  color: "white",
  width: "80px",
  height: "50px",
  background: "#89edbd",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: 14,
  fontWeight: "bold",
}));
const CompanyBoxConatiner = styled("div")(() => ({
  width: "100%",
}));
const CompanyTitle = styled("div")(() => ({
  fontSize: "16px",
  width: "100%",
  height: "60px",
  color: "black",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  paddingLeft: 18,
  backgroundColor: "whitesmoke",
}));
const CompanyName = styled("div")(() => ({
  paddingBottom: "10px",
  fontSize: "16px",
  width: "100%",
  color: "#475467",
}));
const SocialDes = styled("div")(() => ({
  // paddingLeft: "10px",
  // paddingRight: "10px",
  // marginTop: "20px",
  fontSize: "13px",
  color: "black",
}));
const Input = styled("div")(() => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "16px",

  fontSize: "13px",
  width: "100%",
}));
const InputFields = styled("div")(() => ({
  display: "flex",
}));

const Company = () => {
  const countries = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];
  const initialSenderAddress = {
    name: "",
    company: "",
    phone: "",
    street: "",
    street2: "",
    zip_code: "",
    city: "",
    state: "",
  };
  const initialSocialMedia = {
    twitter: "",
    facebook: "",
    instagram: "",
  };
  const { profileLoading } = useAuth();
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [companyInfo, setComapnyInfo] = useState(null);
  const [senderAddress, setSenderAddress] = useState(null);
  const [socialMedia, setSocialMedia] = useState(null);
  const [carrierCredentials, setCarrierCredentials] = useState(null);
  const [loading, setLoading] = useState(true);
  // console.log(initialCompany,companyInfo?.companyName, "company")
  const innerWith = useMediaQuery("(max-width:360px)");
  const handleComapnyInfo = (value, name) => {
    console.log(value, name);
    setComapnyInfo({ ...companyInfo, [name]: value });
  };
  const handleSenderAddress = (value, name) => {
    console.log(value, name);
    setSenderAddress({ ...senderAddress, [name]: value });
  };
  const handleSocialMedia = (value, name) => {
    setSocialMedia({ ...socialMedia, [name]: value });
  };
  const handleCarrierCredentials = (value, name) => {
    setCarrierCredentials({ ...carrierCredentials, [name]: value });
  };
  const [user, setUser] = useState(null);
  const { updateUserDetails } = useSettings();
  const { getMyProfileCompanyApiCall } = useAuth();
  let token = window.localStorage.getItem("accessToken");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true); // Set loading to true when starting the API call
        const res = await getMyProfileCompanyApiCall(token);
        setUser(res.user);
        setComapnyInfo({
          companyName: res?.user?.companies?.companyInfo?.companyName,
          website: res?.user?.companies?.companyInfo?.website,
          emailAddress: res?.user?.companies?.companyInfo?.emailAddress,
        });
        setSenderAddress({
          name: res?.user?.companies?.senderAddress?.name,
          company: res?.user?.companies?.senderAddress?.company,
          phone: res?.user?.companies?.senderAddress?.phone,
          street: res?.user?.companies?.senderAddress?.street,
          street2: res?.user?.companies?.senderAddress?.street2,
          zip_code: res?.user?.companies?.senderAddress?.zip_code,
          city: res?.user?.companies?.senderAddress?.city,
          state: res?.user?.companies?.senderAddress?.state
            ? res?.user?.companies?.senderAddress?.state
            : "",
        });
        setSocialMedia({
          twitter: res?.user?.companies?.socialMedia?.twitter,
          facebook: res?.user?.companies?.socialMedia?.facebook,
          instagram: res?.user?.companies?.socialMedia?.instagram,
        });
        setCarrierCredentials({
          email: res?.user?.carrierCredentials?.email,
          password: res?.user?.carrierCredentials?.password,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        setLoading(false);
      }
    })();
  }, [token]);

  const initialCompany = {
    companyName: user?.companies?.companyInfo?.companyName
      ? user?.companies?.companyInfo?.companyName
      : "",
    website: user?.companies?.companyInfo?.website
      ? user?.companies?.companyInfo?.website
      : "",
    emailAddress: "",
  };

  let jsonData = {
    companyInfo: companyInfo,
    senderAddress: senderAddress,
    socialMedia: socialMedia,
  };
  const quoteSubmit = () => {
    let companies = jsonData;
    let data = { companies, carrierCredentials: carrierCredentials };
    updateUserDetails(data, token);
    console.log(data, "jsonData");
  };

  return (
    <>
      <MenuSidebar>
        {profileLoading || loading ? (
          <Loading />
        ) : (
          user && (
            <div style={{ display: "flex" }}>
              {/* <SettingSidebar /> */}

              <MainCompanyConatiner style={{ margin: innerWith ? 20 : 30 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          marginBottom={"20px"}
                        >
                          Company
                        </Typography>
                        <MainDescription>
                          Your company details will be used on your packing
                          slips, tracking pages and emails for your customers.
                        </MainDescription>
                      </div>

                      <Button
                        label={"Save"}
                        width="80px"
                        variant="contained"
                        onClick={quoteSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={12} width={"100%"}>
                    <Card
                      elevation={4}
                      sx={{ mt: 2, borderRadius: 3, pb: 2, width: "100%" }}
                    >
                      <Grid sx={{ backgroundColor: "whitesmoke" }} item md={12}>
                        <CompanyTitle>Sender Address</CompanyTitle>
                      </Grid>
                      <Grid
                        item
                        sx={{ pl: 2, fontSize: "13px", pt: 2 }}
                        md={12}
                      >
                        You can change your origin address here.
                      </Grid>
                      <InputFields>
                        <Grid container>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Name</CompanyName>
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PersonIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                name="name"
                                type="text"
                                size="small"
                                placeholder="Name"
                                fullWidth
                                value={senderAddress.name}
                                onChange={(e) => {
                                  handleSenderAddress(e.target.value, "name");
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Company</CompanyName>
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ApartmentIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                name="company"
                                type="text"
                                size="small"
                                placeholder="Company"
                                fullWidth
                                value={senderAddress.company}
                                onChange={(e) => {
                                  handleSenderAddress(
                                    e.target.value,
                                    "company"
                                  );
                                }}
                              />
                            </Input>
                          </Grid>
                        </Grid>
                      </InputFields>
                      <InputFields>
                        <Grid container>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Street*</CompanyName>
                              <TextField
                                name="street"
                                type="text"
                                size="small"
                                placeholder="Street"
                                fullWidth
                                value={senderAddress.street}
                                onChange={(e) => {
                                  handleSenderAddress(e.target.value, "street");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MapIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>{" "}
                          </Grid>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Street 2</CompanyName>
                              <TextField
                                name="street2"
                                type="text"
                                size="small"
                                placeholder="Street 2"
                                fullWidth
                                value={senderAddress.street2}
                                onChange={(e) => {
                                  handleSenderAddress(
                                    e.target.value,
                                    "street2"
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MapIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                        </Grid>
                      </InputFields>
                      <InputFields>
                        <Grid container>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>City*</CompanyName>
                              <TextField
                                name="city"
                                type="text"
                                size="small"
                                placeholder="City"
                                fullWidth
                                value={senderAddress.city}
                                onChange={(e) => {
                                  handleSenderAddress(e.target.value, "city");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationCityIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Zip Code*</CompanyName>
                              <TextField
                                name="zip_code"
                                type="text"
                                size="small"
                                placeholder="Zip code"
                                fullWidth
                                value={senderAddress.zip_code}
                                onChange={(e) => {
                                  handleSenderAddress(
                                    e.target.value,
                                    "zip_code"
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationOnIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                        </Grid>
                      </InputFields>
                      <InputFields>
                        <Grid container>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            <Input>
                              <CompanyName>Phone</CompanyName>
                              <TextField
                                name="email"
                                type="email"
                                size="small"
                                placeholder="Phone no"
                                fullWidth
                                value={senderAddress.phone}
                                onChange={(e) => {
                                  handleSenderAddress(e.target.value, "phone");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PhoneIphoneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={6} xs={12} paddingRight={4}>
                            {senderAddress && (
                              <Input>
                                <CompanyName>State*</CompanyName>
                                <Autocomplete
                                  disablePortal
                                  id="state-select-demo"
                                  options={countries}
                                  autoHighlight
                                  getOptionLabel={(option) =>
                                    typeof option === "string"
                                      ? option
                                      : option.name.toString()
                                  }
                                  value={senderAddress.state}
                                  fullWidth
                                  clearIcon={null}
                                  onChange={(e) => {
                                    handleSenderAddress(
                                      e.target.innerText,
                                      "state"
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <LocationOnIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...params}
                                      name="state"
                                      type="text"
                                      placeholder="State"
                                      size="small"
                                      fullWidth
                                      sx={{
                                        marginBottom: "25px",
                                      }}
                                      value={senderAddress.state}
                                    />
                                  )}
                                />
                              </Input>
                            )}
                          </Grid>
                        </Grid>
                      </InputFields>
                    </Card>
                  </Grid>

                  {/* <Box sx={{ display: "flex" }}> */}
                  <Grid container spacing={3} pl={3}>
                    <Grid item md={6} width={"100%"}>
                      <Card
                        elevation={4}
                        sx={{
                          mt: 2,
                          borderRadius: 3,
                          pb: 2,
                          width: "100%",
                          height: "92.2%",
                        }}
                      >
                        <Grid
                          sx={{ backgroundColor: "whitesmoke" }}
                          item
                          md={12}
                        >
                          <CompanyTitle>Company Info</CompanyTitle>
                        </Grid>
                        <Box sx={{ p: 2, pl: 0 }}>
                          <Grid item sx={{ pl: 2, fontSize: "13px" }} md={12}>
                            You can change your company info details here.
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Company Name </CompanyName>
                              {companyInfo && (
                                <TextField
                                  fullWidth
                                  defaultValue={companyInfo.companyName}
                                  name="name"
                                  type="text"
                                  placeholder="Company Name"
                                  size="small"
                                  value={companyInfo?.companyName}
                                  onChange={(e) => {
                                    handleComapnyInfo(
                                      e.target.value,
                                      "companyName"
                                    );
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AssignmentIndIcon />{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </Input>
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Website</CompanyName>
                              <TextField
                                fullWidth
                                name="website"
                                type="text"
                                size="small"
                                placeholder="http://www.example.com"
                                value={companyInfo.website}
                                onChange={(e) => {
                                  handleComapnyInfo(e.target.value, "website");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <WebIcon />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Contact Email Address</CompanyName>
                              <TextField
                                fullWidth
                                name="email"
                                type="email"
                                size="small"
                                placeholder="example@example.com"
                                value={companyInfo.emailAddress}
                                onChange={(e) => {
                                  handleComapnyInfo(
                                    e.target.value,
                                    "emailAddress"
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailIcon />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item md={6} width={"100%"}>
                      <Card
                        elevation={4}
                        sx={{ mt: 2, borderRadius: 3, pb: 2 }}
                      >
                        <Grid
                          sx={{ backgroundColor: "whitesmoke" }}
                          item
                          md={12}
                        >
                          <CompanyTitle>Social Media</CompanyTitle>
                        </Grid>
                        <Box sx={{ p: 2, pl: 0 }}>
                          <Grid item sx={{ pl: 2, fontSize: "13px" }} md={12}>
                            Your social media links allow your customers to
                            connect with you from your tracking pages.
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Twitter</CompanyName>
                              <TextField
                                fullWidth
                                name="twitter"
                                type="text"
                                size="small"
                                value={socialMedia.twitter}
                                placeholder="Twitter"
                                onChange={(e) => {
                                  handleSocialMedia(e.target.value, "twitter");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <TwitterIcon />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Facebook</CompanyName>
                              <TextField
                                fullWidth
                                name="facebook"
                                type="text"
                                size="small"
                                placeholder="Facebook"
                                value={socialMedia.facebook}
                                onChange={(e) => {
                                  handleSocialMedia(e.target.value, "facebook");
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FacebookIcon />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                          <Grid item md={12} paddingRight={2}>
                            <Input>
                              <CompanyName>Instagram</CompanyName>
                              <TextField
                                fullWidth
                                name="instagram"
                                type="text"
                                size="small"
                                placeholder="Instagram"
                                value={socialMedia.instagram}
                                onChange={(e) => {
                                  handleSocialMedia(
                                    e.target.value,
                                    "instagram"
                                  );
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <InstagramIcon />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Input>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                  {/* </Box> */}
                </Grid>
              </MainCompanyConatiner>
            </div>
          )
        )}
      </MenuSidebar>
    </>
  );
};

export default Company;
