import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MainSidebarContainer = styled("div")(() => ({
  padding: "25px",
  backgroundColor: "#fafafa",
  width: "15%",
  minHeight: "1300px",
  boxShadow:
    "0px 4px 5px -2px rgba(0, 0, 0, 0.06), 0px 7px 10px 1px rgba(0, 0, 0, 0.042), 0px 2px 16px 1px rgba(0, 0, 0, 0.036)",
}));

const SidebarItem = styled("div")(() => ({
  paddingLeft: "20px",
  height: "25px",
  paddingTop: "15px",
  paddingBottom: "10px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const Sidebar = () => {
  const navigation = useNavigate();
  return (
    <MainSidebarContainer>
      <div style={{ paddingBottom: "10px" }}>
        <Typography variant="h8">YOUR BUSINESS</Typography>
      </div>
      <SidebarItem onClick={() => navigation("/setting/store")}>
        <Typography variant="body1">Store</Typography>
      </SidebarItem>
      <SidebarItem onClick={() => navigation("/setting/connect")}>
        <Typography variant="body1">Company info</Typography>
      </SidebarItem>
    </MainSidebarContainer>
  );
};

export default Sidebar;
